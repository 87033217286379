import { Box, CircularProgress, InputAdornment, styled, TextField, Typography, useTheme } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { TimerComponent } from "../../../components/Timer/Timer";
import {
	DECREASE_PROVIDER_STEP,
	INCREASE_CURRENT_PAGE, INCREASE_PROVIDER_STEP,
	SET_PROVIDER,
	TProviderType,
	SET_INTERMEDIATE_STEP,
	TIntermediateStep,

} from "../../../redux/currentPosition/currentPositionTypes";
import { getUserPhone } from "../../../redux/mainContactData/mainContactDataSelectors";
import { SET_REGISTERED } from "../../../redux/mainContactData/mainContactDataTypes";
import { fetchData } from "../../../utils/fetch";
import { getProviderFromURL } from "../../../utils/common";
import { ButtonsContainer, FormContainer } from "../../../components/FieldContainer/FieldContainer";
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import { GET_ANKETAS_DATA } from "../../../redux/continueAnketaPopup/continueAnketaPopupTypes";
import { armeniaFormContainer, belizFormContainer,zaFormContainer,formContainerStyles } from "./LoginByPhoneForm";
import { STORE_FORM_DATA } from "../../../redux/userData/userDataTypes";
import { SET_HAS_ACCOUNT_PHONE,SET_HAS_ACCOUNT_EMAIL } from "../../../redux/accountData/accountTypes";
import { getAccountData } from "../../../redux/accountData/accountSelector";
import HasAccountMessage from "../../../components/HasAccountMessage/HasAccountMessage";
import HasAccountEmailMessage from "../../../components/HasAccountMessage/HasAccountEmailMessage";
import {getLanguageFromURL,languageMapping} from "../../../utils/common";
import {trackEventTealium} from "../../../utils/tealiumUtils";

let myInterval: string | number | NodeJS.Timeout | undefined;

const ConfirmationCodeFieldWrapper = styled(Box)({
	'display': "flex",
	'flexDirection': 'column',
	'gap': '8px',
});

const ConfirmationContainer = styled(Box)({
	display: "flex",
	flexDirection: 'column',
	maxWidth: "553px",
	overflowY: "auto"
});

const requestCode = async (
	phone: string,
	setError: (error: string) => void,
	setSmsNotSent: (smsNotSent: boolean) => void
) => {
	const res = await fetchData({
		url: "auth/client/send-sms",
		method: "POST",
		params: {
			phone,
			lang: languageMapping(getLanguageFromURL())
		},
	});
	if (!res?.success) {
		setError(t("errors.sms.errorMessage", { phone }));
		setSmsNotSent(true);
	}
};

const verifySms = async (phone: string, code: string,provider:string) => {
	return await fetchData({
		'url': 'auth/client/login',
		method: 'POST',
		params: {
			phone,
			code,
			provider
		}
	})
}

const verifyHasAccount = async (phone: string, provider: string) => {
	const endpoint = `client/ownership/phone/verify-uniq-${provider}`;
	const params = {
		phone: phone.replace(/\D/g, ""),
	};
	return await fetchData({
		url: endpoint,
		method: 'POST',
		params
	});
}

export const ConfirmRegistrationForm = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const theme = useTheme();
	const provider: TProviderType = getProviderFromURL() as TProviderType;

	const { hasAccount, hasAccountEmail, email } = useSelector(getAccountData);

	const [isConfirmBtnMode, setConfirmBtnMode] = useState(true);
	const [inputCode, setInputCode] = useState('');
	const [helperText, setHelperText] = useState('');
	const [timerExpired, setTimerExpired] = useState(false);
	const [confirmBtnEnabled, setConfirmButtonEnabled] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [smsNotSent, setSmsNotSent] = useState(false);

	const phone = useSelector(getUserPhone) as string;

	
	useEffect(() => {
		if (phone) {
			requestCode(phone, setHelperText, setSmsNotSent);
		}
	}, [phone]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			inputRef.current?.focus();
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, []);


	const onClickConfirmBtnHandler = () => {
		if (isConfirmBtnMode) {
			submitForm();
		} else {
			requestCode(phone, setHelperText, setSmsNotSent);
			setTimerExpired(false)
			setConfirmBtnMode(true);
			setInputCode('');
			setHelperText('');
			inputRef.current?.focus();
		}
	}

	const onClickChangePhoneBtnHandler = () => {
		dispatch({
            type: SET_INTERMEDIATE_STEP,
            payload: TIntermediateStep.ENTER_PHONE
        });
	}

	const timerHasExpired = () => {
		setHelperText(t('errors.sms.timeOut'));
		setTimerExpired(true);
		setConfirmBtnMode(false);
		setLoading(false);
		setSmsNotSent(false);
	}

	const submitForm = async () => {
		setLoading(true);
		const provider: TProviderType = getProviderFromURL() as TProviderType || 'none';
		const res = await verifySms(phone, inputCode,provider);

		if (!res.success) {
			setHelperText(t("errors.sms.wrongPhoneCode", { phone }));
			setLoading(false);
			inputRef.current?.select();
			return;
		} else {
			const eventData = {
				"tealium_event": "form_step2_smscode",
				"button_name": "Далее",
				"customer_phone": phone,
				"form_type": "open_account",
				"form_placement": "frame"
			};
			trackEventTealium(eventData, 'link');
	
			sessionStorage.setItem('token', res.data.access_token);
			sessionStorage.setItem('phone', phone);
			sessionStorage.removeItem('forcedAuthSuccess');
			const resAccount = await verifyHasAccount(phone, provider);

			if (!resAccount.success) {
				if (resAccount.data?.response?.error?.email) {
					sessionStorage.setItem('isExistsEmail',resAccount.data?.response?.error?.email);

					dispatch({
						type: SET_HAS_ACCOUNT_EMAIL,
						payload: {
							hasAccountEmail: true,
							email: resAccount.data?.response?.error?.email
						}
					});
				}
				if (resAccount.data?.response?.error?.phone) {
					sessionStorage.setItem('isExistsPhone',resAccount.data?.response?.error?.phone);

					dispatch({
						type: SET_HAS_ACCOUNT_PHONE,
						payload: {
							hasAccount: true
						}
					});
				}
				// sessionStorage.setItem('token', ''); - токен оставляем, потому что он нужен для запросов sumsub / обнуления данных анкеты;
			} else {
				if (!!getProviderFromURL()) {
					dispatch({
						type: SET_PROVIDER,
						payload: {
							provider: TProviderType[provider]
						}
					});

					dispatch({
						type: GET_ANKETAS_DATA,
						payload: {
							provider: TProviderType[provider],
							needAConfirmWindow: true
						}
					})
				}
				else {
					dispatch({
						type: SET_REGISTERED
					});

					dispatch({
						type: INCREASE_CURRENT_PAGE
					})
				}
				dispatch({
                    type: SET_INTERMEDIATE_STEP,
                    payload: TIntermediateStep.MAIN_FORM
                });

				dispatch({
					type: STORE_FORM_DATA,
					payload: {
						formName: TProviderType[provider],
						fieldName: "phone",
						fieldValue: phone,
						skipStep: true
					},
				});
			}
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter') {
			onClickConfirmBtnHandler();
		}
	};

	if (hasAccount) {
		return <HasAccountMessage phone={phone} provider={provider} />
	}

	if (hasAccountEmail && email) {
		return <HasAccountEmailMessage email={email} provider={provider} />;
	}

	return (
		<FormContainer
			sx={formContainerStyles[provider] || belizFormContainer}
			bgcolor={{ sm: theme.palette.background.paper }}
		>
			{provider
				?
				<Typography variant="h4">
					{t('registration.confirmation')}
				</Typography>
				:
				<h2>{t('registration.confirmation')}</h2>
			}

			<ConfirmationCodeFieldWrapper>
				<TextField
					// size="small"
					sx={{ width: 1, margin: 0 }}
					margin={"normal"}
					value={inputCode}
					label={t('fields.sms.label')}
					placeholder={t('fields.sms.placeholder')}
					required
					helperText={helperText}
					error={!!helperText}
					inputRef={inputRef}
					InputProps={{
						endAdornment: <InputAdornment position="end">
							{
								!timerExpired && !smsNotSent &&
								<TimerComponent
									initialMinute={3}
									initialSeconds={0}
									setExpired={timerHasExpired}
									intervalId={myInterval}
								/>
							}
						</InputAdornment>,
					}}
					onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						if (event.target.value.length > 0 && !confirmBtnEnabled) {
							setConfirmButtonEnabled(true);
						}
						if (!event.target.value.length && confirmBtnEnabled) {
							setConfirmButtonEnabled(false);
						}
						setHelperText('');
						setInputCode(event.target.value);
					}}
				/>
				{smsNotSent && !timerExpired && (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "6px",
						}}
					>
						<Typography variant="body1" color="error.main">
							{t("errors.sms.tryAgain")}
						</Typography>
						<TimerComponent
							initialMinute={3}
							initialSeconds={0}
							setExpired={timerHasExpired}
							color={"error.main"}
						/>
					</Box>
				)}
			</ConfirmationCodeFieldWrapper>
			<ButtonsContainer>
				<ButtonComponent
					text={t('buttons.back')}
					variant={"outlined"}
					onClick={onClickChangePhoneBtnHandler}
					btnMargin={0}
				/>
				<ButtonComponent
					text={isConfirmBtnMode ? t('buttons.next') : t('buttons.sendCodeAgain')}
					onClick={onClickConfirmBtnHandler}
					disabled={(!confirmBtnEnabled && !timerExpired) || isLoading}
					btnMargin={0}
				>
					{isLoading && <CircularProgress size="1rem" color="inherit" sx={{ marginLeft: "10px" }} />}
				</ButtonComponent>
			</ButtonsContainer>
		</FormContainer>)
}

