import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonComponent} from "../../../components/Buttons/ButtonComponent";
import {EMPTY_FIELD_TEXT, INVALID_PHONE_VALUE} from "../../../redux/commonTypes";
import {INCREASE_PROVIDER_STEP,SET_INTERMEDIATE_STEP, TIntermediateStep, TProviderType} from "../../../redux/currentPosition/currentPositionTypes";
import {getCountryCode, getUserPhone} from "../../../redux/mainContactData/mainContactDataSelectors";
import {UPDATE_COUNTRY_CODE_ACTION, UPDATE_PHONE_ACTION} from "../../../redux/mainContactData/mainContactDataTypes";
import {MuiPhone} from "../../../components/Fields/MuiPhone";
import {CountryIso2, usePhoneValidation} from "react-international-phone";
import {FormContainer} from "../../../components/FieldContainer/FieldContainer";
import {useTranslation} from "react-i18next";
import {Box, styled, Typography, useTheme} from "@mui/material";
import {getProviderFromURL,getLanguageFromURL,getPhoneFromURL} from "../../../utils/common";
import VpnNotification from "../../../components/VpnNotification/VpnNotification";
import AgeNotification from "../../../components/AgeNotification/AgeNotification";
import {trackEventTealium} from "../../../utils/tealiumUtils";

const countryAliases: { [key: string]: string } = {
    am: 'am',
    ru: 'ru'
};

const directLinkLanguage: string = getLanguageFromURL() || 'ru';


export const armeniaFormContainer = {
    gap: '32px',
    '@media (min-width: 640px)': {
        // padding: '64px 0',
        padding: '0',
        gap: '40px',
    }
}

export const zaFormContainer = {
    gap: '32px',
    '@media (min-width: 640px)': {
        // padding: '64px 0',
        padding: '0',
        gap: '40px',
    }
}

export const ruFormContainer = {
    gap: '32px',
    '@media (min-width: 640px)': {
        // padding: '64px 0',
        padding: '0',
        gap: '40px',
    }
}

export const belizFormContainer = {
    gap: '32px',
    '@media (min-width: 640px) and (max-width: 981px)': {
        padding: '64px 0',
        gap: '32px',
    },
    '@media (min-width: 981px)': {
        padding: '64px 120px 64px 48px',
        gap: '64px',
    }
}

export const formContainerStyles: Record<string, any> = {
    armenia: armeniaFormContainer,
    beliz: belizFormContainer,
    za: zaFormContainer,
    ru: ruFormContainer,
};


export const LoginByPhoneForm = () => {
    const {t} = useTranslation();
    const defaultPhone = useSelector(getUserPhone);
    const phoneFromURL = getPhoneFromURL(); 

    const currentCountry = useSelector(getCountryCode) as CountryIso2;
    const [phone, setPhone] = useState(phoneFromURL ?? defaultPhone ?? '');
    const [phoneHelperText, setPhoneHelperText] = useState('');
    const dispatch = useDispatch();
    const phoneValidation = usePhoneValidation(phone, {country: currentCountry});
    const theme = useTheme()
    const provider = getProviderFromURL() ?? 'beliz';
    const inputRef = useRef<HTMLInputElement>(null);
    const countriesToShowVpn = ['armenia', 'za','ru']; 
    const showVpnNotification = countriesToShowVpn.includes(provider);

    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const validatePhoneNumber = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!phone) {
            setPhoneHelperText(t('errors.field.empty'));
        }
        if (!phoneValidation.isValid) {
            setPhoneHelperText(t('errors.phone.invalidFormat'));
        }
    };

    const submitForm = () => {
        if (!phone) {
            setPhoneHelperText(t('errors.field.empty'));
            return;
        }
        if (!phoneValidation.isValid) {
            setPhoneHelperText(t('errors.phone.invalidFormat'));
            return;
        }

        const eventData = {
            "tealium_event": "form_started",
            "button_name": "Далее",
            "customer_phone": phone,
            "form_type": "open_account",
            "form_placement": "frame"
        };
        trackEventTealium(eventData, 'link');
    
        const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');
        dispatch({
            type: UPDATE_PHONE_ACTION,
            payload: {
                phone: preparedPhone
            }
        })
        
        dispatch({
            type: SET_INTERMEDIATE_STEP,
            payload: TIntermediateStep.CONFIRM_SMS
        });

    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            submitForm();
        }
    };

    const defaultCountryCode = countryAliases[directLinkLanguage || 'ru'];


    return (
        <FormContainer
            className="FormContainer"
            sx={formContainerStyles[provider] || belizFormContainer} 
            bgcolor={{sm: theme.palette.background.paper}}>
            {showVpnNotification && <VpnNotification/>}
            {provider
                ?
                <Typography variant="h4">
                    {t('registration.phone')}
                </Typography>
                :
                <h2>{t('registration.phone')}</h2>
            }
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "24px" }}>
                {showVpnNotification && <AgeNotification/>}
                <MuiPhone
                    sx={{margin: 0}}
                    value={phone}
                    helperText={phoneHelperText}
                    defaultCountryCode={defaultCountryCode}
                    onBlur={validatePhoneNumber}
                    onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                    onChange={(phone, country) => {
                        setPhoneHelperText('')
                        setPhone(phone);
                        dispatch({
                            type: UPDATE_COUNTRY_CODE_ACTION,
                            payload: {
                                countryCode: country
                            }
                        })
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
                    inputRef={inputRef}
                />
            </Box>
            <ButtonComponent
                text={t('buttons.next')}
                onClick={submitForm}
                disabled={!phoneValidation.isValid}
                btnMargin={0}
            />
        </FormContainer>
    )
}

