import { Box, CircularProgress, InputAdornment, Link, styled, TextField, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { TimerComponent } from "../../../components/Timer/Timer";
import { fetchData } from "../../../utils/fetch";
import {
    DECREASE_PROVIDER_STEP,
    INCREASE_PROVIDER_STEP,
    SET_PROVIDER_STEP
} from "../../../redux/currentPosition/currentPositionTypes";
import {
    FormRow, FormWrapper,
    PopupBeliz,
    PopupBelizContainer, PopupBelizContent, PopupBelizWrapper
} from "../../../components/FieldContainer/FieldContainer";
import { getApplicantFiles, getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { TAnketaField } from "../../../redux/userData/userDataTypes";
import { EducationOptions, ProffessionalOptions, TEducationTypes, TInvesmentGoalTypes, TProffessionalTypes } from "./BelizConfigTypes";
import { getDocumentFromDb } from "../../../utils/uploadFile";
import { SET_SUMSUB_BZ_TOKEN } from "../../../redux/sumSubData/sumSubDataTypes";
import { TStoredAnketaField } from "../../../redux/storedAnketas/storedAnketasTypes";
import {useTranslation} from "react-i18next";
import {getLanguageFromURL,languageMapping} from "../../../utils/common";

let myInterval: string | number | NodeJS.Timeout | undefined;

const ConfirmationCodeFieldWrapper = styled(Box)({
    'display': "flex",
    'flexDirection': 'row',
    width: '100%'
});

const requestCode = async (phone: string) => {
    const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');

    return await fetchData({
        'url': 'client/ownership/phone/send-sms',
        method: 'POST',
        params: {
            phone: preparedPhone,
            lang: languageMapping(getLanguageFromURL())
        }
    })
}

const verifyCode = async (phone: string, code: string) => {
    const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');

    return await fetchData({
        'url': 'client/ownership/phone/verify-sms',
        method: 'POST',
        params: {
            phone: preparedPhone,
            code
        }
    })
}

const getCheckboxesValues = (fields: { [fieldName: string]: TAnketaField; } | undefined,
    serverData: TStoredAnketaField | undefined,
    prefix: string) => {
    const fieldsValues: string[] = [];
    const financialValues = Object.keys(fields ?? {}).filter(field => field.startsWith(prefix));
    financialValues.forEach((fieldKey: string) => {
        if (fields?.[fieldKey]?.fieldValue) {
            fieldsValues.push(fieldKey.replace(prefix, '').replace('_checkbox', ''))
        }
    });
    if (!fieldsValues.length) {
        const storedValues = Object.keys(serverData ?? {}).filter(field => field.startsWith(prefix));
        storedValues.forEach((fieldKey: string) => {
            if (serverData?.[fieldKey]) {
                fieldsValues.push(fieldKey.replace(prefix, '').replace('_checkbox', ''))
            }
        });
    }
    return fieldsValues;
}

const getToken = async () => {
    return await fetchData({
        'url': 'client/anketas/bz/sumsub-iframe-token',
        method: 'GET',
        params: {}
    })
}

export const BelizConfirmationForm = () => {
    const { t } = useTranslation(['translation', 'belizForm']);
    const [isConfirmBtnMode, setConfirmBtnMode] = useState(true);
    const [inputCode, setInputCode] = useState('');
    const [helperText, setHelperText] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [timerExpired, setTimerExpired] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const bzAnketasData = useSelector(getLocalStoredBelizAnketasData);
    const bzServerAnketasData = useSelector(getStoredBelizAnketasData);
    const fileNames = useSelector(getApplicantFiles);

    const phone = bzAnketasData?.fields.phone?.fieldValue ?? bzServerAnketasData?.data['phone'] as string ?? '';

    const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');

    useEffect(() => {
        const sendSMS = async () => {
            const { success, data } = await requestCode(preparedPhone);
            if (!success) {
                // setHelperText(data?.errorMessage);
                setHelperText(t('errors.sms.smsCodeNotSent', {phone}));
            }
        }
        sendSMS()
            .catch(console.error);
    }, [preparedPhone]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const setNextForm = () => {
        dispatch({
            type: INCREASE_PROVIDER_STEP
        })
    }

    const onClickBtnHandler = async () => {
        if (isConfirmBtnMode) {
            try {
                const { success, data } = await verifyCode(preparedPhone, inputCode);
                if (success) {
                    setLoading(true);
                    const result = await submitForm();
                    if (result.success) {
                        setHelperText('');
                        const resForToken = await getToken();
                        setLoading(false);
                        if (resForToken?.success) {
                            dispatch({
                                type: SET_SUMSUB_BZ_TOKEN,
                                payload: {
                                    token: resForToken?.data?.token
                                }
                            })
                            setNextForm();
                        }
                    } else {
                        setHelperText(t('errors.anketaFillingError'));
                        setLoading(false);
                    }
                } else {
                    // setHelperText(data.errorMessage);
                    setHelperText(t('errors.sms.wrongPhoneCode', {phone}));
                    inputRef.current?.focus();
                }
            } catch(e) {
                setHelperText(t('errors.anketaFillingError'));
            }
        } else {
            setHelperText('');
            setTimerExpired(false);
            setConfirmBtnMode(true);
            setInputCode('')
            inputRef.current?.focus();
            const { success, data } = await requestCode(preparedPhone);
            if (!success) {
                // setHelperText(data?.errorMessage);
                setHelperText(t('errors.sms.smsCodeNotSent', {phone}));
            }
        }
    }

    const timerHasExpired = () => {
        setHelperText(t('errors.sms.timeOut'));
        setTimerExpired(true);
        setConfirmBtnMode(false);
        setLoading(false);
    }

    const getValueByFieldName = (fieldName: string) => {
        return bzAnketasData?.fields[fieldName]?.fieldValue ?? bzServerAnketasData?.data[fieldName];
    }

    const submitForm = async () => {
        const investmentgoal = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'investmentgoal_');
        const education = getValueByFieldName('education_radiogroup');
        const educationRadioText = EducationOptions[education as TEducationTypes]?.text;
        const educationText = educationRadioText ? educationRadioText : bzAnketasData?.fields['education_text']?.fieldValue;
        const occupation = getValueByFieldName('professional_radiogroup');
        const occupationRadioText = ProffessionalOptions[occupation as TProffessionalTypes]?.text;
        const occupationText = occupationRadioText ? educationRadioText : bzAnketasData?.fields["professional_text"]?.fieldValue;
        const sourceofincomebusiness = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'sourceofincomebusiness_');
        const salarysource = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'salarysource_');
        const financialInstrumentsExperience = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'financialexperience_');
        const whatexpectedtouse = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'whatexpectedtouse_');
        let url = new URL(window.location.href);
        let cid = url.searchParams.get('cid');

        const fields = {
            'NameRus': getValueByFieldName('name'),
            'SurName': getValueByFieldName('lastName'),
            'Patronymik': getValueByFieldName('secondName'),
            'email': getValueByFieldName('email'),
            'MobilePhone': preparedPhone,
            'agreeToProcessPersonalInfo': getValueByFieldName('confidentialpolicy_checkbox'),
            'agreeToReceiveMarketingSmsEmail': getValueByFieldName('mailingstatus_checkbox'),
            'taxResidency': getValueByFieldName('taxresidency'),
            'INN': getValueByFieldName('inn') ? getValueByFieldName('inn') : undefined,
            'purposeOfInvestments': investmentgoal,
            'education': education ? educationText : null,
            'occupation': occupation ? occupationText : null,
            'investmentsOrSecuritiesEmployee': getValueByFieldName('inbrokercompany_radiogroup') === "yes" ? true : false,
            'riskOfInvestments': getValueByFieldName('estimatedrisk_radiogroup'),
            'experienceFinancialInstruments': financialInstrumentsExperience,
            'financialInstruments': whatexpectedtouse,
            'anticipatedInvestment': getValueByFieldName('estimatedyearinvestment_radiogroup'),
            'annualIncome': getValueByFieldName('salaryrate_radiogroup'),
            'sourceOfIncomeBusiness': sourceofincomebusiness,
            'primaryIncomeSource': salarysource,
            'primaryIncomeSourceCountry': getValueByFieldName('salarycountry'),
            'paymentsFromCountry': getValueByFieldName('accountreplenish'),
            'paymentsToCountry': getValueByFieldName('accountwithdrawn'),
            'expectedAnnualAccountTurnover': getValueByFieldName('estimatedyearamount_radiogroup'),
            'estimatedNumberOfOperationsPerYear': getValueByFieldName('estimatednumberofoperationsperyear_radiogroup'),
            'expectedDurationOfInvestments': getValueByFieldName('expectedinvestmentduration_radiogroup'),
            'fatcaStatusIsNonUsCitizen': getValueByFieldName('fatcaStatusIsNonUsCitizen'),
            'isNotUsCitizen': getValueByFieldName('isNotUsCitizen_radiogroup') === "yes" ? true : false,
            'isBeneficialOwner': getValueByFieldName('isBeneficialOwner_radiogroup') === "yes" ? true : false,
            cid
        }
        const files: { [key: string]: any } = {};
        for (let i = 0; i < fileNames.length; i++) {
            const fileName = fileNames[i];
            const file = await getDocumentFromDb(fileName);
            files[fileName] = file;
        }
        return fetchData({
            'url': 'client/anketas/sign/BZ',
            method: 'POST',
            contentType: 'multipart/form-data; boundary=-------------573cf973d5228',
            params: {
                fields,
                files
            }
        })

    }
    const [confirmBtnEnabled, setConfirmButtonEnabled] = useState(false);
    const dispatch = useDispatch();
    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const goToFirstStep = () => {
        dispatch({
            type: SET_PROVIDER_STEP,
            payload: {
                step: 1
            }
        })
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            onClickBtnHandler();
        }
    };
    return (
        <FormWrapper>
            <PopupBeliz>
                <PopupBelizWrapper>
                    <PopupBelizContainer>
                        <Typography variant="h3">
                            {t('step4.title', {ns: 'belizForm'})}
                        </Typography>
                        <PopupBelizContent>
                            <Typography variant="body1" flexWrap="wrap">
                                {t('fields.sms.enterCodeFromSMSSentToYourNumber')}
                                <Typography variant="body1" fontWeight="500" component="span"> {phone} </Typography>
                                <Link underline="none" onClick={goToFirstStep} sx={{cursor: 'pointer'}}>
                                    {t('buttons.change')}
                                </Link>
                            </Typography>
                            <ConfirmationCodeFieldWrapper>
                                <TextField
                                    size="small"
                                    sx={{ width: '100%', maxWidth: { xs: '100%', md: '500px' } }}
                                    // margin={"normal"}
                                    label={t('fields.sms.label')}
                                    placeholder={t('fields.sms.placeholder')}
                                    required
                                    helperText={helperText}
                                    error={!!helperText}
                                    inputRef={inputRef}
                                    value={inputCode}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            {
                                                !timerExpired &&
                                                <TimerComponent
                                                    initialMinute={3}
                                                    initialSeconds={0}
                                                    setExpired={timerHasExpired}
                                                    intervalId={myInterval}
                                                />
                                            }
                                        </InputAdornment>,
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setHelperText('');
                                        setInputCode(event.target.value);
                                        if (event.target.value.length > 0 && !confirmBtnEnabled) {
                                            setConfirmButtonEnabled(true);
                                        }
                                        if (!event.target.value.length && confirmBtnEnabled) {
                                            setConfirmButtonEnabled(false);
                                        }
                                    }}
                                />
                            </ConfirmationCodeFieldWrapper>
                            {/*<ButtonComponent text="Назад" variant={"outlined"} onClick={setPrevForm} />*/}
                            <ButtonComponent
                                minWidth={125}
                                btnMargin={0}
                                text={isConfirmBtnMode ? t('buttons.confirm') : t('buttons.re-requestCode')}
                                onClick={onClickBtnHandler}
                                disabled={(!confirmBtnEnabled && !timerExpired) || isLoading}
                            >
                                {isLoading && <CircularProgress size="1rem" color="inherit" sx={{ marginLeft: "10px" }} />}
                            </ButtonComponent>
                        </PopupBelizContent>
                    </PopupBelizContainer>
                    <Typography variant="caption" color="text.secondary">
                        {t('step4.signingExplanation', {ns: 'belizForm'})}
                    </Typography>
                </PopupBelizWrapper>
            </PopupBeliz>
        </FormWrapper>
    )
}
