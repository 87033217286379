import { useState } from "react";
import {
    Box, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/de';
import {fetchData} from "../../utils/fetch";


interface AdminDateSelectionProps {
    open: boolean,
    handleClose: () => void,
}
export const DateSelectionDialog = ({ open, handleClose }: AdminDateSelectionProps) => {
    const [start, setStart] = useState<Dayjs | null>(null);
    const [finish, setFinish] = useState<Dayjs | null>(null);

    const uploadReport = () => {
        const startDate = start?.format('YYYY-MM-DD');
        const finishDate = finish?.format('YYYY-MM-DD');
        const date = finish ? `date_from=${startDate}&date_to=${finishDate}` : `date=${startDate}`;

        fetchData({
            url: `admin/anketas/excelReport/download/${date}`,
            method: 'GET',
            params: {},
            isBlob: true
        }).then(response => {
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(response);
            const date_label = finish ? `date_from=${startDate}_date_to=${finishDate}` : `date=${startDate}`;
            a.download = `Report_${date_label}.xlsx`;
            a.target = "_blank";
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        });
        handleClose();
    };

    return (
        <Dialog
            sx={{p: 8}}
            onClose={() => {
                handleClose()
            }}
            open={open}>
            <IconButton
                aria-label="close"
                onClick={() => {
                    handleClose()
                }}
                sx={{
                    width: '24px',
                    height: '24px',
                    position: 'absolute',
                    right: {xs: 12, sm: 14},
                    top: {xs: 12, sm: 14},
                    color: (theme) => theme.palette.text.secondary,
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogTitle sx={{ m: 0, p: 2 }}>Выберите дату или период отчёта</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <Box sx={{ mt: 2, display: 'flex', gap: '20px' }}>
                        <DatePicker
                            sx={{ flex: '1 1 265px'}}
                            label="Дата / Начало периода"
                            value={start}
                            onChange={(newValue) => setStart(newValue)}
                            slotProps={{
                                field: { clearable: true },
                            }}
                        />
                        <DatePicker
                            sx={{ flex: '1 1 265px'}}
                            label="Конец периода"
                            value={finish}
                            onChange={(newValue) => setFinish(newValue)}
                            slotProps={{
                                field: { clearable: true },
                            }}
                            disabled={!start}
                        />
                    </Box>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>Отмена</Button>
                <Button variant="contained" onClick={uploadReport} autoFocus disabled={!start}>
                    Выгрузить
                </Button>
            </DialogActions>
        </Dialog>
    );
};
