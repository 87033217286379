export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';

export type TCountry = {
    valueEn: string;
    valueRu: string;
    alpha3Code: string;
    risk: {
        status: string
    }
}

export type TGetCountriesListAction = {
    type: typeof GET_COUNTRIES_LIST
}

export type TSetCountriesListAction = {
    type: typeof SET_COUNTRIES_LIST,
    payload: {
        countries: TCountry[]
    }
}

export type TCountriesAction = TGetCountriesListAction | TSetCountriesListAction;

export type TCountriesState = {
    countriesList: TCountry[];
}