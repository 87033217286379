export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_ERROR_FIELDS = "SET_ERROR_FIELDS";
export const REMOVE_ERROR_FIELD = "REMOVE_ERROR_FIELD";

export type TError = boolean | string | undefined;

export type TBelizFormError = {
  [key: string]: TError
};

export type TBelizErrorFields = string[];

export type TBelizErrorField = string;

export type TSetFormErrorAction = {
  type: typeof SET_FORM_ERRORS;
  payload: {
    errors:  TBelizFormError,
  };
};

export type TSetErrorFieldAction = {
  type: typeof SET_ERROR_FIELDS;
  payload: {
    errorFields:  TBelizErrorFields,
  };
};

export type TRemoveErrorFieldAction = {
  type: typeof REMOVE_ERROR_FIELD;
  payload: {
    errorField:  TBelizErrorField,
  };
};

export type TFormErrorsAction = TSetFormErrorAction  | TSetErrorFieldAction  | TRemoveErrorFieldAction;

export type TBelizFormErrorsState = {
  errors: TBelizFormError,
  errorFields: TBelizErrorFields,
};
