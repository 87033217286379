import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";


const ResultWrapper = styled(Box)({
    margin: '0 auto',
    padding: '60px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '48px'
});

const SuccessMessageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',
    gap: '16px',
    '@media (min-width:640px)': {
        alignItems: 'center',
        textAlign: 'center',
        gap: '24px'
    },
    '@media (min-width:1140px)': {
        width: '80%'
    }
});

function ZaFinalMessageSuccess() {
    const { t } = useTranslation(['translation', 'zaForm']);
    return (
        <ResultWrapper>
            <SuccessMessageWrapper>
                <Typography variant={'h1'}>{t('step5.sumSub.result.success.title', { ns: 'zaForm' })}</Typography>
                <Box>
                    <Typography variant={'subtitle1'}>{t('step5.sumSub.result.success.description', { ns: 'zaForm' })}</Typography>
                    <Typography variant={'subtitle1'}>
                        {t('step5.sumSub.result.success.open', { ns: 'zaForm' })}                    
                    </Typography>
                </Box>
            </SuccessMessageWrapper>
            <ButtonComponent
                text={t('buttons.login')}
                btnMargin={0}
                onClick={()=> window.open("https://tradernet.am/authentication/login", "_blank")}
            />
        </ResultWrapper>
    )
}

export default ZaFinalMessageSuccess;