import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    useTheme,
    DialogActions,
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../utils/fetch";
import { ButtonComponent } from "../../components/Buttons/ButtonComponent";
import { PasswordField } from "../../components/Fields/PasswordField";
import { useDispatch,useSelector } from "react-redux"
import { TProviderType } from "../../redux/currentPosition/currentPositionTypes";
import { GET_ANKETAS_DATA } from "../../redux/continueAnketaPopup/continueAnketaPopupTypes";
import { getProviderFromURL } from "../../utils/common";


function LoginDialog({
    open,
    handleClose,
    email: initialEmail,
}: {
    open: boolean;
    handleClose: () => void;
    email: string;
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation(["translation", "armeniaForm"]);
    const [message, setMessage] = useState('');
    const [errorText, setErrorText] = useState('');
    const [isLoading, setLoading] = useState(false);
	const provider: TProviderType = getProviderFromURL() as TProviderType;

    const [email, setEmail] = useState(initialEmail);

    const [password, setPassword] = useState('');
    const [passwordHelperText, setPasswordHelperText] = useState('');
    const dispatch = useDispatch();

    const authInSumsub = async () => {
        return await fetchData({
            url: 'client/anketas/authentificate-sumsub/AM',
            method: 'POST',
            params: {
                login: email,
                password: password
            }
        });
    }


    const handleSubmit = () => {
        setErrorText('');
        setLoading(true);
        authInSumsub().then((response) => {
            setLoading(false);
            if (response?.success) {
                sessionStorage.removeItem('isExistsEmail');
                setErrorText('');
                setMessage('');
                handleClose();
                dispatch({
                    type: GET_ANKETAS_DATA,
                    payload: {
                        provider: TProviderType[provider],
                        needAConfirmWindow: false
                    }
                });
			    sessionStorage.setItem('forcedAuthSuccess',"1");
            } else {
                const errorMessage = response.data?.error?.message || t('account.emailBadCred');
                setErrorText(errorMessage);
            }
        }).catch((error) => {
            setLoading(false);
            const errorMessage = error.data?.error?.message || t('account.emailBadCred');
            setErrorText(errorMessage);
        });
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setPasswordHelperText("");
    }


    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => {
                    setErrorText('');
                    handleClose();
                }}
                PaperProps={{
                    sx: {
                        alignItems: 'center !important',
                        maxWidth: '831px !important',
                        background: '#F6F6F6 !important',
                    },
                }}

            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setErrorText('');
                        handleClose();
                    }}
                    sx={{
                        width: { xs: 24, sm: 36 },
                        height: { xs: 24, sm: 36 },
                        padding: "12px",
                        position: "absolute",
                        right: { xs: 16, sm: 18 },
                        top: { xs: 16, sm: 18 },
                        color: "#6B6B6B",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{t("buttons.enter")}</DialogTitle>
                <DialogContent
                    sx={{
                        gap: "24px !important"
                    }}
                    onBlur={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                    }}
                >
                    <DialogContentText>{t("account.emailEnterData")}</DialogContentText>
                    <Box sx={{ width: "100%" }}>
                        <TextField
                            fullWidth
                            margin={"normal"}
                            label={t("fields.email.label")}
                            placeholder={t("fields.email.placeholder")}
                            type="email"
                            required
                            value={email}
                            disabled
                        />
                        <PasswordField
                            value={password}
                            width={"100%"}
                            label={t("fields.password.label")}
                            placeholder={t("fields.password.placeholder")}
                            name={"password"}
                            margin={"16px 0 8px"}
                            helperText={passwordHelperText}
                            error={!!passwordHelperText}
                            onChange={handlePasswordChange}
                        />
                        <Typography variant="body2">
                            {t("fields.password.description")}
                        </Typography>
                    </Box>
                    {errorText && (
                        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                            {errorText}
                        </Typography>
                    )}

                </DialogContent>
                <DialogActions>
                    <ButtonComponent
                        btnMargin="0 0 0 0"
                        btnWidth="100%"
                        onClick={handleSubmit}
                    >
                        {!isLoading && t("buttons.enter2", { ns: "translation" })}
                        {isLoading && <CircularProgress size="2rem" color="inherit" sx={{ marginLeft: "10px" }} />}
                    </ButtonComponent>

                </DialogActions>
            </Dialog>
        </>
    );
}

export default LoginDialog;
