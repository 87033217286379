import {FormControl, FormControlLabel, FormLabel, Checkbox, Typography, TextField, Box} from "@mui/material"
import {useEffect, useState} from "react";
import {FieldContainer} from "../FieldContainer/FieldContainer";
import {useStoredData} from "../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {STORE_FORM_DATA} from "../../redux/userData/userDataTypes";
import {EMPTY_FIELD_TEXT} from "../../redux/commonTypes";
import {getFieldHasError} from "../../redux/belizErrors/belizErrorsSelectors";
import {TApplicationState} from "../../redux/rootReducer";
import {REMOVE_ERROR_FIELD} from "../../redux/belizErrors/belizErrorsTypes";
import {useTranslation} from "react-i18next";

export type TOptionData = {
    label: string,
    text?: string
}

type TOptionKeyType = string;

type TOptions = {
    [key: TOptionKeyType]: TOptionData
}

type CheckBoxOptions = {
    providerName: string,
    groupName: string,
    groupLabel: string,
    required: boolean,
    options: TOptions,
    hasError: boolean
}

export const CheckBoxComponentWithOtherOption = ({
                                                     providerName,
                                                     groupName,
                                                     groupLabel,
                                                     required,
                                                     options,
                                                     hasError
                                                 }: CheckBoxOptions) => {
    let defaultOptionsValues = [];
    const optionKeys = Object.keys(options);
    const optionNames = optionKeys.map(optionId => `${groupName}_${optionId}_checkbox`);
    const defaultValues: boolean[] = useStoredData<boolean>(providerName, optionNames);
    const dispatch = useDispatch();
    // const fieldHasError = useSelector((state: TApplicationState) => getFieldHasError(state, groupName));
    const [optionText, setOptionText] = useState<string>('');
    const [fieldError, setFieldError] = useState(hasError);
    const {t} = useTranslation(['translation', 'belizForm']);

    for (let i = 0; i < optionNames.length; i++) {
        const optionId = optionKeys[i];
        const defaultValue = defaultValues[i];
        if (defaultValue === true) {
            defaultOptionsValues.push(optionId);
        }
    }
    const [optionValues, setOptionValues] = useState<TOptionKeyType[]>(defaultOptionsValues);

    useEffect(() => {
        if (required && hasError) {
            if (!optionValues?.length || (optionValues.includes('other') && !optionText)) {
                setFieldError(true);
            }
        }
    }, [hasError, optionValues]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionId = event.target.id;
        const newOptionValues = event.target.checked ? [...optionValues, optionId] : optionValues.filter(item => item !== optionId)
        setOptionValues(newOptionValues);
        dispatch({
            type: STORE_FORM_DATA,
            payload: {
                formName: providerName,
                fieldName: groupName,
                fieldValue: event.target.checked ? true : false
            }
        });
        dispatch({
            type: REMOVE_ERROR_FIELD,
            payload: {
                errorField: groupName
            }
        });
        setFieldError?.(false);
        // if (event.target.checked) {
        //     setFieldError?.(false);
        // }
    };
    return (
        <FieldContainer id={groupName}>
            <FormControl sx={{gap: {xs: '16px', md: '24px'}}}>
                <FormLabel required={required}>{t(`step2.${groupName}`, {ns: 'belizForm'})}</FormLabel>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    {
                        optionKeys.map(optionId => {
                            return (
                                <FormControlLabel value={optionId} key={optionId} control={
                                    <Checkbox
                                        id={optionId}
                                        name={`${providerName}.${groupName}_${optionId}_checkbox`}
                                        checked={optionValues.includes(optionId)}
                                        onChange={handleChange}
                                    />}
                                                  // label={options[optionId].label}
                                                  label={t(`step2.${optionId}`, {ns: 'belizForm'})}
                                />
                            )
                        })
                    }
                    <TextField
                        size="small"
                        margin={"normal"}
                        placeholder={t('placeholders.enterYourOption', {ns: 'belizForm'})}
                        type="text"
                        name={`${providerName}.${groupName}_text`}
                        multiline
                        rows={2}
                        disabled={!optionValues.includes('other')}
                        // helperText={taxResidencyHelperText}
                        error={fieldError && optionValues.includes('other') && !optionText}
                        value={optionText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setOptionText(event.target.value);
                            setFieldError(false);
                            hasError = false;
                        }}
                    />
                    {fieldError &&
                      <Typography variant="body2" color="error.main">{t('errors.field.emptyBox')}</Typography>}
                </Box>
            </FormControl>
        </FieldContainer>)

}
