import {REMOVE_ERROR_FIELD, TBelizErrorField} from "../belizErrors/belizErrorsTypes";

export const STORE_FORM_DATA = 'STORE_FORM_DATA';
export const STORE_DATA_ON_SERVER = 'STORE_DATA_ON_SERVER';
export const STORE_FILE_NAME = 'STORE_FILE_NAME';
export const STORE_FILES_NAMES = 'STORE_FILES_NAMES';
export const REMOVE_FILE_NAME = 'REMOVE_FILE_NAME';
export const STORE_APPLICANT_FILES = 'STORE_APPLICANT_FILES';

export type TAnketaType = 'common' | 'beliz' | 'armenia' | 'za' | 'ru';

export type TAnketaField = {
    fieldValue: string,
    storedOnServer: boolean
}

export type TAnketa = {
    fields: { [fieldName : string] : TAnketaField},
}

export type TUserDataState = {
    anketas: {
        [anketatype in TAnketaType] : TAnketa | null
    },
    applicantFiles?: string[]
}

export type TStoreFormDataAction = {
    type: typeof STORE_FORM_DATA,
    payload : {
        formName : TAnketaType,
        fieldName : string,
        fieldValue: string,
        skipStep?: boolean
    }
}

export type TStoreDataonServerAction = {
    type: typeof STORE_DATA_ON_SERVER,
    payload: {
        formName: TAnketaType,
        fieldName: string
    }
}

export type TStoreFileNameAction = {
    type: typeof STORE_FILE_NAME,
    payload: {
        fileName: string
    }
}

export type TStoreFilesNamesAction = {
    type: typeof STORE_FILES_NAMES,
    payload: {
        filesNames: string[]
    }
}

export type TRemoveFileNameAction = {
    type: typeof REMOVE_FILE_NAME;
    payload: {
        fileName: string
    };
};


export type TStoreApplicantFilesAction = {
    type: typeof STORE_APPLICANT_FILES,
    payload: {
        applicantFiles?: string[]
    }
}
export type TUserDataAction = TStoreFormDataAction | TStoreDataonServerAction | TStoreFileNameAction | TStoreFilesNamesAction | TRemoveFileNameAction | TStoreApplicantFilesAction;
