import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { FieldContainer } from "../FieldContainer/FieldContainer";
import { useStoredData } from "../../utils/common";
import { EMPTY_FIELD_TEXT } from "../../redux/commonTypes";
import { useDispatch } from "react-redux";
import { STORE_FORM_DATA } from "../../redux/userData/userDataTypes";
import {REMOVE_ERROR_FIELD} from "../../redux/belizErrors/belizErrorsTypes";
import {useTranslation} from "react-i18next";

export type TOptionData = {
    label: string,
    text?: string
}

type TOptionKeyType = string;

type TOptions = {
    [key: TOptionKeyType]: TOptionData
}

type RadioGroupOptions = {
    providerName: string,
    groupName: string,
    groupLabel: string,
    required: boolean,
    options?: TOptions,
    hasError?: boolean
}

export const RadioGroupComponent = ({
    providerName,
    groupName,
    groupLabel,
    required,
    options,
    hasError
}: RadioGroupOptions) => {
    const [defaultValue] : string[] = useStoredData<string>(providerName, [`${groupName}_radiogroup`]);
    const [optionValue, setOptionValue] = useState<TOptionKeyType>(defaultValue??'');
    const [fieldError, setFieldError] = useState(hasError);
    const { t } = useTranslation(['translation', 'belizForm']);

    useEffect(()=>{
        if (hasError && required){
            setFieldError(true);
        }
    }, [hasError]);
    const dispatch = useDispatch();
    return (
        <FieldContainer id={groupName}>
            <FormControl sx={{gap: {xs: '16px', md: '24px'}}}>
                <FormLabel required={required}>{t(`step2.${groupName}`, {ns: 'belizForm'})}</FormLabel>
                <RadioGroup
                    sx={{gap: '16px'}}
                    name={`${providerName}.${groupName}_radiogroup`}
                    value={optionValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setOptionValue(event.target.value);
                        setFieldError(false);
                        dispatch({
                            type: STORE_FORM_DATA,
                            payload: {
                                formName: providerName,
                                fieldName: groupName,
                                fieldValue: event.target.value
                            }
                        });
                        dispatch({
                            type: REMOVE_ERROR_FIELD,
                            payload: {
                                errorField: groupName
                            }
                        });
                    }}
                >
                    {
                        Object.keys(options??{})?.map(optionId => {
                            return (
                                <FormControlLabel
                                    key = {optionId}
                                    value={optionId}
                                    control={<Radio />}
                                    // label={options?.[optionId].label}
                                    label={t(`step2.${optionId}`, {ns: 'belizForm'})}
                                />
                            )
                        })
                    }
                    {fieldError && <Typography variant="body2" color="error.main">{t('errors.field.emptyBox')}</Typography>}
                </RadioGroup>
            </FormControl>
        </FieldContainer>)

}
