import {FormControl, FormControlLabel, FormLabel, Checkbox, Typography, Box} from "@mui/material"
import {useEffect, useState} from "react";
import {FieldContainer} from "../FieldContainer/FieldContainer";
import {useStoredData} from "../../utils/common";
import {useDispatch} from "react-redux";
import {STORE_FORM_DATA} from "../../redux/userData/userDataTypes";
import {EMPTY_FIELD_TEXT} from "../../redux/commonTypes";
import {TOptionKeyType, TOptions} from "../../forms/MainHelpersApp/BelizForm/BelizConfigTypes";
import {REMOVE_ERROR_FIELD} from "../../redux/belizErrors/belizErrorsTypes";
import {useTranslation} from "react-i18next";

type RadioGroupOptions = {
    providerName: string,
    groupName: string,
    groupLabel: string,
    required: boolean,
    options: TOptions,
    hasError?: boolean
}

export const CheckBoxComponent = ({
                                      providerName,
                                      groupName,
                                      groupLabel,
                                      required,
                                      options,
                                      hasError
                                  }: RadioGroupOptions) => {
    let defaultOptionsValues = [];
    const optionKeys = Object.keys(options);
    const optionNames = optionKeys.map(optionId => `${groupName}_${optionId}_checkbox`);
    const defaultValues: boolean[] = useStoredData<boolean>(providerName, optionNames);
    const dispatch = useDispatch();
    const [fieldError, setFieldError] = useState(hasError);
    const {t} = useTranslation(['translation', 'belizForm']);

    for (let i = 0; i < optionNames.length; i++) {
        const optionId = optionKeys[i];
        const defaultValue = defaultValues[i];
        if (defaultValue === true) {
            defaultOptionsValues.push(optionId);
        }
    }
    const [optionValues, setOptionValues] = useState<TOptionKeyType[]>(defaultOptionsValues);

    useEffect(() => {
        if (required && hasError) {
            if (!optionValues?.length) {
                setFieldError(true);
            }
        }
    }, [hasError, optionValues, required]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionId = event.target.id;
        const newOptionValues = event.target.checked ? [...optionValues, optionId] : optionValues.filter(item => item !== optionId)
        setOptionValues(newOptionValues);
        dispatch({
            type: STORE_FORM_DATA,
            payload: {
                formName: providerName,
                fieldName: groupName,
                fieldValue: event.target.checked ? true : false
            }
        });
        if (event.target.checked) {
            setFieldError?.(false);
        }
        dispatch({
            type: REMOVE_ERROR_FIELD,
            payload: {
                errorField: groupName
            }
        });
    };
    return (
        <FieldContainer id={groupName}>
            <FormControl sx={{gap: {xs: '16px', md: '24px'}}}>
                <FormLabel required={required}>{t(`step2.${groupName}`, {ns: 'belizForm'})}</FormLabel>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                    {
                        optionKeys.map(optionId => {
                            return (
                                <FormControlLabel value={optionId} key={optionId} control={
                                    <Checkbox
                                        id={optionId}
                                        name={`${providerName}.${groupName}_${optionId}_checkbox`}
                                        checked={optionValues.includes(optionId)}
                                        onChange={handleChange}
                                    />}
                                                  // label={options[optionId].label}
                                                  label={t(`step2.${optionId}`, {ns: 'belizForm'})}
                                />
                            )
                        })
                    }
                </Box>
                {fieldError && <Typography variant="body2" color="error.main">{t('errors.field.emptyBox')}</Typography>}
            </FormControl>
        </FieldContainer>)

}
