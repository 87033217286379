import { TProviderType } from "../currentPosition/currentPositionTypes";

export const SET_HAS_ACCOUNT_PHONE = "SET_HAS_ACCOUNT_PHONE";
export const SET_HAS_ACCOUNT_EMAIL = "SET_HAS_ACCOUNT_EMAIL";
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA";

export type TAccountState = {
  provider: TProviderType;
  hasAccount: boolean;
  hasAccountEmail: boolean;
	email: string | null;
};

export type TSetAccountPhoneAction = {
  type: typeof SET_HAS_ACCOUNT_PHONE;
  payload: {
    hasAccount: boolean;
  };
};

export type TSetAccountEmailAction = {
  type: typeof SET_HAS_ACCOUNT_EMAIL;
  payload: {
    hasAccountEmail: boolean;
    email: string
  };
};

export type TGetAccountDataAction = {
  type: typeof GET_ACCOUNT_DATA;
  payload: {
    provider: TProviderType;
    phone: string;
  };
};

export type TAccountAction =
  | TSetAccountPhoneAction
  | TSetAccountEmailAction
  | TGetAccountDataAction;
