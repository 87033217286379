export const LOAD_USER_ANKETAS_ACTION = 'LOAD_USER_ANKETAS_ACTION';
export const SET_ANKETAS_EXISTING_FILTERS_ACTION = 'SET_ANKETAS_EXISTING_FILTERS_ACTION';
export const SET_ANKETAS_APPLIED_FILTERS_ACTION = 'SET_ANKETAS_APPLIED_FILTERS_ACTION';

export type TUserAnketaField = string|number|boolean;

export type TUserAnketa = {
    [key : string] : TUserAnketaField
}

export type THeaderField = {
    field: string,
    title: string
}

export type TUserAnketaTable = {
    rows : Array<Array<TUserAnketaField>>,
    header: Array<string>
}

export type TUserAnketasFullData = {
    header: Array<THeaderField>,
    rows: Array<TUserAnketa>,
    count: number
}
export type TAnketasFilters = {
    field_name: string,
    search_text: string
}

export type TAdminAnketasState = {
    anketas: TUserAnketasFullData,
    filterNames: string[],
    appliedFilters: TAnketasFilters[]
}

export type TLoadUserAnketasAction = {
    type: typeof LOAD_USER_ANKETAS_ACTION,
    payload: {
        anketas: TUserAnketasFullData
    }
}

export type TSetAnketasExistingFiltersAction = {
    type: typeof SET_ANKETAS_EXISTING_FILTERS_ACTION,
    payload: {
        filters: string[] 
    }
}

export type TSetAnketasAppliedFiltersAction = {
    type: typeof SET_ANKETAS_APPLIED_FILTERS_ACTION,
    payload: {
        filter: TAnketasFilters
    }
}

export type TAdminAnketasAction = TLoadUserAnketasAction | TSetAnketasExistingFiltersAction | TSetAnketasAppliedFiltersAction;
