import { useState } from 'react';
import { useTranslation } from "react-i18next";
import './NeedHelpButton.css';
import Question from "../../imgs/question-24.svg";
import ArmeniaNeedHelpDialog from '../../forms/MainHelpersApp/ArmeniaForm/ArmeniaNeedHelpDialog';

function NeedHelpButton() {
    const {t} = useTranslation();
    const [needHelpDialogOpen, setNeedHelpDialogOpen] = useState(false);

    const openNeedHelpDialog = () => {
        setNeedHelpDialogOpen(true);        
    };

    const closeNeedHelpDialog = () => {
        setNeedHelpDialogOpen(false);
    };

    return (
        <>
            <button className="need-help-btn" onClick={openNeedHelpDialog}>
                <img src={Question} alt="Question icon" />
                <p className="need-help-btn-text">{t('buttons.needHelp')}</p>
            </button>
            <ArmeniaNeedHelpDialog
                open={needHelpDialogOpen}
                handleClose={closeNeedHelpDialog}
            />
        </>
    )
}

export default NeedHelpButton