import { Suspense, useEffect } from "react";
import './App.css';
import { Routes, Route } from "react-router-dom"
import { MainHelpersAppForm } from './forms/MainHelpersApp';
import { AdminHelpersAppForm } from './forms/AdminHelpersAppForm';
import { PageNotFound } from "./PageNotFound";
import { getLanguageFromURL, getProviderFromURL } from "./utils/common";

const setErrorPage = () => {
    window.location.href = window.location.origin + '/pagenotfound';
}

function App() {
    useEffect(() => {
        try {
            if (window.location.href.endsWith('pagenotfound') || window.location.href.indexOf('admin') !== -1) return;
            if (window.self === window.top){
                setErrorPage();
            }
            const provider = getProviderFromURL();
            if (!provider){
                setErrorPage();
            }
            if ((provider === "beliz" && window.location.host.endsWith('am')) || (provider === "armenia" && window.location.host.endsWith('bz'))){
                setErrorPage();
            }
        } catch (e) {
        }
    }, [])
    return (
        <div className="App">
            <Suspense fallback="">
                <Routes>
                    <Route path="/" element={<MainHelpersAppForm />} />
                    <Route path="/admin" element={<AdminHelpersAppForm />} />
                    <Route path="/pagenotfound" element={<PageNotFound />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;
