import { Box, Link, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LinearSteps } from "../../../components/LinearSteps/LinearSteps";
import { getProviderStep } from "../../../redux/currentPosition/currentPositionSelector";
import { MainRuForm } from "./MainRuForm";
import { Dispatch, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { getStoredRuAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { AnyAction } from "redux";
import { STORE_FORM_DATA } from "../../../redux/userData/userDataTypes";
import { GET_ANKETAS_DATA } from "../../../redux/continueAnketaPopup/continueAnketaPopupTypes";
import { getSumSubRuStatus } from "../../../redux/sumSubData/sumSubSelectors";
import { TProviderType } from "../../../redux/currentPosition/currentPositionTypes";
import RuFinalMessageSuccess from "./RuFinalMessageSuccess";

const RuFormOuterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  position: relative;
  @media (min-width:640px) {
    min-height: 536px;
    padding: 64px 80px;
    margin: 0 40px 64px;
    background-color: #F6F6F6;
    border-radius: 16px;
  }
`
const RuFormWrapper = styled(Box)`
  max-width: 770px;
  color: #242624;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  @media (min-width:640px) {
    background-color: #F6F6F6;
    gap: 48px;
  }
`
const ResultWrapper = styled(Box)({
    margin: '0 auto',
    padding: '60px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',
    gap: '16px',
    '@media (min-width:640px)': {
        alignItems: 'center',
        textAlign: 'center',
        gap: '24px'
    },
    '@media (min-width:1140px)': {
        width: '80%'
    }
});

const enum SubheaderName {
    personalData = 1,
    residence = 2,
    documents = 3,
    signingDocuments = 4
}

const getSubHeaderText = (activeIndex: number) => {
    switch (activeIndex) {
        case SubheaderName.personalData:
            return i18n.t('steps.personalData', { ns: 'ruForm' });
        case SubheaderName.residence:
            return i18n.t('steps.residence', { ns: 'ruForm' });
        case SubheaderName.documents:
            return i18n.t('steps.documents', { ns: 'ruForm' });
        case SubheaderName.signingDocuments:
            return i18n.t('steps.signingDocuments', { ns: 'ruForm' });

    }
    return '';
}

const getAnketasData = async (dispatch: Dispatch<AnyAction>) => {

    dispatch({
        type: GET_ANKETAS_DATA,
        payload: {
            provider: TProviderType.ru,
            needAConfirmWindow: false
        }
    })
}

export const RuForm = () => {
    const { t } = useTranslation('ruForm');
    const activeIndex = useSelector(getProviderStep);
    const storedFields = useSelector(getStoredRuAnketasData);
    const dispatch = useDispatch();
    const sumSubStatus = useSelector(getSumSubRuStatus);
    const isSumSubStatusCompleted = sumSubStatus === 'GREEN/COMPLETED' || sumSubStatus === 'COMPLETE';

    useEffect(() => {
        if (activeIndex) {
            dispatch({
                type: STORE_FORM_DATA,
                payload: {
                    formName: 'ru'
                }
            });
        }
    }, [activeIndex]);

    useEffect(() => {
        if (!storedFields) {
            getAnketasData(dispatch);
        }
    }, [storedFields])

    return (
        <RuFormOuterWrapper>            
            <RuFormWrapper>
                {
                    isSumSubStatusCompleted					
                        ?
                        <RuFinalMessageSuccess />                        
                        :
                        <>
                            <Typography variant="h1">
                                {t('fillForm')}
                            </Typography>
                            <LinearSteps activeIndex={activeIndex} curHeaderText={getSubHeaderText(activeIndex)} nextHeaderText={getSubHeaderText(activeIndex + 1)} />
                            <MainRuForm activeStep={activeIndex} />
                        </>
                }
            </RuFormWrapper>
        </RuFormOuterWrapper>
    )
}
