import {
    REMOVE_FILE_NAME,
    STORE_DATA_ON_SERVER,
    STORE_FILE_NAME, STORE_FILES_NAMES,
    STORE_FORM_DATA,
    STORE_APPLICANT_FILES,
    TUserDataAction,
    TUserDataState
} from "./userDataTypes";
import {REMOVE_ERROR_FIELD} from "../belizErrors/belizErrorsTypes";

const defaultState = {
    anketas: {
        'common': {
            fields: {

            },
            lastPage: 0
        },
        'beliz': {
            fields: {

            },
            applicantFiles: [],
            attachedFiles: [],
            lastPage: 0
        },
        'armenia': {
            fields: {

            },
            lastPage: 0
        },
        'za': {
            fields: {

            },
            lastPage: 0
        },
        'ru': {
            fields: {

            },
            lastPage: 0
        }
    }
}

export const userDataReducer = (state: TUserDataState = defaultState, action: TUserDataAction) => {
    switch (action.type) {
        case STORE_FORM_DATA:
            return action.payload ? {
                ...state,
                anketas: {
                    ...state.anketas,
                    [action.payload.formName]: {
                        ...state.anketas[action.payload.formName],
                        fields: {
                            ...state.anketas[action.payload.formName]?.fields,
                            [action.payload.fieldName]: {
                                fieldValue: action.payload.fieldValue,
                                storedOnServer: false
                            }
                        }
                    }

                }
            } : state
        case STORE_FILE_NAME:
            return {
                ...state,
                applicantFiles: [...state.applicantFiles ?? [], action.payload.fileName]
            }
        case STORE_FILES_NAMES:
            return {
                ...state,
                applicantFiles: [...action.payload.filesNames]
            }
        case REMOVE_FILE_NAME:
            return {
                ...state,
                applicantFiles: state.applicantFiles?.filter((file) => file !== action.payload.fileName ),
            };
        case STORE_APPLICANT_FILES:
            return {
                ...state,
                applicantFiles: action.payload.applicantFiles
            }
        case STORE_DATA_ON_SERVER:
            return {
                ...state,
                anketas: {
                    ...state.anketas,
                    [action.payload.formName]: {
                        ...state.anketas[action.payload.formName],
                        fields: {
                            ...state.anketas[action.payload.formName]?.fields,
                            [action.payload.fieldName]: {
                                ...state.anketas[action.payload.formName]?.fields[action.payload.fieldName],
                                storedOnServer: true
                            }
                        }
                    }
                }
            }
        default:
            return state;
    }
}
