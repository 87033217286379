import { TApplicationState } from "../rootReducer"

export const getUserName = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.name;
}

export const getUserLastName = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.lastName;
}

export const getUserPhone = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.phone;
}

export const getUserEmail = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.email;
}

export const getUserSecondName = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.secondName;
}

export const getCountryCode = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.countryCode??'ru';
}

export const userHasRegistered = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.registered;
}

export const userHasAuthorized = (state: TApplicationState) =>{
    return state.usersAnketa.mainContactData.authorized;
}