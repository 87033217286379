import {
    REMOVE_POPUP_ERROR,
    SET_POPUP_ERROR, TPopupErrorAction, TPopupErrorsState
  } from "./errorPopupTypes";
  
  const defaultPopupErrorsState: TPopupErrorsState = {
    error: null
  };
  
  export const errorPopupReducer = (state = defaultPopupErrorsState, action: TPopupErrorAction): TPopupErrorsState => {
    switch (action.type) {
      case SET_POPUP_ERROR:
        return {
          ...state,
          error: action.payload.error,
        };
        case REMOVE_POPUP_ERROR:
            return {
              ...state,
              error: null
            };
      default:
        return state;
    }
  };
  