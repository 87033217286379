import { useDispatch, useSelector } from "react-redux"
import { beingEditedAnketaIDSelector, inEditAnketaModeSelector } from "../../redux/adminEditAnketaMode/adminEditAnketaModeSelectors"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { SET_ADMIN_EDIT_ANKETA_MODE } from "../../redux/adminEditAnketaMode/adminEditAnketaModeTypes";
import { getAnketaById } from "../../redux/adminAnketas/adminAnketasSelectors";
import { TApplicationState } from "../../redux/rootReducer";
import { useEffect, useState } from "react";
import { fetchData } from "../../utils/fetch";

export const AdminEditAnketaForm = () => { 
    const inEditMode = useSelector(inEditAnketaModeSelector);
    const currentAnketaId = useSelector(beingEditedAnketaIDSelector);
    const currentAnketa = useSelector((state: TApplicationState) => getAnketaById(state, currentAnketaId??''));

    const comment = currentAnketa?.['comment']??'';

    const [newCommentValue, setNewCommentValue] = useState(comment);

    useEffect(()=>{
        setNewCommentValue(comment);
    }, [comment])

    const dispatch = useDispatch();

    const saveData = () => {
        fetchData({
            url: `admin/anketas/comment/${currentAnketaId}`,
            method: 'POST',
            params: { value: newCommentValue}
        });
        handleClose();
    }

    const handleClose = () => {
        dispatch({
            type : SET_ADMIN_EDIT_ANKETA_MODE,
            payload : {
                editMode: false,
                anketaId: undefined
            }
        }) 
    }
    return(<Dialog
        open={inEditMode}
        onClose={handleClose}
    >
        <DialogTitle>
            Редактирование анкеты
        </DialogTitle>
        <DialogContent>
        <TextField
            size="small"
            margin={"normal"}
            label="Комментарий"
            placeholder="Комментарий"
            multiline
            type="input"
            value={newCommentValue}
            sx={{width : '30em'}}
            rows={5}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewCommentValue(event.target.value);
            }}
        />
        </DialogContent>
        <DialogActions>
            <Button onClick={saveData} autoFocus>
                Сохранить
            </Button>
        </DialogActions>
    </Dialog>)
}