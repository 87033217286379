import { SET_ADMIN_EDIT_ANKETA_MODE, TAdminEditAnketaModeAction, TAdminEditAnketaModeState } from "./adminEditAnketaModeTypes";

const defaultAdminDataState: TAdminEditAnketaModeState = {
    inEditMode: false,
    anketaId: undefined
}

export const adminEditCommentModeReducer = (state = defaultAdminDataState, action: TAdminEditAnketaModeAction): TAdminEditAnketaModeState => {
    switch (action.type) {
        case SET_ADMIN_EDIT_ANKETA_MODE:
            return {
                ...state,
                inEditMode: action.payload.editMode,
                anketaId: action.payload.anketaId
            }
        default:
            return state;
    }
}