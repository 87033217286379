import {Link, styled, Typography} from "@mui/material"
import {useDispatch} from "react-redux"
import './MainBelizForm.css'
import {DECREASE_PROVIDER_STEP, INCREASE_PROVIDER_STEP} from "../../../redux/currentPosition/currentPositionTypes"
import {BelizSignAnketaForm} from "./BelizSignAnketaForm"
import {BelizConfirmDocuments} from "./BelizConfirmDocuments"
import {BelizContactData} from "./BelizContactData"
import {BelizTaxResidency} from "./BelizTaxResidency"
import React, {useEffect} from "react";
import {BelizSumSubForm} from "./BelizSumSubForm"
import {GET_COUNTRIES_LIST} from "../../../redux/countriesList/countriesListTypes"
import {STORE_FORM_DATA} from "../../../redux/userData/userDataTypes"
import {useTranslation} from "react-i18next";

const MainBelizFormWrapper = styled('div')`
  //margin: 0 40px;
  width: 100%;
  max-width: 516px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  overflow-y: auto;
  background-color: #FFFFFF;
  @media (min-width:981px) {
    max-width: unset;
    margin: 0 40px;
    gap: 64px;
    background-color: #F4F8FF;
  }
`

export const MainBelizForm = ({activeStep}: { activeStep: number }) => {
    const { t } = useTranslation('belizForm');
    const dispatch = useDispatch();
    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }
    useEffect(() => {
        dispatch({
            type: GET_COUNTRIES_LIST
        })
    }, []);
    useEffect(() => {
        if (activeStep) {
            dispatch({
                type: STORE_FORM_DATA,
                payload: {
                    formName: 'beliz'
                }
            });
        }
    }, [activeStep])
    return (
        <MainBelizFormWrapper>
            <Typography variant="h1">
                {t("fillForm")}
            </Typography>
            {activeStep > 1 && activeStep < 5
                && <Link
                    sx={{display: 'flex', gap: '4px', textDecoration: 'none'}}
                    component="button"
                    color="primary.main"
                    variant="subtitle1"
                    onClick={setPrevForm}
                >
                    <div className="arrow-left"></div>
                    <Typography variant="body1">
                        {t("prevStep")}
                    </Typography>
                </Link>
            }
            {activeStep === 1 && <BelizContactData/>}
            {activeStep === 2 && <BelizTaxResidency/>}
            {activeStep === 3 && <BelizConfirmDocuments/>}
            {activeStep === 4 && <BelizSignAnketaForm/>}
            {activeStep > 4 && <BelizSumSubForm/>}
        </MainBelizFormWrapper>)
}
