import { EducationOptions, TEducationTypes, ProffessionalOptions, TProffessionalTypes, InvestmentGoalOptions, TInvesmentGoalTypes, TSalarySource, SalarySourceOptions } from "../forms/MainHelpersApp/BelizForm/BelizConfigTypes";
import { TStoredAnketaField, TStoredAnketa } from "../redux/storedAnketas/storedAnketasTypes";
import { TAnketa, TAnketaField } from "../redux/userData/userDataTypes";

const getCheckboxesValues = (fields: { [fieldName: string]: TAnketaField; } | undefined,
    serverData: TStoredAnketaField | undefined,
    prefix: string) => {
    const fieldsValues: string[] = [];
    const financialValues = Object.keys(fields ?? {}).filter(field => field.startsWith(prefix));
    financialValues.forEach((fieldKey: string) => {
        if (fields?.[fieldKey]?.fieldValue) {
            fieldsValues.push(fieldKey.replace(prefix, '').replace('_checkbox', ''))
        }
    });
    if (!fieldsValues.length){
        const storedValues = Object.keys(serverData ?? {}).filter(field => field.startsWith(prefix));
        storedValues.forEach((fieldKey: string) => {
            if (serverData?.[fieldKey]) {
                fieldsValues.push(fieldKey.replace(prefix, '').replace('_checkbox', ''))
            }
        });
    }
    return fieldsValues;
}

export const extractBelizAnketasData = (bzAnketasData: TAnketa | null, bzServerAnketasData: TStoredAnketa | undefined) => {
    const getValueByFieldName = (fieldName : string) => {
        return bzAnketasData?.fields[fieldName]?.fieldValue ?? bzServerAnketasData?.data[fieldName];
    }
    const investmentgoalKeys = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'investmentgoal_');
    const investmentgoal = investmentgoalKeys.map((key : string) => ((key !== 'other') ? InvestmentGoalOptions[key as TInvesmentGoalTypes]?.text : (bzAnketasData?.fields['investmentgoal_text']?.fieldValue || (bzServerAnketasData?.data['investmentgoal_text']??'') as string)??'')).filter(item=>item);
    const education = getValueByFieldName('education_radiogroup');
    const educationRadioText = EducationOptions[education as TEducationTypes]?.text;
    const educationText = educationRadioText ? educationRadioText : bzAnketasData?.fields['education_text']?.fieldValue;
    const occupation = getValueByFieldName('professional_radiogroup');
    const sourceofincomebusiness = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'sourceofincomebusiness_');
    const salarysourceKeys = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'salarysource_');
    const salarysource = salarysourceKeys.map((key : string) => ((key !== 'other') ? SalarySourceOptions[key as TSalarySource]?.text : (bzAnketasData?.fields['salarysource_text']?.fieldValue || (bzServerAnketasData?.data['salarysource_text']??'') as string)??'')).filter(item=>item);
    const financialInstrumentsExperience = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'financialexperience_');
    const whatexpectedtouse = getCheckboxesValues(bzAnketasData?.fields, bzServerAnketasData?.data, 'whatexpectedtouse_');

    const phone = bzAnketasData?.fields.phone?.fieldValue ?? bzServerAnketasData?.data['phone'] as string ?? '';

    const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');

    const fields = {
        'NameRus': getValueByFieldName('name'),
        'SurName': getValueByFieldName('lastName'),
        'Patronymik': getValueByFieldName('secondName'),
        'email': getValueByFieldName('email'),
        'MobilePhone': preparedPhone,
        'agreeToProcessPersonalInfo': getValueByFieldName('confidentialpolicy_checkbox'),
        'agreeToReceiveMarketingSmsEmail': getValueByFieldName('mailingstatus_checkbox'),
        'taxResidency': getValueByFieldName('taxresidency'),
        'INN': getValueByFieldName('inn') ? getValueByFieldName('inn'): undefined,
        'purposeOfInvestments': investmentgoal,
        'education': education ? educationText : null,
        'occupation': occupation ? ProffessionalOptions[occupation as TProffessionalTypes]?.text : null,
        'investmentsOrSecuritiesEmployee': getValueByFieldName('inbrokercompany_radiogroup') === "yes" ? true : false,
        'riskOfInvestments': getValueByFieldName('estimatedrisk_radiogroup'),
        'experienceFinancialInstruments': financialInstrumentsExperience,
        'financialInstruments': whatexpectedtouse,
        'anticipatedInvestment': getValueByFieldName('estimatedyearinvestment_radiogroup'),
        'annualIncome': getValueByFieldName('salaryrate_radiogroup'),
        'sourceOfIncomeBusiness': sourceofincomebusiness,
        'primaryIncomeSource': salarysource,
        'primaryIncomeSourceCountry': getValueByFieldName('salarycountry'),
        'paymentsFromCountry': getValueByFieldName('accountreplenish'),
        'paymentsToCountry': getValueByFieldName('accountwithdrawn'),
        'expectedAnnualAccountTurnover': getValueByFieldName('estimatedyearamount_radiogroup'),
        'estimatedNumberOfOperationsPerYear': getValueByFieldName('estimatednumberofoperationsperyear_radiogroup'),
        'expectedDurationOfInvestments': getValueByFieldName('expectedinvestmentduration_radiogroup')
    }
    return fields;
}