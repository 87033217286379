import { call, put, take } from "redux-saga/effects";
import { FETCH_DOCS_FROM_SERVER, SAVE_DOCUMENTS_LINKS, TFetchDocsAction } from "../redux/fetchedDocuments/fetchedDocumentsTypes";
import { fetchData } from "../utils/fetch";

function* fetchDocumentsFromServer(action: TFetchDocsAction) {
    const { docIds, url } = action.payload;
    const fileUrls: { [key: string]: string } = {};
    for (let i = 0; i < docIds.length; i++) {
        const blob: Blob = yield call(fetchData, {
            'url': `docExamples/bz/get-doc-example/${docIds[i]}`,
            method: 'POST',
            params: action.payload.data,
            isBlob: true
        });
        const fileURL = window.URL.createObjectURL(blob);
        fileUrls[docIds[i]] = fileURL
    }

    yield put({
        type : SAVE_DOCUMENTS_LINKS,
        payload: fileUrls
    });

    yield call(getBlobDocumentsSaga);
}

function* getBlobDocumentsSaga(): any {
    const action: TFetchDocsAction = yield take(FETCH_DOCS_FROM_SERVER);
    yield call(fetchDocumentsFromServer, action);
}

export default getBlobDocumentsSaga;