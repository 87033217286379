import {Box, Link, Typography} from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { getProviderStep } from "../../../redux/currentPosition/currentPositionSelector";
import { MainBelizForm } from "./MainBelizForm"
import { getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { Dispatch, useEffect } from "react";
import { STORE_FORM_DATA } from "../../../redux/userData/userDataTypes";
import { TProviderType } from "../../../redux/currentPosition/currentPositionTypes";
import { GET_ANKETAS_DATA } from "../../../redux/continueAnketaPopup/continueAnketaPopupTypes";
import { AnyAction } from "redux";
import { useTranslation } from "react-i18next";
import { getSumSubBelizStatus } from "../../../redux/sumSubData/sumSubSelectors";
import { FormContainer } from "../../../components/FieldContainer/FieldContainer";

const getAnketasData = async (dispatch: Dispatch<AnyAction>) => {

    dispatch({
        type: GET_ANKETAS_DATA,
        payload: {
            provider: TProviderType.beliz,
            needAConfirmWindow: false
        }
    })
}

const sxMainBelizForm = {
    backgroundColor: '#FFFFFF',
    '@media (max-width:981px)': {
        display: 'flex',
        justifyContent: 'center',
    },
    '@media (min-width: 981px)': {
        backgroundColor: '#F4F8FF',
    }
}

export const BelizForm = () => {
    const { t } = useTranslation('belizForm');
    const activeIndex = useSelector(getProviderStep);
    const storedFields = useSelector(getStoredBelizAnketasData);
    const dispatch = useDispatch();

    const sumSubStatus = useSelector(getSumSubBelizStatus);
    const isSumSubStatusCompleted = sumSubStatus === 'GREEN';

    useEffect(() => {
        if (activeIndex) {
            dispatch({
                type: STORE_FORM_DATA,
                payload: {
                    formName: 'beliz'
                }
            });
        }
    }, [activeIndex]);

    useEffect(() => {
        if (!storedFields) {
            getAnketasData(dispatch);
        }
    }, [storedFields])
    return (<Box className='MainBelizForm' sx={sxMainBelizForm}>
            {
                isSumSubStatusCompleted
                    ?
                    <FormContainer>
                        <Typography variant={'h3'}>{t('step5.sumSub.result.pending.title')}</Typography>
                        <Typography variant={'subtitle1'}>{t('step5.sumSub.result.pending.description')}</Typography>
                        <Typography variant={'subtitle1'}>
                            {t('step5.sumSub.result.support.help')}
                            <Link variant="subtitle1" underline="none" href="mailto:clients@ffin.bz">
                                {t('step5.sumSub.result.support.email')}
                            </Link>
                        </Typography>
                    </FormContainer>
                    :
                    <MainBelizForm activeStep={activeIndex} />
            }
    </Box>
    )
}
