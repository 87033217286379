import { TApplicationState } from "../rootReducer";

export const getAllAnketas = (state: TApplicationState) => {
    return state?.adminAnketas?.anketasData?.anketas;
}

export const getAnketaById = (state: TApplicationState, anketaId: string) => {
    if (anketaId){
        return state?.adminAnketas?.anketasData?.anketas.rows.find(row => row['id'].toString() === anketaId);
    }
    return;
}

export const getFilters = (state: TApplicationState) => {
    return state?.adminAnketas?.anketasData.filterNames
}


export const getFilterValues = (state: TApplicationState) => {
     return state?.adminAnketas?.anketasData.appliedFilters;
}