export const AUTHORIZE_ADMIN = 'AUTHORIZE_ADMIN';
export const UNAUTHORIZE_ADMIN = 'UNAUTHORIZE_ADMIN';

export type TAdminDataState = {
    authorized : boolean
}

export type TAuthorizeAdminAction = {
    type: typeof AUTHORIZE_ADMIN,
}

export type TUnAuthorizeAdminAction = {
    type: typeof UNAUTHORIZE_ADMIN,
}

export type TAdminDataAction = TAuthorizeAdminAction|TUnAuthorizeAdminAction;