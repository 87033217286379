import { Box } from "@mui/material";
import SumsubWebSdk from '@sumsub/websdk-react';
import { getLocalStoredArmeniaAnketasData, getStoredArmeniaAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DECREASE_PROVIDER_STEP, TProviderType } from "../../../redux/currentPosition/currentPositionTypes";
import { getSumSubToken } from "../../../redux/sumSubData/sumSubSelectors";
import { GET_SUMSUB_AM_AUTH } from "../../../redux/storedAnketas/storedAnketasTypes";
import { getLanguageFromURL, getProviderFromURL } from "../../../utils/common";



export const ArmeniaSumSubForm = () => {
    const accessToken = useSelector(getSumSubToken);
    const localArmeniaData = useSelector(getLocalStoredArmeniaAnketasData);
    const storedFields = useSelector(getStoredArmeniaAnketasData);

    const userPhone: string | undefined = storedFields?.['phone'] || localArmeniaData?.fields['phone']?.fieldValue;
    const userEmail: string | undefined = storedFields?.data['email'] as string | undefined || localArmeniaData?.fields['email']?.fieldValue;
    const taxResidency = storedFields?.data['country'] || localArmeniaData?.fields['country']?.fieldValue || '';

    const dispatch = useDispatch();

    useEffect(() => {
        if (!accessToken) {
            dispatch({
                type: DECREASE_PROVIDER_STEP
            })
        }
    }, [accessToken]);

    const langURLParam = getLanguageFromURL();

    let language;

    switch(langURLParam){
        case 'en':
            language = 'en';
            break;
        case 'ru':
            language = 'ru';
            break;
        case 'am':
        default:
            language = 'hy';
            break;
    }

    const config = {
        lang: language,
        email: userEmail,
        phone: userPhone
    }

    const accessTokenExpirationHandler = () => {
        dispatch({
            type: GET_SUMSUB_AM_AUTH,
            payload: {
                provider: TProviderType.armenia
            }
        })
        return new Promise<string>((resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void) => { resolve('success') });
    }

    return (<Box>
        {<SumsubWebSdk
            accessToken={accessToken ?? ''}
            expirationHandler={accessTokenExpirationHandler}
            config={config}
            options={{}}
        />
        }</Box>)
}