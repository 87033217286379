import { SAVE_DOCUMENTS_LINKS, TDocumentsAction, TDocumentsState } from "./fetchedDocumentsTypes";

const defaultDocumentsState = {
}

export const fetchedDocumentsReducer = (state = defaultDocumentsState, action: TDocumentsAction): TDocumentsState => {
    switch (action.type) {
    case SAVE_DOCUMENTS_LINKS:
        return {
            ...state,
            ...action.payload
        }
        default:
            return state;
    }
}