import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { rootReducer } from './redux/rootReducer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { rootSaga } from "./sagas/rootSaga";
import { BrowserRouter } from 'react-router-dom';
import './i18n/i18n';
import { getProviderFromURL } from "./utils/common";
import TealiumTracker from './components/Tealium/Tealium'
import { initializeGTM } from './utils/gtm';

const provider = getProviderFromURL()

//tealium отключен
// if(provider == 'armenia'){
//   window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
//   window.utag_cfg_ovrd.noview = true;
// }

if(provider){
  initializeGTM(provider);
}


const sagaMiddleware = createSagaMiddleware();
let store;
if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
} else {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}
sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
