import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldContainer } from "../FieldContainer/FieldContainer";
import { useDispatch, useSelector } from "react-redux";
import { getStoredBelizAnketasData } from "../../redux/storedAnketas/storedAnketasSelectors";
import { TStoredAnketaField } from "../../redux/storedAnketas/storedAnketasTypes";
import { useStoredData } from "../../utils/common";
import { EMPTY_FIELD_TEXT } from "../../redux/commonTypes";
import { STORE_FORM_DATA } from "../../redux/userData/userDataTypes";
import {REMOVE_ERROR_FIELD} from "../../redux/belizErrors/belizErrorsTypes";

export type TOptionData = {
    label: string,
    text?: string
}

type TOptionKeyType = string;

type TOptions = {
    [key: TOptionKeyType]: TOptionData
}

type RadioGroupOptions = {
    providerName: string,
    groupName: string,
    groupLabel: string,
    required: boolean,
    options: TOptions,
    hasError?: boolean
}

export const RadioGroupWithOtherOption = ({
    providerName,
    groupName,
    groupLabel,
    required,
    options,
    hasError
}: RadioGroupOptions) => {
    const { t } = useTranslation(['translation', 'belizForm']);
    const dispatch = useDispatch();
    const [defaultValue, defaultText] : string[]= useStoredData<string>(providerName, [`${groupName}_radiogroup`, `${groupName}_text`]);
    const [optionValue, setOptionValue] = useState<TOptionKeyType>(defaultValue??'');
    const [optionText, setOptionText] = useState<string>(defaultText??'');
    const [fieldError, setFieldError] = useState('');

    useEffect(()=>{
        if (required && hasError){
            setFieldError(t('errors.field.empty'));
        }
    }, [required, hasError]);

    return (
        <FieldContainer id={groupName}>
            <FormControl sx={{gap: {xs: '16px', md: '24px'}}}>
                <FormLabel required={required}>{t(`step2.${groupName}`, {ns: 'belizForm'})}</FormLabel>
                <RadioGroup
                    sx={{gap: '16px'}}
                    name={`${providerName}.${groupName}_radiogroup`}
                    value={optionValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setOptionValue(event.target.value);
                        const standardOptionText = t(`step2.${event.target.value}`, {ns: 'belizForm'}) ?? '';
                        const text = optionValue === 'other' ? optionText : standardOptionText;
                        setOptionText(text);
                        setFieldError('');
                        dispatch({
                            type: STORE_FORM_DATA,
                            payload: {
                                formName: providerName,
                                fieldName: groupName,
                                fieldValue: text
                            }
                        });
                        dispatch({
                            type: REMOVE_ERROR_FIELD,
                            payload: {
                                errorField: groupName
                            }
                        });
                    }}
                >
                    {
                        Object.keys(options).map(optionId => {
                            return (
                                <FormControlLabel
                                    key = {optionId}
                                    value={optionId}
                                    control={<Radio />}
                                    // label={options[optionId].label}
                                    label={t(`step2.${optionId}`, {ns: 'belizForm'})}
                                />
                            )
                        })
                    }
                    <TextField
                        // size="small"
                        margin={"normal"}
                        placeholder={t('placeholders.enterYourOption', {ns: 'belizForm'})}
                        type="text"
                        name={`${providerName}.${groupName}_text`}
                        multiline
                        rows={2}
                        helperText={optionValue === 'other' ? fieldError : ''}
                        error={!!fieldError && optionValue === 'other'}
                        disabled={optionValue !== 'other'}
                        value={optionText}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setOptionText(event.target.value);
                            setFieldError('');
                        }}
                    />
                    {fieldError && optionValue !== 'other' && <Typography variant="body2" color="error.main">{t('errors.field.emptyBox')}</Typography>}
                </RadioGroup>
            </FormControl>
        </FieldContainer>)
}
