import './AppHeader.css';
import React from "react";
import {useSelector} from "react-redux";
import {userHasRegistered} from "../../redux/mainContactData/mainContactDataSelectors";

export const AppHeader = () => {
    return (
    <div className="AppHeader">
        <div className='AppLogo'></div>
        <div className='HeaderText'>Поможем открыть счет у зарубежного брокера</div>
        <div className='ContactPhone'>+7(999)-999-00-00</div>
    </div>)
}
