import i18n from "../../../i18n/i18n";

export type TOptionData = {
    label: string,
    text?: string
}

export type TOptionKeyType = string;

export type TOptions = {
    [key: TOptionKeyType]: TOptionData
}

export enum TInvesmentGoalTypes {
    investfreemoney = "invest-free-money",
    currencyarbitrage = "currency-arbitrage",
    hedgingcurrencyrisks = "hedging-currency-risks",
    other = "other"
}

export const InvestmentGoalOptions: {
    [key in TInvesmentGoalTypes]: TOptionData
} = {
    [TInvesmentGoalTypes.investfreemoney]: {
        label: 'Вложение свободных денежных средств',
        text: 'Вложение свободных денежных средств',
    },
    [TInvesmentGoalTypes.currencyarbitrage]: {
        label: 'Валютный арбитраж (спекулятивные операции)',
        text: 'Валютный арбитраж (спекулятивные операции)',
    },
    [TInvesmentGoalTypes.hedgingcurrencyrisks]: {
        label: 'Хеджирование валютных рисков',
        text: 'Хеджирование валютных рисков',
    },
    [TInvesmentGoalTypes.other]: {
        label: 'Другое',
        text: ''
    }
}

export enum TEducationTypes {
    highereconomic = "higher-economic",
    higherlegal = "higher-legal",
    highertechnical = "higher-technical",
    nonfinishedhigher = "non-finished-higher",
    secondaryeducation = "secondary-education",
    secondaryspecialeducation = "secondary-special-education",
    other = "other"
}

export const EducationOptions: {
    [key in TEducationTypes]: TOptionData
} = {
    [TEducationTypes.highereconomic]: {
        label: 'Высшее экономическое',
        text: 'Высшее экономическое',
    },
    [TEducationTypes.higherlegal]: {
        label: 'Высшее юридическое',
        text: 'Высшее юридическое',
    },
    [TEducationTypes.highertechnical]: {
        label: 'Высшее техническое',
        text: 'Высшее техническое',
    },
    [TEducationTypes.nonfinishedhigher]: {
        label: 'Незаконченное высшее',
        text: 'Незаконченное высшее',
    },
    [TEducationTypes.secondaryeducation]: {
        label: 'Среднее образование',
        text: 'Среднее образование',
    },
    [TEducationTypes.secondaryspecialeducation]: {
        label: 'Среднее специальное образование',
        text: 'Среднее специальное образование',
    },
    [TEducationTypes.other]: {
        label: 'Другое',
        text: ''
    }
}

export enum TProffessionalTypes {
    economist = "economist",
    lawyer = "lawyer",
    engineer = "engineer",
    military = "military",
    teacher = "teacher",
    other = "other"
}

export const ProffessionalOptions: {
    [key in TProffessionalTypes]: TOptionData
} = {
    [TProffessionalTypes.economist]: {
        label: 'Экономист',
        text: 'Экономист',
    },
    [TProffessionalTypes.lawyer]: {
        label: 'Юрист',
        text: 'Юрист',
    },
    [TProffessionalTypes.engineer]: {
        label: 'Инженер',
        text: 'Инженер',
    },
    [TProffessionalTypes.military]: {
        label: 'Военный',
        text: 'Военный',
    },
    [TProffessionalTypes.teacher]: {
        label: 'Учитель',
        text: 'Учитель',
    },
    [TProffessionalTypes.other]: {
        label: 'Другое',
        text: ''
    }
}

export enum TWorkInBrokerCompany {
    'no' = 'no',
    'yes' = 'yes'
}

export const WorkInBrokerCompanyOptions = {
    [TWorkInBrokerCompany.no]: {
        label: 'Нет'
    },
    [TWorkInBrokerCompany.yes]: {
        label: 'Да'
    }
}

export enum TYesOrNo {
  "no" = "no",
  "yes" = "yes",
}

export const YesOrNoOptions = {  
  [TYesOrNo.yes]: {
    label: "Да",
  },
  [TYesOrNo.no]: {
    label: "Нет",
  },
};

export enum TEstimatedRisk {
    'low' = 'low',
    'middle' = 'middle',
    'high' = 'high'
}

export const EstimatedRiskOptions = {
    [TEstimatedRisk.low]: {
        label: 'Низкая'
    },
    [TEstimatedRisk.middle]: {
        label: 'Средняя'
    },
    [TEstimatedRisk.high]: {
        label: 'Высокая'
    }
}

export enum TExperienceFinancialInstruments {
    'shares' = 'shares',
    'bonds' = 'bonds',
    'derivatives' = 'derivatives'
}

export const ExperienceFinancialInstrumentOptions = {
    [TExperienceFinancialInstruments.shares]: {
        label: 'Акции'
    },
    [TExperienceFinancialInstruments.bonds]: {
        label: 'Облигации и другие инструменты с фиксированной доходностью'
    },
    [TExperienceFinancialInstruments.derivatives]: {
        label: 'Деривативы (Опционы, фьючерсы, свопы, форвардные и другие производственный контракты на основе ценных бумаг, валют, процентных ставок или доходности, производственные контракты)'
    }
}

export enum TWhatExpectedToUse {
    'simple' = 'simple',
    'shares' = 'shares',
    'bonds' = 'bonds',
    'complex' = 'complex'
}

export const WhatExpectedToUseOptions = {
    [TWhatExpectedToUse.simple]: {
        label: 'Простые инструменты'
    },
    [TWhatExpectedToUse.shares]: {
        label: 'Акции'
    },
    [TWhatExpectedToUse.bonds]: {
        label: 'Облигации и другие инструменты с фиксированной доходностью'
    },
    [TWhatExpectedToUse.complex]: {
        label: 'Сложные инструменты (Опционы, фьючерсы, свопы, форвардные и другие производные контракты на основе ценных бумаг)'
    }
}

export enum TEstimatedYearInvestment {
    'lessthan15k' = 'less-than-15k',
    'from15kto50k' = '15k-50k',
    'from50kto100k' = '50k-100k',
    'morethan100k' = '100k+'
}
export const EstimatedYearInvestmentOptions = {
    [TEstimatedYearInvestment.lessthan15k]: {
        label: 'Менее $15 000'
    },
    [TEstimatedYearInvestment.from15kto50k]: {
        label: '$15 000 – $50 000'
    },
    [TEstimatedYearInvestment.from50kto100k]: {
        label: '$50 000 – $100 000'
    },
    [TEstimatedYearInvestment.morethan100k]: {
        label: 'Более $100 000'
    }
}

export enum TEstimatedYearAmount {
    'upto50k' = 'up-to-50k',
    'upto100k' = 'up-to-100k',
    'upto500k' = 'up-to-500k',
    'upto1m' = 'up-to-1m',
    'morethan1m' = '1m+'
}

export const EstimatedYearAmountOptions = {
    [TEstimatedYearAmount.upto50k]: {
        label: 'До $50 000'
    },
    [TEstimatedYearAmount.upto100k]: {
        label: 'До $100 000'
    },
    [TEstimatedYearAmount.upto500k]: {
        label: 'До $500 000'
    },
    [TEstimatedYearAmount.upto1m]: {
        label: 'До $1 000 000'
    },
    [TEstimatedYearAmount.morethan1m]: {
        label: 'Больше $1 000 000'
    },
}

export enum TEstimatedNumberOfOperationsPerYear {
    from1to9 = '1-9',
    from10to99 = '10-99',
    from100to999 = '100-999',
    morethan1000 = '1000+'
}

export const EstimatedNumberOfOperationsPerYearOptions = {
    [TEstimatedNumberOfOperationsPerYear.from1to9]: {
        label: '1 - 9'
    },
    [TEstimatedNumberOfOperationsPerYear.from10to99]: {
        label: '10-99'
    },
    [TEstimatedNumberOfOperationsPerYear.from100to999]: {
        label: '100-999'
    },
    [TEstimatedNumberOfOperationsPerYear.morethan1000]: {
        label: 'Более 1000'
    }
}

export enum TExpectedInvestmentDuration {
    dailytrading = 'daily-trading',
    monthlytrading = 'monthly-trading',
    upto1y = 'up-to-1y',
    from1to3y = '1-3y',
    morethan3y = '3y+'
}

export const ExpectedInvestmentDurationOptions = {
    [TExpectedInvestmentDuration.dailytrading]: {
        label: 'Внутридневная или ежедневная торговля'
    },
    [TExpectedInvestmentDuration.monthlytrading]: {
        label: 'Частая торговля(торговля раз в месяц по каждой позиции)'
    },
    [TExpectedInvestmentDuration.upto1y]: {
        label: 'Менее одного года(торговля несколько раз в год по каждой позиции)'
    },
    [TExpectedInvestmentDuration.from1to3y]: {
        label: 'Планирую держать инвестиции в течение 1-3 лет'
    },
    [TExpectedInvestmentDuration.morethan3y]: {
        label: 'Планирую держать инвестиции более 3 лет'
    },
}

export enum TSalaryRate {
    'lessthan50k' = 'less-than-50k',
    'from50kto100k' = '50k-100k',
    'morethan100k' = '100k+'
}

export const SalaryRateOptions = {
    [TSalaryRate.lessthan50k]: {
        label: 'Менее $50 000'
    },
    [TSalaryRate.from50kto100k]: {
        label: '$50 000 – $100 000'
    },
    [TSalaryRate.morethan100k]: {
        label: 'Более $100 000'
    }
}

export enum TSourceOfIncomeBusiness {
    'no' = 'no',
    'nonprofit' = 'non-profit',
    'casino' = 'casino',
    'finance' = 'finance',
    'cash' = 'cash',
    'cryptocurrency' = 'cryptocurrency'
}

export const SourceOfIncomeBusinessOptions = {
    [TSourceOfIncomeBusiness.no]: {
        label: 'Нет',
        text: 'no'
    },
    [TSourceOfIncomeBusiness.nonprofit]: {
        label: 'Некомерческая деятельность',
        text: 'non-profit',
    },
    [TSourceOfIncomeBusiness.casino]: {
        label: 'Казино',
        text: 'casino'
    },
    [TSourceOfIncomeBusiness.finance]: {
        label: 'Финансовая сфера',
        text: 'finance'
    },
    [TSourceOfIncomeBusiness.cash]: {
        label: 'Малый бизнес с платежами наличными',
        text: 'cash'
    },
    [TSourceOfIncomeBusiness.cryptocurrency]: {
        label: 'Криптовалюты',
        text: 'cryptocurrency'
    }
}

export enum TSalarySource {
    'salary' = 'salary',
    'investment' = 'investment',
    'personalsavings' = 'personal-savings',
    'rent' = 'rent',
    'business' = 'business',
    'other' = 'other'
}

export const SalarySourceOptions = {
    [TSalarySource.salary]: {
        label: 'Зарплата',
        text: 'Зарплата',
    },
    [TSalarySource.investment]: {
        label: 'Доход от инвестиций',
        text: 'Доход от инвестиций'
    },
    [TSalarySource.personalsavings]: {
        label: 'Личные сбережения',
        text: 'Личные сбережения',
    },
    [TSalarySource.rent]: {
        label: 'Рента, дивиденды и другие пассивные виды дохода',
        text: 'Рента, дивиденды и другие пассивные виды дохода',
    },
    [TSalarySource.business]: {
        label: 'Индивидуальное предпринимательство и/или предприниматель без юридического лица',
        text: 'Индивидуальное предпринимательство и/или предприниматель без юридического лица',
    },
    [TSalarySource.other]: {
        label: 'Другое',
        text: '',
    }
}

export enum TFATCAStatus {
    'NonUSPerson' = 'NonUSPerson'
}

export const FATCAStatusOptions = {
    [TFATCAStatus.NonUSPerson]: {
        label: 'Non-U.S person',
        text: 'Non-U.S person',
    },
}

export type TFieldStucture = {
    key?: string,
    optionId: number,
    optionType: 'singlechoice' | 'multiplechoice' | 'selectcountry' | 'textfield',
    optionName: string,
    optionLabel: string,
    required?: boolean,
    options?: { [key: string]: TOptionData },
    placeholder?: string,
    validationRule?: (fieldValue: string) => string | undefined
    errorText?: string
}

export const taxResidencyOptions: TFieldStucture[] = [{
    optionId: 1,
    optionType: 'selectcountry',
    optionName: 'taxresidency',
    optionLabel: 'Налоговое резидентство',
    required: true
}, {
    optionId: 2,
    optionType: 'textfield',
    optionName: 'inn',
    optionLabel: 'Индивидуальный номер налогоплательщика (ИНН)',
    placeholder: '_ _ _ _ _ _ _ _ _ _ _ _ ',
    validationRule: (fieldValue: string) => {
        if (!fieldValue.length) {
            return i18n.t('errors.field.emptyBox');
        } else if (fieldValue.length > 30) {
            return i18n.t('errors.field.less30');
        }
    }
}, {
    optionId: 3,
    optionType: 'multiplechoice',
    optionName: 'investmentgoal',
    optionLabel: 'Цель инвестиций',
    required: true,
    options: InvestmentGoalOptions
}, {
    optionId: 4,
    optionType: 'singlechoice',
    optionName: 'education',
    optionLabel: 'Образование',
    required: true,
    options: EducationOptions
}, {
    optionId: 5,
    optionType: 'singlechoice',
    optionName: 'professional',
    optionLabel: 'Кто вы по профессии?',
    required: true,
    options: ProffessionalOptions
}, {
    optionId: 6,
    optionType: 'singlechoice',
    optionName: 'inbrokercompany',
    optionLabel: 'Вы работаете в компании, основной деятельностью которой являются инвестиции в ценные бумаги?',
    required: true,
    options: WorkInBrokerCompanyOptions
}, {
    optionId: 7,
    optionType: 'singlechoice',
    optionName: 'estimatedrisk',
    optionLabel: 'Укажите желаемую степень риска ваших инвестиций',
    required: true,
    options: EstimatedRiskOptions
}, {
    optionId: 9,
    optionType: 'multiplechoice',
    optionName: 'financialexperience',
    optionLabel: 'Имеете ли вы опыт со следующими финансовыми инструментами?',
    required: true,
    options: ExperienceFinancialInstrumentOptions
}, {
    optionId: 10,
    optionType: 'multiplechoice',
    optionName: 'whatexpectedtouse',
    optionLabel: 'С какими финансовыми инструментами намерены работать?',
    required: true,
    options: WhatExpectedToUseOptions
}, {
    optionId: 11,
    optionType: 'singlechoice',
    optionName: 'estimatedyearinvestment',
    optionLabel: 'Укажите предполагаемую сумму инвестирования в ближайшие 12 месяцев',
    required: true,
    options: EstimatedYearInvestmentOptions
}, {
    optionId: 12,
    optionType: 'singlechoice',
    optionName: 'estimatedyearamount',
    optionLabel: 'Ожидаемый годовой оборот счета',
    required: true,
    options: EstimatedYearAmountOptions
}, {
    optionId: 13,
    optionType: 'singlechoice',
    optionName: 'estimatednumberofoperationsperyear',
    optionLabel: 'Предполагаемое количество операций в год',
    required: true,
    options: EstimatedNumberOfOperationsPerYearOptions
}, {
    optionId: 14,
    optionType: 'singlechoice',
    optionName: 'expectedinvestmentduration',
    optionLabel: 'Ожидаемая продолжительность инвестиций',
    required: true,
    options: ExpectedInvestmentDurationOptions
}, {
    optionId: 15,
    optionType: 'singlechoice',
    optionName: 'salaryrate',
    optionLabel: 'Уровень благосостояния и размер годового дохода (в долларах США)',
    required: true,
    options: SalaryRateOptions
}, {
    optionId: 16,
    optionType: 'multiplechoice',
    optionName: 'sourceofincomebusiness',
    optionLabel: 'Относится ли ваш основной источник дохода к следующим сферам деятельности?',
    required: true,
    options: SourceOfIncomeBusinessOptions
}, {
    optionId: 17,
    optionType: 'multiplechoice',
    optionName: 'salarysource',
    optionLabel: 'Ваш основной источник дохода',
    required: true,
    options: SalarySourceOptions
}, {
    optionId: 18,
    optionType: 'selectcountry',
    optionName: 'salarycountry',
    optionLabel: 'Страна основного источника дохода',
    required: true,
}, {
    optionId: 19,
    optionType: 'selectcountry',
    optionName: 'accountreplenish',
    optionLabel: 'Страна, откуда будет пополняться счет',
    required: true,
}, {
    optionId: 20,
    optionType: 'selectcountry',
    optionName: 'accountwithdrawn',
    optionLabel: 'Страна, куда будут выводиться денежные средства',
    required: true,
}, {
    optionId: 21,
    optionType: 'multiplechoice',
    optionName: 'fatcaStatusIsNonUsCitizen',
    optionLabel: 'FATCA Статус',
    required: true,
    options: FATCAStatusOptions
}, {
    optionId: 22,
    optionType: 'singlechoice',
    optionName: 'isNotUsCitizen',
    optionLabel: 'Я подтверждаю, что не являюсь: гражданином США; лицом с двойным гражданство (США и другой страны); держателем грин карты США',
    required: true,
    options: YesOrNoOptions
}, {
    optionId: 23,
    optionType: 'singlechoice',
    optionName: 'isBeneficialOwner',
    optionLabel: 'Я подтверждаю, что являюсь лицом, являющимся бенефициарным владельцем (или имею право подписывать от имени лица, являющегося бенефициарным владельцем) всех доходов или поступлений, к которым относится эта форма',
    required: true,
    options: YesOrNoOptions
},
];

export const filesOptions = [
    { name: 'files__funds_source_confirmation__wage', text: 'Заработная плата' },
    { name: 'files__funds_source_confirmation__bank_statement', text: 'Сбережения' },
    { name: 'files__funds_source_confirmation__real_estate_sale', text: 'Продажа недвижимости' },
    { name: 'files__funds_source_confirmation__equity_sale', text: 'Продажа акций' },
    { name: 'files__funds_source_confirmation__loan', text: 'Кредит' },
    { name: 'files__funds_source_confirmation__company_sale', text: 'Продажа компании' },
    { name: 'files__funds_source_confirmation__company_profits', text: 'Прибыль компании / Дивиденды' },
    { name: 'files__funds_source_confirmation__inheritance', text: 'Наследование' },
    { name: 'files__funds_source_confirmation__gift', text: 'Подарок' },
];
