import { useSelector } from "react-redux";
import { TAnketaType } from "../redux/userData/userDataTypes";
import { getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../redux/storedAnketas/storedAnketasSelectors";
import { TCountry } from "../redux/countriesList/countriesListTypes";

export type TFieldProps = {
    type: string,
    mask: string | undefined,
    value: string | boolean,
    required: boolean | undefined
}
const validateEmpty = (value: string | boolean) => {
    return (value !== undefined);
}
const validateEmail = (value: string) => {
    return true;
}
const validatePhone = (value: string) => {
    return true;
}
export const validateField = (field: TFieldProps) => {
    if (field.required) {
        if (!validateEmpty(field.value)) return false;
    }
    if (typeof field.value === 'boolean') {
        return true;
    }
    switch (field.mask) {
        case 'email':
            if (!validateEmail(field.value)) return false;
            break;
        case 'phone':
            if (!validatePhone(field.value)) return false;
            break;
        default:
            break;
    }
    return true;
}

export const sendFieldToServer = (field: Element) => {
    if (!(field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) || !field.name) return;

    let value: string | boolean = field.value;
    const [formName, fieldName] = field.name.split('.');

    if (field instanceof HTMLInputElement && field.name.endsWith("_checkbox")) {
        value = field.checked;
    }

    if (field instanceof HTMLInputElement && field.name.endsWith("_radiogroup")) {
        if (field.checked) {
            return {
                formName: formName as TAnketaType,
                fieldName,
                fieldValue: value
            }
        } else
            return;
    }

    const validated = validateField({
        type: field.nodeName.toLowerCase(),
        mask: field.type,
        required: field.required,
        value: value
    })

    if (validated) {
        return {
            formName: formName as TAnketaType,
            fieldName,
            fieldValue: value
        }
    }
    return;
}

export function useStoredData <T>(providerName: string, fieldNames: string[]): T[]{
    const belizData = useSelector(getStoredBelizAnketasData)?.data;
    const localBZAnketasData = useSelector(getLocalStoredBelizAnketasData)?.fields;
    switch (providerName) {
        case 'beliz':
            return fieldNames.map(fieldName => (localBZAnketasData?.[fieldName]?.fieldValue ?? belizData?.[fieldName]) as T);
        default:
            return [];
    }
}

export const getProviderFromURL = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('provider');
}

export const getLanguageFromURL = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('lng');

    return language || 'ru'; 
}

export const languageMapping = (lang:string) => {
    const countryAliases: { [key: string]: string } = {
        am: 'hy',
        ru: 'ru',
        en:'en'
    };

    return countryAliases[lang || 'ru'];
}

export const getPhoneFromURL = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('phone');
}



export const getCountryCodeByValue = (searchCountryValue: string, countries: TCountry[]) => {
    return countries.filter((country) => (country.valueEn === searchCountryValue))?.[0]?.alpha3Code;
}

export const getCountryRiskByValue = (searchCountryValue: string, countries: TCountry[]) => {
    return countries.filter((country) => (country.valueEn === searchCountryValue))?.[0]?.risk.status;
}
