export const INCREASE_CURRENT_PAGE = 'INCREASE_CURRENT_PAGE';
export const MOVE_TO_NEXT_PAGE = 'MOVE_TO_NEXT_PAGE';
export const DECREASE_CURRENT_PAGE = 'DECREASE_CURRENT_PAGE';
export const MOVE_TO_PREV_PAGE = 'MOVE_TO_PREV_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const MOVE_TO_PAGE = 'MOVE_TO_PAGE';
export const INCREASE_PROVIDER_STEP = 'INCREASE_PROVIDER_STEP';
export const MOVE_TO_NEXT_STEP = 'MOVE_TO_NEXT_STEP';
export const DECREASE_PROVIDER_STEP = 'DECREASE_PROVIDER_STEP';
export const MOVE_TO_PREV_STEP = 'MOVE_TO_PREV_STEP';
export const SET_PROVIDER_STEP = 'SET_PROVIDER_STEP';
export const MOVE_TO_STEP = 'MOVE_TO_STEP';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_INTERMEDIATE_STEP = 'SET_INTERMEDIATE_STEP';

export enum TProviderType {
    'none' = 'none',
    'beliz' = 'beliz',
    'armenia' = 'armenia',
    'za' = 'za',
    'ru' = 'ru'
  }

export type TCurrentPositionState = {
    currentPage: number;
    lastPage: number;
    providerStep: number;
    provider: TProviderType;
    currentIntermediateStep: string
}

export type TCurrentPositionIncreasePageAction = {
    type: typeof INCREASE_CURRENT_PAGE | typeof MOVE_TO_NEXT_PAGE;
}

export type TCurrentPositionDecreasePageAction = {
    type: typeof DECREASE_CURRENT_PAGE | typeof MOVE_TO_PREV_PAGE;
}
export type TCurrentPositionSetPageAction = {
    type: typeof SET_CURRENT_PAGE | typeof MOVE_TO_PAGE,
    payload: {
        page: number
    }
}

export type TCurrentPositionIncreaseProviderStepAction = {
    type: typeof INCREASE_PROVIDER_STEP | typeof MOVE_TO_NEXT_STEP;
}

export type TCurrentPositionDecreaseProviderStepAction = {
    type: typeof DECREASE_PROVIDER_STEP | typeof MOVE_TO_PREV_STEP
}

export type TCurrentPositionSetProviderStepAction = {
    type: typeof SET_PROVIDER_STEP | typeof MOVE_TO_STEP,
    payload: {
        step: number
    }
}

export type TSetProviderAction = {
    type: typeof SET_PROVIDER,
    payload: {
        provider: TProviderType
    }
}

export type TSetIntermediateStepAction = {
    type: typeof SET_INTERMEDIATE_STEP,
    payload: string
}

export type TCurrentPositionAction = TCurrentPositionIncreasePageAction |
    TCurrentPositionDecreasePageAction |
    TCurrentPositionSetPageAction |
    TCurrentPositionIncreaseProviderStepAction |
    TCurrentPositionDecreaseProviderStepAction |
    TCurrentPositionSetProviderStepAction |
    TSetProviderAction |
    TSetIntermediateStepAction;


export const TIntermediateStep = {
    ENTER_PHONE: 'ENTER_PHONE',
    CONFIRM_SMS: 'CONFIRM_SMS',
    MAIN_FORM: 'MAIN_FORM'
};
    