import {Box, createTheme, styled, ThemeProvider} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProvider } from '../../../redux/currentPosition/currentPositionSelector';
import { STORE_FORM_DATA } from '../../../redux/userData/userDataTypes';
import { sendFieldToServer } from '../../../utils/common';
import { MainTabsForm } from './MainTabsForm';
import './MainForm.css';
import { SET_REGISTERED } from '../../../redux/mainContactData/mainContactDataTypes';
import { useEffect } from 'react';

const MainFormOuterWrapper = styled(Box)`
  width: 100%;
  height: auto;
`

const belizMainFormOuterWrapperLayout = {
    // display: 'flex',
    // height: 'calc(100vh - 48px)',
    // overflow: 'hidden',
    // height: '100%',
    flex: '1 1 auto',
    backgroundColor: '#FFFFFF',
    '@media (min-width: 981px)': {
        height: 'calc(100vh - 80px)',
        backgroundColor: '#F4F8FF',
    }
}

const armeniaMainFormOuterWrapperLayout = {
    height: 'auto',
}

const zaMainFormOuterWrapperLayout = {
    height: 'auto',
}

const ruMainFormOuterWrapperLayout = {
    height: 'auto',
}

const MainFormWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const belizMainFormWrapperLayout = {
    // height: 'calc(100% - 102px)',
    // overflowY: 'auto',
    // height: '100%',
    backgroundColor: '#FFFFFF',
    '@media (min-width: 981px)': {
        backgroundColor: '#F4F8FF',
    }
}

const belizMainFormInnerWrapper = {
    padding: '24px 20px',
    backgroundColor: '#FFFFFF',
    '@media (min-width: 981px)': {
        padding: '0 40px',
        backgroundColor: '#F4F8FF',
    }
}

const armeniaMainFormWrapperLayout = {
    // paddingTop: '64px',
}

export const MainForm = () => {
    const dispatch = useDispatch();
    const provider = useSelector(getProvider);
    useEffect(()=>{
        if (sessionStorage.getItem('token')){
            dispatch({
                type: SET_REGISTERED
            })
        }
    }, [dispatch])
    const providerStyles = {
        'none': {},
        'beliz': belizMainFormOuterWrapperLayout,
        'armenia': armeniaMainFormOuterWrapperLayout,
        'za': zaMainFormOuterWrapperLayout,
        'ru': ruMainFormOuterWrapperLayout,
    }
    return (
        <MainFormOuterWrapper bgcolor='background.default'
                              sx={providerStyles[provider]}
        >
            <MainFormWrapper className="MainForm-wrapper"
                 sx={provider === 'beliz' ? belizMainFormWrapperLayout : armeniaMainFormWrapperLayout}
                 bgcolor='background.default'
            >
                <Box className="MainForm"
                     bgcolor='background.default'
                     sx={provider === 'beliz' ? belizMainFormInnerWrapper : {}}
                     onBlur={(e: React.SyntheticEvent) => {
                       const data = sendFieldToServer(e.target as Element);
                       if (data) {
                         dispatch({
                           type: STORE_FORM_DATA,
                           payload: data
                         })
                       }
                     }}
                >
                    <MainTabsForm />
                </Box>
            </MainFormWrapper>
        </MainFormOuterWrapper>
        )
}
