import { TProviderType } from "../currentPosition/currentPositionTypes";
import { SET_SUMSUB_BZ_TOKEN, SET_SUMSUB_TOKEN, SET_SUMSUB_STATUS, TSumSubDataState, TSumsubAction, SET_SID } from "./sumSubDataTypes";

const defaultSumSubState: TSumSubDataState = {
    token: undefined,
    bzToken: undefined,
    status: undefined,
    provider: TProviderType.none,
    SID: undefined,
    phoneId: undefined
}

export const sumSubReducer = (state = defaultSumSubState, action: TSumsubAction): TSumSubDataState => {
    switch (action.type) {
        case SET_SID:
            return {
                ...state,
                SID: action.payload.SID,
                phoneId: action.payload.phoneId
            }
        case SET_SUMSUB_TOKEN:
            return {
                ...state,
                token: action.payload.token
            }
        case SET_SUMSUB_BZ_TOKEN:
            return {
                ...state,
                bzToken: action.payload.token
            }
        case SET_SUMSUB_STATUS:
            return {
                ...state,
                status: action.payload.status,
                provider: action.payload.provider
            }
        default:
            return state;
    }
}
