import {
    Box,
    CircularProgress,
    InputAdornment, Link,
    TextField,
    Typography
} from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { TimerComponent } from "../../../components/Timer/Timer";
import {
    DECREASE_PROVIDER_STEP,
    INCREASE_PROVIDER_STEP,
} from "../../../redux/currentPosition/currentPositionTypes";
import {
    getLocalStoredZaAnketasData,
    getStoredZaAnketasData,
} from "../../../redux/storedAnketas/storedAnketasSelectors";
import { fetchData } from "../../../utils/fetch";
import { SET_SUMSUB_TOKEN } from "../../../redux/sumSubData/sumSubDataTypes";
import { useTranslation } from "react-i18next";
import { getPhoneId, getSID } from "../../../redux/sumSubData/sumSubSelectors";
import { sendFormToTN } from "./ZaConfirmDocuments";


const requestCode = async (phone: string) => {
    return fetchData({
        url: "client/ownership/phone/send-sms",
        method: "POST",
        params: {
            phone,
        },
    });
};

const verifyCode = async (phone: string, code: string, SID: string = "", phoneId: string = "") => {
    return await fetchData({
        'url': 'client/anketas/phone/verify-sms-with-tn-za',
        method: 'POST',
        params: {
            phone,
            code,
            SID,
            phoneId
        }
    })
}

const authInSumsub = async () => {
    return await fetchData({
        'url': 'client/anketas/authentificate-sumsub/ZA',
        method: 'POST',
        params: {}
    })
}

const getToken = async (sid: string) => {
    return await fetchData({
        url: "client/anketas/tn/get-sumsub-iframe-token",
        method: "POST",
        params: {
            sid,
        },
    });
};

let myInterval: string | number | NodeJS.Timeout | undefined;

export const ZaConfirmationForm = () => {
    const { t } = useTranslation(["translation", "zaForm"]);
    const [isConfirmBtnMode, setConfirmBtnMode] = useState(true);
    const [helperText, setHelperText] = useState("");
    const [timerExpired, setTimerExpired] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const hasError = useRef(false);

    const zaAnketasData = useSelector(getLocalStoredZaAnketasData);
    const zaServerAnketasData = useSelector(getStoredZaAnketasData);

    const phone = zaAnketasData?.fields.phone?.fieldValue ?? zaServerAnketasData?.data['phone'] as string ?? '';
    const preparedPhone = phone.replace(/\D/g, "");
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const [needClearTimer, setNeedClearTimer] = useState(false);
    let sid = useRef(useSelector(getSID));
    const phoneId = useSelector(getPhoneId);

    /*useEffect(() => {
        const sendSMS = async () => {
            const { success, data } = await requestCode(preparedPhone);
            if (!success) {
                const errorMessage = data?.errorMessage;
                setHelperText(`${t('errors.sms.errorMessage')} : ${errorMessage ? '(' + errorMessage + ')' : ''}`);
            }
        }
        sendSMS()
            .catch(console.error);

    }, [preparedPhone]);*/

    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const onClickBtnHandler = async () => {
        if (isConfirmBtnMode) {
            setLoading(true);
            if (!sid.current){
                const {data} = await authInSumsub();
                sid.current = data?.SID;
            }
            const { success, data } = await verifyCode(preparedPhone, code, sid.current, phoneId);
            if (success) {
                clearInterval(myInterval);
                setNeedClearTimer(true);
                await submitForm();
                setHelperText('');
                setLoading(false);
                setNextForm();
            } else {
                setLoading(false);
                const errorMessage = data?.errorMessage;
                setHelperText(`${t('errors.sms.invalidCode')} : ${errorMessage ? '(' + errorMessage + ')' : ''}`);
            }
        } else {
            setCode('');
            setHelperText('');
            setTimerExpired(false);
            setConfirmBtnMode(true);
            setConfirmButtonEnabled(false);
            inputRef.current?.focus();
            hasError.current = await sendFormToTN({
                localAnketa: zaAnketasData,
                storedAnketa: zaServerAnketasData,
                setLoadingState : setLoading,
                setCreateAnketaErrorText : setHelperText,
                dispatch
            })??false;
        }
    }

    const timerHasExpired = () => {
        setHelperText(t('errors.sms.timeOut'));
        setTimerExpired(true);
        setConfirmBtnMode(false);
    }

    const submitForm = async () => {
        try {
            if (sid.current) {
                const resForToken = await getToken(sid.current);
                if (resForToken?.success) {
                    dispatch({
                        type: SET_SUMSUB_TOKEN,
                        payload: {
                            token: resForToken?.data?.token
                        }
                    })
                }
            }
        } catch (e) {
        }

    }
    const [confirmBtnEnabled, setConfirmButtonEnabled] = useState(false);

    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const setNextForm = () => {
        dispatch({
            type: INCREASE_PROVIDER_STEP
        })
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            onClickBtnHandler();
        }
    };


    useEffect(() => {
        const timeout = setTimeout(() => {
            inputRef.current?.focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: '8px' }}>
            <Typography variant="h5">
                {t('step4.title', { ns: 'zaForm' })}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: '20px' }}>
                <TextField
                    fullWidth
                    margin={"normal"}
                    label={t('fields.sms.label')}
                    placeholder={t('fields.sms.placeholder')}
                    required
                    helperText={helperText}
                    error={!!helperText}
                    inputRef={inputRef}
                    value={code}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {
                                !timerExpired &&
                                <TimerComponent
                                    initialMinute={3}
                                    initialSeconds={0}
                                    setExpired={timerHasExpired}
                                    intervalId={myInterval}
                                    needClearTimer={needClearTimer}
                                />
                            }
                        </InputAdornment>,
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setHelperText('');
                        setCode(event.target.value);
                        if (event.target.value.length > 0 && !confirmBtnEnabled) {
                            setConfirmButtonEnabled(true);
                        }
                        if (!event.target.value.length && confirmBtnEnabled) {
                            setConfirmButtonEnabled(false);
                        }
                    }}
                />
            </Box>
            <Box sx={{ marginTop: '32px', display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, alignItems: 'center', gap: '48px' }}>
                <Link
                    sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: '4px', textDecoration: 'none' }}
                    component="button"
                    color="primary.main"
                    variant="subtitle1"
                    onClick={setPrevForm}
                >
                    <div className="armenia_arrow-left-green"></div>
                    <Typography variant="subtitle1" color={'primary.main'}>
                        {t('buttons.back')}
                    </Typography>
                </Link>
                <ButtonComponent minWidth={120} btnMargin={0}
                    text={isConfirmBtnMode ? t('buttons.confirm') : t('buttons.sendCodeAgain')}
                    onClick={onClickBtnHandler}
                    disabled={(!confirmBtnEnabled && !timerExpired) || isLoading}>
                    {isLoading && <CircularProgress size="1rem" color="inherit" sx={{ marginLeft: "10px" }} />}
                </ButtonComponent>
            </Box>
        </Box>)
}
