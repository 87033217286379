import {Box, Checkbox, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Link, styled, Typography} from "@mui/material"
import React, {Dispatch, useEffect, useState} from "react";
import {ButtonComponent} from "../../../components/Buttons/ButtonComponent";
import {
    DECREASE_PROVIDER_STEP,
    INCREASE_PROVIDER_STEP,
    SET_PROVIDER_STEP
} from "../../../redux/currentPosition/currentPositionTypes";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../utils/fetch";
import {
    getLocalStoredArmeniaAnketasData,
    getStoredArmeniaAnketasData
} from "../../../redux/storedAnketas/storedAnketasSelectors";
import {useTranslation} from "react-i18next";
import { SET_SID } from "../../../redux/sumSubData/sumSubDataTypes";
import { TAnketa } from "../../../redux/userData/userDataTypes";
import { TStoredAnketa } from "../../../redux/storedAnketas/storedAnketasTypes";
import { AnyAction } from "redux";
import {getLanguageFromURL,languageMapping} from "../../../utils/common";
import { RETRY_LATER, SET_POPUP_ERROR } from "../../../redux/errorPopup/errorPopupTypes";
import {trackEventTealium} from "../../../utils/tealiumUtils";
import CloseIcon from "@mui/icons-material/Close";


const CheckBoxesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FileComponentBox = styled(Box)`
  padding: 20px 40px 20px 24px;
  border-radius: 8px;
  border: 1px solid #E1E6ED;
  background: #FFF;
`

const FileComponent = ({fileName, link, fileExtension}: { fileName: string, link: string, fileExtension?: string }) => {
    return (<FileComponentBox>
        <Link
            sx={{display: 'flex', alignItems: 'center', gap: '26px', textDecoration: 'none'}}
            download={fileName+'.'+fileExtension} href={link} target="_blank">
            <div className="pdfIcon_green"></div>
            <Typography variant="h6">
                {fileName}
            </Typography>
        </Link>
    </FileComponentBox>)
}

const signAnketa = async (anketa: TAnketa, storedAnketa: TStoredAnketa | undefined) => {
    const phone = anketa.fields.phone?.fieldValue ?? storedAnketa?.data['phone']
    const preparedPhone = phone.replace('+', '').replace('(', '').replace(')', '').replaceAll('-', '').replaceAll(' ', '');
    const taxResidentCountry = anketa.fields.country?.fieldValue ?? storedAnketa?.data['country'];

    let params:any = {
        phone: preparedPhone,
        login: anketa.fields.email?.fieldValue ?? storedAnketa?.data['email'],
        pwd: anketa.fields.password?.fieldValue ?? storedAnketa?.data['password'],
        innnum: anketa.fields.inn?.fieldValue ?? storedAnketa?.data['inn'],
        tax_resident_country: taxResidentCountry,
        anketa_assets: anketa.fields.wealth?.fieldValue ?? storedAnketa?.data['wealth'],
        lang: languageMapping(getLanguageFromURL())
    };

    if (taxResidentCountry === 'Armenia') {
        params.tax_resident_countryCode = 'AM';
    }

    return await fetchData({
        'url': 'client/anketas/sign/am',
        method: 'POST',
        params: params
    })
}

export const sendFormToTN = async ({
    localAnketa,
    storedAnketa,
    setLoadingState,
    setCreateAnketaErrorText,
    dispatch
} : {
    localAnketa : TAnketa | null,
    storedAnketa: TStoredAnketa | undefined,
    setLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
    setCreateAnketaErrorText: React.Dispatch<React.SetStateAction<string>>,
    dispatch: Dispatch<AnyAction>
}) => {
    let hasError = false;
    if (localAnketa && localAnketa.fields) {
        try {
            setLoadingState(true);
            const res = await signAnketa(localAnketa, storedAnketa);
            if (!res.success) {
                hasError = true;
                
                const knownErrors = [
                    'Неправильная почта или пароль',
                    'Incorrect e-mail or password',
                    'էլ․փոստ կամ գաղտնաբառ'
                ];
                const errorMessage = res?.data?.error?.message || res?.data?.errorMessage || 'Неизвестная ошибка';
                const errorMatch = knownErrors.some(knownError => 
                    errorMessage.toLowerCase().includes(knownError.toLowerCase())
                );

                if (errorMatch) {
                    dispatch({
                        type: SET_PROVIDER_STEP,
                        payload: { 
                            step: 99 
                        }
                    });
                } else {
                    console.log('errorMessage',errorMessage);

                    setCreateAnketaErrorText(errorMessage);
                    // dispatch({
                    //     type: SET_POPUP_ERROR,
                    //     payload: {
                    //         error: RETRY_LATER
                    //     }
                    // });
                }

                setLoadingState(false);
                return hasError;
            }
            hasError = false;
            dispatch({
                type: SET_SID,
                payload: {
                    SID: res?.data?.SID,
                    phoneId: res?.data?.phoneId
                }
            })
            setLoadingState(false);
        } catch (e) {
            setLoadingState(false);
        }

    } else {
        console.error('Нет анкетных данных')
    }

    return hasError;

}

export const ArmeniaConfirmDocuments = () => {
    const { t } = useTranslation(['armeniaForm', 'translation']);
    const [agreement, setAgreement] = useState(true);
    const [correctness, setCorrectness] = useState(true);
    const [requestToJoinBlob, setRequestToJoinBlob] = useState();
    const [requestToJoinLink, setRequestToJoinLink] = useState('');

    const dispatch = useDispatch();
    const localArmeniaData = useSelector(getLocalStoredArmeniaAnketasData);
    const storedArmeniaData = useSelector(getStoredArmeniaAnketasData);
    const storedArmeniaFields: { [key: string]: string } = storedArmeniaData?.data as { [key: string]: string } ?? {};

    const phone = localArmeniaData?.fields['phone']?.fieldValue || storedArmeniaFields['phone'] || '';
    const name = localArmeniaData?.fields['name']?.fieldValue || storedArmeniaFields['name'] || '';

    const [createAnketaErrorText, setCreateAnketaErrorText] = useState('');
    const [isLoading, setLoading] = useState(false);

    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const setNextForm = () => {
        dispatch({
            type: INCREASE_PROVIDER_STEP
        })
    }

    const onClickBtnHandler = async () => {
        const hasError = await sendFormToTN({
            localAnketa: localArmeniaData,
            storedAnketa: storedArmeniaData,
            setLoadingState : setLoading,
            setCreateAnketaErrorText,
            dispatch
        });
        if (!hasError) {
            const eventData = {
                "tealium_event": "form_step5_documents",
                "button_name": "Подписать с помощью SMS-кода",
                "customer_email": storedArmeniaFields?.email,
                "customer_phone": storedArmeniaFields?.phone,
                "customer_last_name": storedArmeniaFields?.lastName,
                "customer_name": storedArmeniaFields?.name,
                "customer_middle_name": storedArmeniaFields?.secondName,
                "form_type": "open_account",
                "form_placement": "frame",
                "tax_residency": storedArmeniaFields?.country
            };
			trackEventTealium(eventData, 'link');
            setNextForm();
        }
    }

    const goToFirstStep = () => {
        dispatch({
            type: SET_PROVIDER_STEP,
            payload: {
                step: 1
            }
        })
    };

    const getJoiningDoc = async () => {
        const doc = await fetchData({
            url: 'documents/am/general-terms-application',
            method: 'GET',
            params: {},
            isBlob: true
        })
        setRequestToJoinBlob(doc);
    }

    useEffect(() => {
        getJoiningDoc();
    }, [])

    useEffect(() => {
        if (requestToJoinBlob) {
            const fileURL = window.URL.createObjectURL(requestToJoinBlob);
            setRequestToJoinLink(fileURL);
        }
    }, [requestToJoinBlob]);

    const handleClose = () => {
        setCreateAnketaErrorText('')
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: '32px'}}>
            <Typography variant="h5">
                {t('step3.title')}
            </Typography>
        <Box sx={{display: "flex", flexDirection: "column", gap: '40px'}}>
            <CheckBoxesContainer>
                <FormControlLabel
                    label={t('step3.iConfirm')}
                    control={<Checkbox checked={agreement} onChange={() => setAgreement(!agreement)}/>}
                />
                <FormControlLabel
                    label={t('step3.iReadAndAgree')}
                    control={<Checkbox checked={correctness}
                                       onChange={() => setCorrectness(!correctness)}/>}
                />
                <Typography variant="body1" color='text.secondary'>
                    {t('step3.conditions')}
                </Typography>
            </CheckBoxesContainer>
            <Box sx={{display: "flex", flexDirection: "column", gap: '24px'}}>
                <FileComponent link="https://academy.cifra-broker.ru/api/uploads/doc/am/Application_for_accession.pdf"
                               fileName={t('step3.files.generalTerms')}/>
                <FileComponent link="https://academy.cifra-broker.ru/api/uploads/doc/am/Application_for_accession_to_the_Regulations_for_individuals.pdf" fileName={t('step3.files.appForIndividuals')}/>
                <FileComponent link="https://academy.cifra-broker.ru/api/uploads/doc/am/Application_for_accession_to_the_Regulations_for_legal_entities.pdf" fileName={t('step3.files.appForLegalEntities')}/>
                <FileComponent link="https://academy.cifra-broker.ru/api/uploads/doc/am/Regulations_for_Brokerage_Services.pdf"
                               fileName={t('step3.files.appForMembership')}
                               fileExtension={t('step3.files.fileExtensions.pdf')}/>
            </Box>
            <Typography variant="subtitle1" sx={{maxWidth: '602px'}}>
                {name}{t('step3.codeToConfirm')} {<b>{phone} </b>}
                {/* {
                    <Link
                        sx={{textDecoration: 'none', cursor: 'pointer'}}
                        component="span"
                        color='primary.main'
                        onClick={goToFirstStep}
                    >
                        {t('buttons.change', { ns: 'translation' })}
                    </Link>
                } */}
            </Typography>
            <Box sx={{marginTop: '16px', display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, alignItems: 'center', gap: '48px'}}>
                <Link
                    sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center', gap: '4px', textDecoration: 'none'}}
                    component="button"
                    color="primary.main"
                    variant="subtitle1"
                    onClick={setPrevForm}
                >
                    <div className="armenia_arrow-left-green"></div>
                    <Typography variant="subtitle1" color={'primary.main'}>
                        {t('buttons.back', { ns: 'translation' })}
                    </Typography>
                </Link>
                <ButtonComponent minWidth={120} btnMargin={0}
                                 text={t('buttons.signWithSMSCode', { ns: 'translation' })}
                                 disabled={!agreement || !correctness || isLoading}
                                 onClick={onClickBtnHandler}>
                           {isLoading && <CircularProgress size="1rem" color="inherit" sx={{marginLeft: "10px"}}/>}
                </ButtonComponent>
            </Box>
        </Box>
        <Dialog
                open={!!createAnketaErrorText}
                onClose={handleClose}
            >
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						width: { xs: 24, sm: 36 },
						height: { xs: 24, sm: 36 },
						padding: "12px",
						position: "absolute",
						right: { xs: 16, sm: 18 },
						top: { xs: 16, sm: 18 },
						color: "#6B6B6B",
					}}
				>
					<CloseIcon />
				</IconButton>
                <DialogTitle>
                    {t('errors.anketaCreation', { ns: 'translation' })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{createAnketaErrorText}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
