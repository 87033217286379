import { useTranslation } from "react-i18next";
import "./AgeNotification.css";

function AgeNotification() {
    const { t } = useTranslation();
    return (
      <div className="age-notification_box">
        <p className="age-notification_text">{t("notifications.age")}</p>
      </div>
    );
}

export default AgeNotification;
