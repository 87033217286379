import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    styled,
    useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isRetryPopup } from "../../redux/errorPopup/errorPopupSelector";
import { REMOVE_POPUP_ERROR } from "../../redux/errorPopup/errorPopupTypes";
import ErrorIcon from '@mui/icons-material/Error';

const DialogContentBox = styled('div')`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

function RetryLaterDialog() {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("armeniaForm");
    const open = useSelector(isRetryPopup);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch({
            type: REMOVE_POPUP_ERROR
        });
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => {
                handleClose();
            }}
        >
            <IconButton
                aria-label="close"
                onClick={() => {
                    handleClose();
                }}
                sx={{
                    width: { xs: 24, sm: 36 },
                    height: { xs: 24, sm: 36 },
                    padding: "12px",
                    position: "absolute",
                    right: { xs: 16, sm: 18 },
                    top: { xs: 16, sm: 18 },
                    color: "#6B6B6B",
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContentBox>
                <ErrorIcon
                    sx={{
                        width: "50px",
                        height: "50px",
                        color: "red",
                        margin: "20px"
                    }}
                />
                <DialogTitle >{t("retryLater.title")}</DialogTitle>
                <DialogContent sx={{ m: 2 }}>
                    <DialogContentText>
                        {t("retryLater.text")}
                    </DialogContentText>
                </DialogContent>
            </DialogContentBox>
        </Dialog>
    );
}

export default RetryLaterDialog;
