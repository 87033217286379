export const UPDATE_CONTACT_DATA_ACTION = 'UPDATE_CONTACT_DATA_ACTION';
export const UPDATE_PHONE_ACTION = 'UPDATE_PHONE_ACTION';
export const SET_REGISTERED = 'SET_REGISTERED';
export const SET_AUTHORIZED = 'SET_AUTHORIZED';
export const UPDATE_COUNTRY_CODE_ACTION = 'UPDATE_COUNTRY_CODE_ACTION';

export type TMainContactDataState = {
    secondName: any;
    name : string,
    lastName : string,
    phone: string,
    email: string,
    authorized: boolean,
    registered: boolean,
    countryCode: string
}

export type TMainContactUpdateDataAction = {
    type: typeof UPDATE_CONTACT_DATA_ACTION,
    payload: {
        data: TMainContactDataState
    }
}

export type TMainContactUpdatePhoneAction = {
    type: typeof UPDATE_PHONE_ACTION,
    payload: {
        phone: string
    }
}


export type TSetUserRegisteredAction = {
    type: typeof SET_REGISTERED,
}

export type TSetUserAuthorizedAction = {
    type: typeof SET_AUTHORIZED,
}

export type TMainContactUpdateCountryCodeAction = {
    type: typeof UPDATE_COUNTRY_CODE_ACTION,
    payload: {
        countryCode: string
    }
}

export type TMainContactDataAction = TMainContactUpdateDataAction  | TMainContactUpdatePhoneAction | TSetUserRegisteredAction | TSetUserAuthorizedAction | TMainContactUpdateCountryCodeAction;