export const SET_STORED_ANKETAS = 'SET_STORED_ANKETAS';
export const UPDATE_ANKETAS_DATA = 'UPDATE_ANKETAS_DATA';
export const GET_SUMSUB_AM_AUTH = 'GET_SUMSUB_AM_AUTH';
export const GET_SUMSUB_ZA_AUTH = 'GET_SUMSUB_ZA_AUTH';
export const GET_SUMSUB_RU_AUTH = 'GET_SUMSUB_RU_AUTH';

export type TSetStoredAnketasAction = {
    type: typeof SET_STORED_ANKETAS,
    payload: {
        anketas: Array<TStoredAnketa>
    }
}

export type TUpdateStoredAnketasAction ={
    type: typeof UPDATE_ANKETAS_DATA,
    payload: {
        anketa: TStoredAnketa
    }
}

export type TStoredAnketaField = {
    [key: string] : string | boolean
}

export type TStoredAnketa = {
    type: string;
    phone?: string,
    data: TStoredAnketaField,
    step: number
}

export type TStoredAnketasState = {
    anketas: Array<TStoredAnketa>,
    anketasLoaded: boolean
}

export type TStoredAnketasAction = TSetStoredAnketasAction | TUpdateStoredAnketasAction;