
import { SET_FIELD_VISIBILITY, TFieldsVisibilityState, TVisibilityAction } from "./fieldsVisibilityTypes";

const defaultVisibilityState = {
}

export const fieldsVisibilityReducer = (state = defaultVisibilityState, action: TVisibilityAction): TFieldsVisibilityState => {
    switch (action.type) {
    case SET_FIELD_VISIBILITY:
        return {
            ...state,
            [action.payload.fieldName]: {
                visible: action.payload.visible
            }
        }
        default:
            return state;
    }
}