import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

function BackdropLoader( { showLoader }: { showLoader: boolean } ) {
    const { t } = useTranslation();
    
    return (
        <Backdrop
            sx={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                gap: "50px",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={showLoader}
        >
            <Typography variant="h6">{t("loader")}</Typography>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default BackdropLoader