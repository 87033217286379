export const SET_ADMIN_EDIT_ANKETA_MODE = 'SET_ADMIN_EDIT_ANKETA_MODE';
export type TAdminEditAnketaModeState = {
    inEditMode: boolean,
    anketaId: string | undefined
}

export type TAdminSetEditAnketaModeAction = {
    type: typeof SET_ADMIN_EDIT_ANKETA_MODE,
    payload: {
        editMode: boolean,
        anketaId: string | undefined
    }
}

export type TAdminEditAnketaModeAction = TAdminSetEditAnketaModeAction