import './HasAccountEmailMessage.css';
import { useEffect, useState } from 'react';
import { Box, Link, styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TProviderType, SET_INTERMEDIATE_STEP, TIntermediateStep, SET_PROVIDER_STEP, SET_CURRENT_PAGE } from "../../redux/currentPosition/currentPositionTypes";
import { ButtonComponent } from "../Buttons/ButtonComponent";
import { FormContainer } from "../FieldContainer/FieldContainer";
import { armeniaFormContainer, zaFormContainer, formContainerStyles } from "../../forms/MainHelpersApp/RegistrationForm/LoginByPhoneForm";
import { logout } from "../../utils/logout";
import LoginDialog from './LoginDialog';
import { newAnketa } from "../../utils/anketaActions";
import { useDispatch } from 'react-redux';
import { GET_ANKETAS_DATA } from '../../redux/continueAnketaPopup/continueAnketaPopupTypes';

export type THasAccountMessageProps = {
	provider: TProviderType;
}

function HasAccountEmailStep4({ provider }: THasAccountMessageProps) {
	const { t } = useTranslation();
	const theme = useTheme();

	const dispatch = useDispatch();

	const handleStartNew = async () => {
		dispatch({
			type: SET_PROVIDER_STEP,
			payload: { step: 1 }
		});
		
		await newAnketa(provider, dispatch);
		dispatch({
			type: GET_ANKETAS_DATA,
			payload: {
				provider: TProviderType[provider],
				needAConfirmWindow: false
			}
		});


	};

	return (
		<FormContainer
			sx={formContainerStyles[provider]}
			bgcolor={{ sm: theme.palette.background.paper }}
		>
			<Box className="armeniaHasEmailBox">
				<Box className="armeniaHasEmailTexts">
					<Typography variant={'h1'}>{t('account.emailExist')}</Typography>
					<Typography variant={'subtitle1'}>{t('account.emailMaybeTaken')}</Typography>
				</Box>

				<Box className="armeniaHasEmailBtns">
					<Link
						className="styledLink"
						onClick={handleStartNew}
					>
						<Typography variant="subtitle1" className="noUnderline">
							{t('account.emailStartNew')}
						</Typography>
					</Link>
				</Box>
			</Box>
		</FormContainer>
	)
}

export default HasAccountEmailStep4