import { Box } from "@mui/material";
import SumsubWebSdk from '@sumsub/websdk-react';
import { getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DECREASE_PROVIDER_STEP } from "../../../redux/currentPosition/currentPositionTypes";
import { getSumSubBzToken } from "../../../redux/sumSubData/sumSubSelectors";



export const BelizSumSubForm = () => {
    const accessToken = useSelector(getSumSubBzToken);
    const storedBelizData = useSelector(getStoredBelizAnketasData);
    const localStoredBelizData = useSelector(getLocalStoredBelizAnketasData);

    const storedBelizFields: { [key: string]: string } = storedBelizData?.data as { [key: string]: string } ?? {};

    const userPhone = sessionStorage.getItem('phone') || '';
    const userEmail = localStoredBelizData?.fields['email']?.fieldValue || storedBelizFields['email'] || '';

    const dispatch = useDispatch();

    useEffect(()=>{
        if (!accessToken){
            dispatch({
                type: DECREASE_PROVIDER_STEP
          })
        }
    }, [accessToken, dispatch])

    const config = {
        lang: 'ru',
        email: userEmail,
        phone: userPhone,
        i18n: { "document": { "subTitles": { "IDENTITY": "Upload a document that proves your identity" } } },
        onMessage: (type: any, payload: any) => {
        }
    }

    const accessTokenExpirationHandler = () => {
        return new Promise<string>((resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void) => { resolve('success') });
    }

    return (<Box sx={{width: '100%', marginBottom: {xs: '60px', md: '120px'}}}>{<SumsubWebSdk
        accessToken={accessToken??''}
        expirationHandler={accessTokenExpirationHandler}
        config={config}
        options={{}}
    />
    }</Box>)
}
