import { LOAD_USER_ANKETAS_ACTION, SET_ANKETAS_APPLIED_FILTERS_ACTION, SET_ANKETAS_EXISTING_FILTERS_ACTION, TAdminAnketasAction, TAdminAnketasState } from "./adminAnketasTypes";

const defaultAdminAnketasState: TAdminAnketasState = {
    anketas: {
        rows: [],
        header: [],
        count: 0
    },
    filterNames: [],
    appliedFilters: []
}

export const adminAnketasReducer = (state = defaultAdminAnketasState, action: TAdminAnketasAction): TAdminAnketasState => {
    switch (action.type) {
        case LOAD_USER_ANKETAS_ACTION: {
            return {
                ...state,
                anketas: action.payload.anketas
            }
        }
        case SET_ANKETAS_EXISTING_FILTERS_ACTION: {
            return {
                ...state,
                filterNames: action.payload.filters
            }
        }
        case SET_ANKETAS_APPLIED_FILTERS_ACTION: {
            return {
                ...state,
                appliedFilters: state.appliedFilters.find(filter => filter.field_name === action.payload.filter.field_name) ? state.appliedFilters.map(appliedFilter => appliedFilter.field_name === action.payload.filter.field_name ? action.payload.filter : appliedFilter) : [...state.appliedFilters, action.payload.filter]
            }
        }
        default:
            return state;
    }
}