import { TApplicationState } from "../rootReducer"

export const getStoredCommonAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.storedAnketas?.anketas?.find(anketa => anketa.type === 'common');
}

export const getStoredBelizAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa?.storedAnketas?.anketas?.find(anketa => anketa.type === 'BZ');
}

export const getStoredArmeniaAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa?.storedAnketas?.anketas?.find(anketa => anketa.type === 'AM');
}

export const getStoredZaAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa?.storedAnketas?.anketas?.find(anketa => anketa.type === 'ZA');
}

export const getStoredRuAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa?.storedAnketas?.anketas?.find(anketa => anketa.type === 'RU');
}

export const getLocalStoredArmeniaAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.data.anketas.armenia;
}

export const getLocalStoredBelizAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.data.anketas.beliz;
}

export const getLocalStoredZaAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.data.anketas.za;
}

export const getLocalStoredRuAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.data.anketas.ru;
}

export const getLocalStoredCommonAnketasData = (state : TApplicationState ) =>{
    return state.usersAnketa.data.anketas.common;
}

export const wasAnketasLoaded = (state : TApplicationState ) =>{
    return state.usersAnketa.storedAnketas.anketasLoaded;
}

export const getApplicantFiles = (state : TApplicationState ) =>{
    return state.usersAnketa.data.applicantFiles??[];
}
