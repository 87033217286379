import { SET_AUTHORIZED, SET_REGISTERED, TMainContactDataAction, TMainContactDataState, UPDATE_CONTACT_DATA_ACTION, UPDATE_COUNTRY_CODE_ACTION, UPDATE_PHONE_ACTION } from "./mainContactDataTypes"

const defaultMainContactDataState = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    secondName: '',
    authorized: false,
    registered: false,
    countryCode: 'ru'
}

export const mainContactDataReducer = (state = defaultMainContactDataState, action: TMainContactDataAction): TMainContactDataState => {
    switch (action.type) {
        case UPDATE_CONTACT_DATA_ACTION:
            return {
                ...state,
                name: action.payload.data.name,
                lastName: action.payload.data.lastName,
                email: action.payload.data.email
            };
        case UPDATE_PHONE_ACTION:
            return {
                ...state,
                phone: action.payload.phone,
            };
        case UPDATE_COUNTRY_CODE_ACTION:
            return {
                ...state,
                countryCode: action.payload.countryCode,
            };
        case SET_REGISTERED:
            return {
                ...state,
                registered: true
            }
        case SET_AUTHORIZED:
            return {
                ...state,
                authorized: true
            }
        default:
            return state;
    }
}