import { Box, Checkbox, FormControlLabel, Link, styled, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import './MainBelizForm.css'
import { useTranslation } from "react-i18next";
import { FormContainer, FormRow, FormWrapper } from "../../../components/FieldContainer/FieldContainer";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  INCREASE_PROVIDER_STEP,
  SET_PROVIDER_STEP
} from "../../../redux/currentPosition/currentPositionTypes";
import { getFileUrls } from "../../../redux/fetchedDocuments/fetchedDocumentsSelectors";
import { FETCH_DOCS_FROM_SERVER } from "../../../redux/fetchedDocuments/fetchedDocumentsTypes";
import { extractBelizAnketasData } from "../../../utils/get_beliz_anketas_data";
import { TAnketa } from "../../../redux/userData/userDataTypes";
import { TStoredAnketa } from "../../../redux/storedAnketas/storedAnketasTypes";
import { getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";

const CheckBoxesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FileComponentBox = styled(Box)`  
  width: 100%;
  max-width: 645px;
  padding: 12px 8px 12px 14px;
  border-radius: 8px;
  border: 1px solid #E1E6ED;
  background: #FFFFFF;
  display: flex;
  gap: 6px;
  @media (min-width: 981px) {
    padding: 24px 40px 24px 24px;
  }
`

const FileComponent = ({ fileName, link }: { fileName: string, link: string }) => {
  return (<FileComponentBox>
    <Link
      sx={{ display: 'flex', alignItems: 'center', gap: { xs: '18px', md: '26px' }, textDecoration: 'none' }}
      download={fileName} href={link} target="_blank"
    >
      <div className="pdfIcon"></div>
      <Typography variant="body1" color="text.primary">
        {fileName}
      </Typography>
    </Link>
  </FileComponentBox>)
}

const createBelizAnketasParams = (keys: string[], data: { [key: string]: string | boolean | (string | undefined)[] | null | undefined }) => {
  const anketasData: { [key: string]: any } = {};
  for (let i = 0; i < keys.length; i++) {
    const objKey = keys[i];
    if (typeof data[objKey] === 'string') {
      const prop: string = data[objKey] as string;
      anketasData[objKey] = {
        [prop]: true
      }
    } else if (Array.isArray(data[objKey])) {
      const prop: string[] = data[objKey] as string[];
      anketasData[objKey] = prop.reduce((a, v) => ({ ...a, [v]: true }), {})
    }
  }
  return anketasData;
}

const collectAnketasData = (bzAnketasData: TAnketa | null, bzServerAnketasData: TStoredAnketa | undefined) => {
  const belizData = extractBelizAnketasData(bzAnketasData, bzServerAnketasData);
  const mainData = {
    "isForExample": true,
    "isCompanyBankRekvizHidden": true,
    "AgreementNumber": "",
    "AgreementDate": "",
    "SurName": belizData["SurName"] ?? '',
    "NameRus": belizData["NameRus"] ?? '',
    "Patronymik": belizData["Patronymik"] ?? '',
    "SurNameENG": belizData["SurName"] ?? '',
    "NameENG": belizData["NameRus"] ?? '',
    "BirthDate": "",
    "Gragdanstvo": "",
    "GragdanstvoTranslit": "",
    "RegCountry": belizData["taxResidency"] ?? '',
    "RegCountryTranslit": belizData["taxResidency"] ?? '',
    "DocNumber": "",
    "Issue": "",
    "IssueTranslit": "",
    "IssueDate": "",
    "AdresRegRUS": "",
    "AdresRegENG": "",
    "PostAdresRUS": "",
    "PostAdresTranslit": "",
    "INN": belizData['INN'] ?? '',
    "SNILS": " ",
    "email": belizData['email'] ?? '',
    "MobilePhone": belizData['MobilePhone'] ?? '',
    "HomePhone": "",
    "planName": {
      "Rus": "",
      "Eng": "",
      "Long": 0,
      "Short": 0
    },

    "Signer": "",
    "SignerRus": "",
    "SignerEng": "",
    "SignerShortName": "",
    "curDate": "",
    "SMSCODE": "",
    "accounts": [
      {
        "account": "",
        "currenct": "",
        "plan": "",
        "is_it_closed": ""
      }
    ],
    "recvizTypeFFIN": false,
    "recvizTypeALFARU": false,
    "recvizTypeALFAKZ": false,
    "recvizTypeFFINKZ": false,
    "recvizTypeKHBKZ": false,
    "ifSignToNotRu": false,
    "ifSignToNotRuNotPd": false,
    "ifSignToKzDp": false,
    "ifSignToAll": false,
    "DeclarationNumber": "",
    "DeclarationInitialDate": "",
    "AgentFeeEng": "",
    "AgentFeeRus": "",
    "OptionalTags": ["AgreementDate", "BirthDate", "IssueDate", "curDate", "rekvizits", "anketa"]
  }

  const anketasBoolParams = createBelizAnketasParams(
    ['annualIncome',
      'anticipatedInvestment',
      'estimatedNumberOfOperationsPerYear',
      'expectedAnnualAccountTurnover',
      'expectedDurationOfInvestments',
      'experienceFinancialInstruments',
      'financialInstruments',
      'riskOfInvestments',
      'sourceOfIncomeBusiness'
    ], belizData);

  const anketasData = {
    ...anketasBoolParams,
    "education": belizData["education"],
    "investmentsOrSecuritiesEmployee": belizData["investmentsOrSecuritiesEmployee"],
    "occupation": belizData["occupation"],
    "paymentsFromCountry": belizData["paymentsFromCountry"],
    "paymentsToCountry": belizData["paymentsToCountry"],
    "primaryIncomeSource": belizData["primaryIncomeSource"] ? belizData["primaryIncomeSource"].join(', ') : '',
    "primaryIncomeSourceCountry": belizData["primaryIncomeSourceCountry"],
    "purposeOfInvestments": belizData["purposeOfInvestments"] ? belizData["purposeOfInvestments"].join(', ') : '',
  }

  return {
    ...mainData,
    anketa: anketasData
  };
}

export const BelizConfirmDocuments = () => {
  const { t } = useTranslation(['translation', 'belizForm']);
  const [agreement, setAgreement] = useState(true);
  const [correctness, setCorrectness] = useState(true);
  const localStoredBelizData = useSelector(getLocalStoredBelizAnketasData);
  const storedBelizData = useSelector(getStoredBelizAnketasData);
  const storedBelizFields: { [key: string]: string } = storedBelizData?.data as { [key: string]: string } ?? {};

  const phone = sessionStorage.getItem('phone') || '';
  const name = localStoredBelizData?.fields['name']?.fieldValue || storedBelizFields['name'] || '';
  const fileUrls = useSelector(getFileUrls);

  const dispatch = useDispatch();

  const setNextForm = () => {
    dispatch({
      type: INCREASE_PROVIDER_STEP
    })
  }

  const goToFirstStep = () => {
    dispatch({
      type: SET_PROVIDER_STEP,
      payload: {
        step: 1
      }
    })
  };

  useEffect(() => {
    const body = document.querySelector('.MainForm-wrapper');
    setTimeout(() => {
      body?.scrollTo({ top: 0, behavior: 'smooth' })
    }, 50)
  }, []);



  useEffect(() => {
    const anketasData = collectAnketasData(localStoredBelizData, storedBelizData)
    dispatch({
      type: FETCH_DOCS_FROM_SERVER,
      payload: {
        docIds: ['152', '154-1836', '156', '236'],
        url: '',
        data: anketasData
      }
    })
  }, [localStoredBelizData, storedBelizData])

  return (
    <FormWrapper>
      <FormContainer>
        <Typography variant="h3">
          {t('step3.title', {ns: 'belizForm'})}
        </Typography>
        <Box sx={{
          maxWidth: '390px',
          padding: '16px',
          borderRadius: '16px',
          background: '#F4F8FF',
          display: { xs: 'block', md: 'none' }
        }}>
          <Typography variant="caption" color="#647294">
            {t('step3.docsDescription', {ns: 'belizForm'})}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: '8px', md: '24px' } }}>
          {Object.keys(fileUrls)?.length ? <>
            <FileComponent link={fileUrls['152'] ?? ''} fileName={t('step3.files.appForAccession', {ns: 'belizForm'})} />
            <FileComponent link={fileUrls['154-1836'] ?? ''} fileName={t('step3.files.regulations', {ns: 'belizForm'})} />
            <FileComponent link={fileUrls['156'] ?? ''} fileName={t('step3.files.accountOpeningNotification', {ns: 'belizForm'})} />
            <FileComponent link={fileUrls['236'] ?? ''} fileName={t('step3.files.feesAndTariffCharges', {ns: 'belizForm'})} />
          </> :
            <>{t('step3.waitDocsGenerated', {ns: 'belizForm'})}</>
          }
        </Box>
        <CheckBoxesContainer>
          <FormControlLabel
            label={t('step3.iReadAndAgree', {ns: 'belizForm'})}
            control={<Checkbox checked={agreement} onChange={() => setAgreement(!agreement)} />}
          />
          {!agreement && <Typography variant="caption" color="error.main" marginTop="-10px">{t('errors.field.confirmation')}</Typography>}
          <FormControlLabel
            label={t('step3.iConfirmData', {ns: 'belizForm'})}
            control={<Checkbox checked={correctness} onChange={() => setCorrectness(!correctness)} />}
          />
          {!correctness && <Typography variant="caption" color="error.main" marginTop="-10px">{t('errors.field.confirmation')}</Typography>}
        </CheckBoxesContainer>
        <Typography variant="body1">
          {name}{t('step3.codeToConfirm', {ns: 'belizForm'})}
          <Typography fontWeight="500" component="span">{phone} </Typography>
          <Link sx={{ cursor: 'pointer' }} underline="none" onClick={goToFirstStep}>
            {t('buttons.change')}
          </Link>
        </Typography>
        <ButtonComponent text={t('buttons.signWithSMSCode')} btnMargin={0} onClick={setNextForm} disabled={!agreement || !correctness} />
      </FormContainer>
      <Box sx={{ paddingTop: '155px', width: '440px', display: { xs: 'none', md: 'block' } }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flex: '1 0 0' }}>
          <div className="frame-334847"></div>
          <Box sx={{ width: '426px', padding: '32px', borderRadius: '16px', background: '#FFF' }}>
            <Typography variant="caption" color="#647294">
              {t('step3.docsDescription', {ns: 'belizForm'})}
            </Typography>
          </Box>
        </Box>
      </Box>
    </FormWrapper>
  )
}
