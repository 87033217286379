export const {
    getDocumentFromDb,
    putDocumentInDb,
    deleteDocumentFromDb,
    getAllDocumentNamesFromDb,
    deleteAllDocumentsFromDb } = (function () {
        let indexedDB = window.indexedDB,
            dbVersion = 1.0;

        // Create/open database
        let request = indexedDB.open("documents", dbVersion),
            db: IDBDatabase,
            createObjectStore = function (dataBase: { createObjectStore: (arg0: string) => void; }) {
                // Create an objectStore
                dataBase.createObjectStore("documents");
            },

            putDocumentInDb = function (blob: any, documentName: string) {

                // Open a transaction to the database
                let transaction = db.transaction(["documents"], 'readwrite');

                transaction.objectStore("documents").put(blob, documentName);
            },

            getDocumentFromDb = function (documentName: string) {

                // Open a transaction to the database
                let transaction = db.transaction(["documents"], 'readwrite');

                const IDBRequest = transaction.objectStore("documents").get(documentName);

                return new Promise((resolve, reject) => {
                    IDBRequest.onsuccess = (event : any) => {
                        resolve(event?.target?.result);
                    };
                });
            },

            deleteDocumentFromDb = function (documentKey: string) {

                // Open a transaction to the database
                let transaction = db.transaction(["documents"], 'readwrite');
                transaction.objectStore("documents").delete(documentKey);
            },

            getAllDocumentNamesFromDb = function () {

                let transaction = db.transaction(["documents"], 'readonly');
                const IDBRequest = transaction.objectStore("documents");
                let request = IDBRequest.openCursor();

                return new Promise( (resolve, reject) => {
                    const filesNames: { [key: string]: string } = {};
                    request.onsuccess = (event : any) => {
                        let cursor = request.result;
                        if (cursor) {
                            let key = cursor.key; // ключ документа
                            let value = cursor.value; // объект документа
                            let fileName = cursor.value?.name || ''; // имя документа
                            filesNames[key as string] = fileName;
                            // console.log(key, fileName);
                            cursor.continue();
                        } else {
                            // console.log("Документов больше нет");
                            resolve(filesNames);
                        }
                    };
                });
            },
            deleteAllDocumentsFromDb = function () {

                // Open a transaction to the database
                let transaction = db.transaction(["documents"], 'readwrite');
                transaction.objectStore("documents").clear();
            }

        request.onerror = function () {
        };

        request.onsuccess = function () {
            db = request.result;

            db.onerror = function () {
            };
        }

        request.onupgradeneeded = function () {
            createObjectStore(request.result);
        };
        return {
            getDocumentFromDb,
            putDocumentInDb,
            deleteDocumentFromDb,
            getAllDocumentNamesFromDb,
            deleteAllDocumentsFromDb
        };
    })();
