export const SET_POPUP_ERROR = "SET_POPUP_ERROR";
export const REMOVE_POPUP_ERROR = "REMOVE_POPUP_ERROR";
export const RETRY_LATER = "RETRY_LATER";

export type TErrorType = typeof RETRY_LATER | null;

export type TSetPopupErrorAction = {
    type: typeof SET_POPUP_ERROR;
    payload: {
      error:  TErrorType,
    };
  };

  
  export type TRemovePopupErrorAction = {
    type: typeof REMOVE_POPUP_ERROR;
  };

export type TPopupErrorAction = TSetPopupErrorAction | TRemovePopupErrorAction;

export type TPopupErrorsState = {
    error: TErrorType,
};