import { useDispatch, useSelector } from "react-redux"
import { AdminLoginForm } from "./AdminLoginForm"
import { adminHasBeenAuthorized } from "../../redux/adminData/adminDataSelector";
import { AdminAnketasForm } from "./AdminAnketasForm";
import { createTheme, ThemeProvider } from '@mui/material';
import { AUTHORIZE_ADMIN } from "../../redux/adminData/adminDataTypes";
import { AdminEditAnketaForm } from "./AdminEditAnketaForm";

const adminTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    backgroundColor: 'white',
                }
            }
        }
    },
});

export const AdminHelpersAppForm = () => {
    const adminIsAuthorized = useSelector(adminHasBeenAuthorized);
    const dispatch = useDispatch()
    if (sessionStorage.getItem('admin_token')) {
        dispatch({
            type: AUTHORIZE_ADMIN
        })
    }
    return (<ThemeProvider theme={adminTheme}>
        {adminIsAuthorized ? (<>
            <AdminAnketasForm />
            <AdminEditAnketaForm />
        </>)
            : <AdminLoginForm />}
    </ThemeProvider>)
}