import { Box, styled } from "@mui/material"
import './MainArmeniaForm.css'
import { ArmeniaConfirmationForm } from "./ArmeniaConfirmationForm";
import { ArmeniaConfirmDocuments } from "./ArmeniaConfirmDocuments";
import { ArmeniaMainContactData } from "./ArmeniaMainContactData";
import { ArmeniaSumSubForm } from "./ArmeniaSumSubForm";
import { ArmeniaTaxResidency } from "./ArmeniaTaxResidency";
import { Logout } from "../../../components/Logout/Logout";
import NeedHelpButton from "../../../components/NeedHelpButton/NeedHelpButton";
import RetryLaterDialog from "../../../components/Dialogs/RetryLater";

const ArmeniaFormMenuButtons = styled(Box)`
    position: static;
    @media (min-width:640px) {
        display: flex;
        align-items: center;
        gap: 12px;
        position: absolute;
        top: 20px;
        right: 24px;
    }  
`

export const MainArmeniaForm = ({ activeStep }: { activeStep: number }) => {    
    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        {activeStep === 1 && <ArmeniaMainContactData />}
        {activeStep === 2 && <ArmeniaTaxResidency />}
        {activeStep === 3 && <ArmeniaConfirmDocuments />}
        {activeStep === 4 && <ArmeniaConfirmationForm />}
        {activeStep > 4 && activeStep < 10 && <ArmeniaSumSubForm />}
        <ArmeniaFormMenuButtons>
            <NeedHelpButton />            
            <Logout style={{ display: {xs: 'none', sm: 'flex'} }} />            
        </ArmeniaFormMenuButtons>
        <RetryLaterDialog/>
    </Box>)
}
