import { SET_STORED_ANKETAS, TStoredAnketasAction, TStoredAnketasState, UPDATE_ANKETAS_DATA } from "./storedAnketasTypes";

const defaultStoredAnketasState: TStoredAnketasState = {
    anketas: [],
    anketasLoaded: false
}

export const storedAnketasReducer = (state = defaultStoredAnketasState, action: TStoredAnketasAction): TStoredAnketasState => {
    switch (action.type) {
    case SET_STORED_ANKETAS:
        return {
            ...state,
            anketas: action.payload.anketas,
            anketasLoaded: true
        }
        case UPDATE_ANKETAS_DATA:
            return {
                ...state,
                anketas: state.anketas?.map(anketa => anketa?.type === action.payload.anketa.type ? action.payload.anketa : anketa)
            }
        default:
            return state;
    }
}