import { AUTHORIZE_ADMIN, TAdminDataAction, TAdminDataState, UNAUTHORIZE_ADMIN } from "./adminDataTypes";

const defaultAdminDataState: TAdminDataState = {
    authorized: false,
}

export const adminDataReducer = (state = defaultAdminDataState, action: TAdminDataAction): TAdminDataState => {
    switch (action.type) {
        case AUTHORIZE_ADMIN: {
            return{
                ...state,
                authorized : true
            }
        }
        case UNAUTHORIZE_ADMIN: {
            return{
                ...state,
                authorized : false
            }           
        }
        default:
            return state;
    }
}