import { useSelector } from "react-redux";
import { getProvider } from "../../../redux/currentPosition/currentPositionSelector";
import { ArmeniaForm } from "../ArmeniaForm/ArmeniaForm";
import { BelizForm } from "../BelizForm/BelizForm"
import {ZaForm} from "../ZaForm/ZaForm";
import {RuForm} from "../RuForm/RuForm";

export const ProviderForm = () => {
    const provider = useSelector(getProvider);
    return (<>
        {provider === 'beliz' && <BelizForm />}
        {provider === 'armenia' && <ArmeniaForm />}
        {provider === 'za' && <ZaForm />}
        {provider === 'ru' && <RuForm />}
    </>)
}