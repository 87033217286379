import { combineReducers } from "redux";
import { continueAnketaPopupReducer } from "./continueAnketaPopup/continueAnketaPopupReducer";
import { currentPositionReducer } from "./currentPosition/currentPositionReducer";
import { mainContactDataReducer } from "./mainContactData/mainContactDataReducers";
import { storedAnketasReducer } from "./storedAnketas/storedAnketasReducer";
import { userDataReducer } from "./userData/userDataReducer";
import { sumSubReducer } from "./sumSubData/sumSubReducer";
import { countriesReducer } from "./countriesList/countriesListReducer";
import { fieldsVisibilityReducer } from "./fieldsVisibility/fieldsVisibilityReducer";
import { belizErrorsReducer } from "./belizErrors/belizErrorsReducer";
import { fetchedDocumentsReducer } from "./fetchedDocuments/fetchedDocumentsReducer";
import { accountReducer } from "./accountData/accountReducer";
import { errorPopupReducer } from "./errorPopup/errorPopupReducer";


export const userRootReducer = combineReducers({
    mainContactData: mainContactDataReducer,
    currentPosition: currentPositionReducer,
    data: userDataReducer,
    storedAnketas: storedAnketasReducer,
    continueAnketaPopup: continueAnketaPopupReducer,
    sumSubData: sumSubReducer,
    countriesData: countriesReducer,
    fieldsVisibility: fieldsVisibilityReducer,
    belizFormErrors: belizErrorsReducer,
    documents: fetchedDocumentsReducer,
	accountData: accountReducer,
    hasErrorPopup: errorPopupReducer
})