import {
    Box,
    FormControl,
    FormLabel,
    IconButton,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import { FieldContainer } from "../../../components/FieldContainer/FieldContainer";
import {deleteDocumentFromDb, getAllDocumentNamesFromDb, putDocumentInDb} from "../../../utils/uploadFile";
import { useEffect, useState } from "react";
import { filesOptions } from "./BelizConfigTypes";
import { AttachFile } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from "react-redux";
import { STORE_APPLICANT_FILES, REMOVE_FILE_NAME, STORE_FILE_NAME, STORE_FILES_NAMES } from "../../../redux/userData/userDataTypes";
import {useTranslation} from "react-i18next";
import {REMOVE_ERROR_FIELD} from "../../../redux/belizErrors/belizErrorsTypes";
import {MAIN_API_URL} from "../../../utils/endpoints";
import {getLanguageFromURL, getProviderFromURL} from "../../../utils/common";
import {TProviderType} from "../../../redux/currentPosition/currentPositionTypes";
import {AppHeader} from "../../../components/AppHeader/AppHeader";
import {AppHeaderBeliz} from "../../../components/AppHeaderBeliz/AppHeaderBeliz";

const DEFAULT_FILE_NAME = 'Прикрепите файл';

const iconButtonSX = {
    color: "text.secondary",
    "&:hover": {
        color: "error.main",
    },
};

const extractShortFileName = (longFileName : string) => {
    let shortName: string | undefined = longFileName;
    if (longFileName.indexOf('\\') !== -1) {
        shortName = longFileName.split('\\').pop();
    }
    if (longFileName.indexOf('/') !== -1) {
        shortName = longFileName.split('/').pop();
    }
    return shortName??'';
}

export const BelizSelectFile =  ({
    error,
    required
}: {
    error: boolean,
    required: boolean
}) => {
    const { t} = useTranslation('belizForm');
    const [selectedFileLabel, setSelectedFileLabel] = useState('');
    const [fileName, setFileName] = useState(DEFAULT_FILE_NAME);
    const [fieldError, setFieldError] = useState(error);
    const [fileIsTooBig, setFileIsTooBig] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [attachedFilesNames, setAttachedFilesNames] = useState<{ [key: string]: string }>({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (error && required) {
            setFieldError(true);
        }
    }, [error, required]);

    useEffect(() => {
        const getFilesData = async () => {
            try {
                const filesData = await getAllDocumentNamesFromDb() as { [key: string]: string };
                setAttachedFilesNames(filesData);
                dispatch({
                    type: STORE_APPLICANT_FILES,
                    payload: {
                        applicantFiles: Object.keys(filesData)
                    }
                })
                dispatch({
                    type: STORE_FILES_NAMES,
                    payload: {
                        filesNames: Object.keys(filesData),
                    },
                });
            } catch (err) {
                console.log('Error occurred when fetching documents');
            }
        }
        getFilesData()
            .catch(console.error);
    }, [])

    const handleChange = (e: SelectChangeEvent<string>) => {
        setSelectedFileLabel(e.target.value);
        setFileName(DEFAULT_FILE_NAME);
    }

    const exampleFiles: {
        [key: string]: string,
    } = {
        en: 'https://ffin.bz//upload/docs/Examples_of_SOF.pdf',
        ru: 'https://ffin.bz/upload/docs/Examples_of_SOF_на_русском__1_.pdf',
    };
    const directLinkLanguage: string = getLanguageFromURL() || 'ru';
    const exampleSOFFile: string = exampleFiles[directLinkLanguage];

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    return (
      <FieldContainer id="filesWasAttached">
        <FormControl sx={{ gap: { xs: "8px", md: "24px" } }}>
          <FormLabel required={required}>
            {t("step2.incomeConfirmation")}
          </FormLabel>
          <Typography variant="caption">
            {t("step2.attachedFileExamples")}
            <Link href={exampleSOFFile} target="_blank" underline="none">
              {t("step2.exampleLink")}
            </Link>
          </Typography>
          <Typography variant="caption">
            {t("step2.attachedFileSize")}
          </Typography>
          <Select
            displayEmpty
            value={selectedFileLabel}
            onChange={handleChange}
            size="small"
          >
            <MenuItem key="menu_choose" disabled value="">
              <Typography variant="body1" color="text.secondary">
                {t("step2.selectDocumentType")}
              </Typography>
            </MenuItem>
            {filesOptions.map((option) => {
              return (
                <MenuItem key={`menu_${option.name}`} value={option.name}>
                  {/*{option.text}*/}
                  {t(`step2.${option.name}`)}
                </MenuItem>
              );
            })}
          </Select>
          {filesOptions.map((option) => {
            return (
              <Box
                key={`input_box_${option.name}`}
                sx={{
                  display: option.name !== selectedFileLabel ? "none" : "flex",
                }}
              >
                <input
                  key={`input_${option.name}`}
                  id={option.name}
                  hidden
                  accept="image/png, image/jpeg, application/pdf"
                  type="file"
                  onChange={(e) => {
                    if (e?.target?.files?.[0]) {
                      const maxAllowedSize = 8 * 1024 * 1024;
                      const selectedFile = e?.target?.files[0];
                      if (selectedFile?.size > maxAllowedSize) {
                        e.target.value = "";
                        setFileIsTooBig(true);
                        return;
                      }
                      if (!allowedTypes.includes(selectedFile?.type)) {
                        e.target.value = "";
                        setInvalidFileType(true);
                        return;
                      }
                    }
                    const fileName: string = extractShortFileName(
                      e?.target?.value
                    );
                    setFileName(fileName);
                    const newFile: { [key: string]: string } = {
                      [option.name]: fileName,
                    };

                    let file = e?.target?.files?.[0];
                    putDocumentInDb(file, option.name ?? "");
                    setFieldError(false);
                    setFileIsTooBig(false);
                    setInvalidFileType(false);
                    setAttachedFilesNames((prevState) => ({
                      ...prevState,
                      ...newFile,
                    }));
                    dispatch({
                      type: STORE_FILE_NAME,
                      payload: {
                        fileName: option.name,
                      },
                    });
                    dispatch({
                      type: REMOVE_ERROR_FIELD,
                      payload: {
                        errorField: "filesWasAttached",
                      },
                    });
                  }}
                />
                <label
                  // hidden={option.name !== selectedFileLabel}
                  style={{ cursor: "pointer" }}
                  htmlFor={option.name}
                >
                  <IconButton color="primary" component="span">
                    <AttachFile fontSize="large" />
                  </IconButton>
                  {/*{fileName}*/}
                  {/*{DEFAULT_FILE_NAME}*/}
                  {t("step2.attachFile")}
                </label>
              </Box>
            );
          })}
          {!!Object.keys(attachedFilesNames)?.length && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              <Typography variant="subtitle1" fontSize={18}>
                {t("step2.attachedFiles", {
                  count: Object.keys(attachedFilesNames)?.length,
                })}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {Object.entries(attachedFilesNames).map(([key, fileName]) => (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "14px",
                    }}
                  >
                    <Typography variant="body2">- {fileName}</Typography>
                    <IconButton
                      sx={iconButtonSX}
                      component="span"
                      onClick={() => {
                        deleteDocumentFromDb(key);
                        setAttachedFilesNames((current) => {
                          const copy = { ...current };
                          delete copy[key];
                          return copy;
                        });
                        dispatch({
                          type: REMOVE_FILE_NAME,
                          payload: {
                            fileName: key,
                          },
                        });
                      }}
                    >
                      <ClearIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                ))}
              </div>
            </Box>
          )}
          {fieldError && !Object.keys(attachedFilesNames)?.length && (
            <Typography variant="body2" color="error.main">
              {t("step2.attachAtLeastOneFile")}
            </Typography>
          )}
          {fileIsTooBig && (
            <Typography variant="body2" color="error.main">
              {t("step2.fileIsTooLarge")}
            </Typography>
          )}
          {invalidFileType && (
            <Typography variant="body2" color="error.main">
              {t("step2.invalidFileType")}
            </Typography>
          )}
        </FormControl>
      </FieldContainer>
    );
}
