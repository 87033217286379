import { Box, styled } from "@mui/material"
import './MainZaForm.css'
import { ZaConfirmationForm } from "./ZaConfirmationForm";
import { ZaConfirmDocuments } from "./ZaConfirmDocuments";
import { ZaMainContactData } from "./ZaMainContactData";
import { ZaSumSubForm } from "./ZaSumSubForm";
import { ZaTaxResidency } from "./ZaTaxResidency";
import { Logout } from "../../../components/Logout/Logout";
import NeedHelpButton from "../../../components/NeedHelpButton/NeedHelpButton";
import RetryLaterDialog from "../../../components/Dialogs/RetryLater";

const ZaFormMenuButtons = styled(Box)`
    position: static;
    @media (min-width:640px) {
        display: flex;
        align-items: center;
        gap: 12px;
        position: absolute;
        top: 20px;
        right: 24px;
    }  
`

export const MainZaForm = ({ activeStep }: { activeStep: number }) => {    
    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        {activeStep === 1 && <ZaMainContactData />}
        {activeStep === 2 && <ZaTaxResidency />}
        {activeStep === 3 && <ZaConfirmDocuments />}
        {activeStep === 4 && <ZaConfirmationForm />}
        {activeStep > 4 && <ZaSumSubForm />}
        <ZaFormMenuButtons>
            <NeedHelpButton />            
            <Logout style={{ display: {xs: 'none', sm: 'flex'} }} />            
        </ZaFormMenuButtons>
        <RetryLaterDialog/>
    </Box>)
}
