import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { DECREASE_CURRENT_PAGE, DECREASE_PROVIDER_STEP, INCREASE_CURRENT_PAGE, INCREASE_PROVIDER_STEP, MOVE_TO_NEXT_PAGE, MOVE_TO_NEXT_STEP, MOVE_TO_PAGE, MOVE_TO_PREV_PAGE, MOVE_TO_PREV_STEP, MOVE_TO_STEP, SET_CURRENT_PAGE, SET_PROVIDER, SET_PROVIDER_STEP, TCurrentPositionAction, TProviderType } from "../redux/currentPosition/currentPositionTypes";
import { TApplicationState } from "../redux/rootReducer";
import { STORE_DATA_ON_SERVER, STORE_FORM_DATA, TStoreFormDataAction } from "../redux/userData/userDataTypes";
import { getProviderFromURL, sendFieldToServer } from "../utils/common";
import { fetchData } from "../utils/fetch";
import { SET_REGISTERED } from "../redux/mainContactData/mainContactDataTypes";
import { GET_SUMSUB_AM_AUTH,GET_SUMSUB_ZA_AUTH,GET_SUMSUB_RU_AUTH ,SET_STORED_ANKETAS, TStoredAnketa, TStoredAnketaField } from "../redux/storedAnketas/storedAnketasTypes";
import { CANCEL_FILLING_ANKETA, CONTINUE_FILLING_ANKETA, GET_ANKETAS_DATA, SET_POPUP_CLOSED, SET_POPUP_OPEN, TGetAnketasDataAction } from "../redux/continueAnketaPopup/continueAnketaPopupTypes";
import { getProvider } from "../redux/currentPosition/currentPositionSelector";
import { SET_SUMSUB_TOKEN, SET_SUMSUB_STATUS, SET_SUMSUB_BZ_TOKEN } from "../redux/sumSubData/sumSubDataTypes";

function* restoreStateSaga(): Generator {
    const isExistsAnketa = sessionStorage.getItem('isExistsAnketa');
    const provider = yield select(getProvider);
    
    if (isExistsAnketa) {
        yield put({
            type: GET_ANKETAS_DATA,
            payload: {
                provider,
                needAConfirmWindow: true,
            },
        });
    }
}

const saveAnketasFieldReq = (data: {
    step?: number,
    formName: string,
    fieldName?: string,
    fieldValue?: string
}) => {
    let urlPostfix = '';
    if ((data.step === 0) || !sessionStorage.getItem('token')) return;

    switch (data.formName) {
        case 'beliz':
            urlPostfix = 'BZ'
            break;
        case 'armenia':
            urlPostfix = 'AM'
            break;
        case 'za':
            urlPostfix = 'ZA'
            break;
        case 'ru':
            urlPostfix = 'RU'
            break;            
        default:
            return;
            // urlPostfix = 'common'
            // break;
    }
    const fields = data.fieldName ? { [data.fieldName]: data.fieldValue } : {};
    const params = {
        fields: fields,
        ...(data.step !== undefined ? { step: data.step } : {})
    };

    // if (Object.keys(fields).length === 0) {
    //     return;
    // }

    return fetchData({
        'url': `client/anketas/${urlPostfix}`,
        'method': 'PATCH',
        'params': params
    })
}


function* sendUserDataToServerSaga(data: TStoreFormDataAction) {
    try {
        let step: number | undefined;

        if (!data.payload.skipStep) {
            step = yield select((s: TApplicationState) => s.usersAnketa.currentPosition.providerStep);
        }
        
        if (data?.payload) {
            yield call(saveAnketasFieldReq, {
                step,
                formName: data.payload.formName,
                fieldName: data.payload.fieldName,
                fieldValue: data.payload.fieldValue,
            });
            yield put({
                type: STORE_DATA_ON_SERVER,
                payload: {
                    formName: data.payload.formName,
                    fieldName: data.payload.fieldName
                }
            });
        }
    } catch (e) {
    }
}

function* keepFormSaga(action: TCurrentPositionAction) {
    const fields = document.querySelectorAll('input');
    const step: number = yield select((s: TApplicationState) => s.usersAnketa.currentPosition.providerStep);
    const provider: string = yield select((s: TApplicationState) => s.usersAnketa.currentPosition.provider);

    yield all(Array.from(fields).map((item: HTMLInputElement) => {
        const data = sendFieldToServer(item as Element);
        if (!data) {
            saveAnketasFieldReq({
                step,
                formName: provider
            })
        }
        return put({
            type: STORE_FORM_DATA,
            payload: data
        })
    }));

    if (!fields || !fields.length) {
        saveAnketasFieldReq({
            step,
            formName: provider
        })
    }

    yield put({
        type: SET_POPUP_CLOSED
    })

    let actionType;
    switch (action.type) {
        case INCREASE_CURRENT_PAGE:
            actionType = MOVE_TO_NEXT_PAGE;
            break;
        case DECREASE_CURRENT_PAGE:
            actionType = MOVE_TO_PREV_PAGE;
            break;
        case SET_CURRENT_PAGE:
            actionType = MOVE_TO_PAGE;
            break;
        case INCREASE_PROVIDER_STEP:
            actionType = MOVE_TO_NEXT_STEP;
            break;
        case DECREASE_PROVIDER_STEP:
            actionType = MOVE_TO_PREV_STEP;
            break;
        case SET_PROVIDER_STEP:
            actionType = MOVE_TO_STEP;
            break;
        default:
            actionType = '';
    }
    if (actionType) {
        yield put({
            type: actionType,
            payload: (action.type === SET_CURRENT_PAGE || action.type === SET_PROVIDER_STEP) ? action.payload : undefined
        })
    }


}

function* setAnketaPosition(provider: TProviderType, step: number){
    yield put({
        type: SET_PROVIDER,
        payload: {
            provider
        }
    });

    yield put({
        type: SET_CURRENT_PAGE,
        payload: {
            page: 2
        }
    });

    yield put({
        type: SET_REGISTERED
    });

    if (step) {
        yield put({
            type: SET_PROVIDER_STEP,
            payload: {
                step
            }
        })
    }
}

function* getSumSubDataForArmenia (){
    let hasSumSubStatus = false;
    const startedSumSubStatus: {
        data: any,
        success: boolean
    } = yield call(fetchData, {
        'url': 'client/anketas/started-sumsub-process/AM',
        method: 'GET',
        params: {}
    }
    );

    if (startedSumSubStatus.success && startedSumSubStatus.data.data.registered){
        const loginData: {
            data: {
                SID: string
            },
            success: boolean
        } = yield call(fetchData, {
            'url': 'client/anketas/authentificate-sumsub/AM',
            method: 'POST',
            params: {}
        }
        );
        if (loginData.success && loginData.data.SID){
            const resForSumSubStatus: {
                success: boolean,
                data: {
                    message: string,
                    pending: boolean,
                    status: string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-status',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForSumSubStatus?.success && resForSumSubStatus?.data?.status) {
                yield put({
                    type: SET_SUMSUB_STATUS,
                    payload: {
                        status: resForSumSubStatus?.data?.status,
                        provider: TProviderType.armenia
                    }
                })
            }
            const resForToken: {
                success: boolean,
                data: {
                    token : string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-iframe-token',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForToken?.success) {
                yield put({
                    type: SET_SUMSUB_TOKEN,
                    payload: {
                        token: resForToken?.data?.token
                    }
                })
            }
            hasSumSubStatus = true;
        }

    }
    return hasSumSubStatus;
}

function* getSumSubDataForZa (){
    let hasSumSubStatus = false;
    const startedSumSubStatus: {
        data: any,
        success: boolean
    } = yield call(fetchData, {
        'url': 'client/anketas/started-sumsub-process/ZA',
        method: 'GET',
        params: {}
    }
    );

    if (startedSumSubStatus.success && startedSumSubStatus?.data?.data?.registered){
        const loginData: {
            data: {
                SID: string
            },
            success: boolean
        } = yield call(fetchData, {
            'url': 'client/anketas/authentificate-sumsub/ZA',
            method: 'POST',
            params: {}
        }
        );
        if (loginData.success && loginData.data.SID){
            const resForSumSubStatus: {
                success: boolean,
                data: {
                    message: string,
                    pending: boolean,
                    status: string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-status',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForSumSubStatus?.success && resForSumSubStatus?.data?.status) {
                yield put({
                    type: SET_SUMSUB_STATUS,
                    payload: {
                        status: resForSumSubStatus?.data?.status,
                        provider: TProviderType.za
                    }
                })
            }
            const resForToken: {
                success: boolean,
                data: {
                    token : string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-iframe-token',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForToken?.success) {
                yield put({
                    type: SET_SUMSUB_TOKEN,
                    payload: {
                        token: resForToken?.data?.token
                    }
                })
            }
            hasSumSubStatus = true;
        }

    }
    return hasSumSubStatus;
}

function* getSumSubDataForRu (){
    let hasSumSubStatus = false;
    const startedSumSubStatus: {
        data: any,
        success: boolean
    } = yield call(fetchData, {
        'url': 'client/anketas/started-sumsub-process/RU',
        method: 'GET',
        params: {}
    }
    );

    if (startedSumSubStatus.success && startedSumSubStatus?.data?.data?.registered){
        const loginData: {
            data: {
                SID: string
            },
            success: boolean
        } = yield call(fetchData, {
            'url': 'client/anketas/authentificate-sumsub/RU',
            method: 'POST',
            params: {}
        }
        );
        if (loginData.success && loginData.data.SID){
            const resForSumSubStatus: {
                success: boolean,
                data: {
                    message: string,
                    pending: boolean,
                    status: string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-status',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForSumSubStatus?.success && resForSumSubStatus?.data?.status) {
                yield put({
                    type: SET_SUMSUB_STATUS,
                    payload: {
                        status: resForSumSubStatus?.data?.status,
                        provider: TProviderType.ru
                    }
                })
            }
            const resForToken: {
                success: boolean,
                data: {
                    token : string
                }
            } = yield call (fetchData, {
                'url': 'client/anketas/tn/get-sumsub-iframe-token',
                method: 'POST',
                params: {
                    sid : loginData.data.SID
                }
            })
            if (resForToken?.success) {
                yield put({
                    type: SET_SUMSUB_TOKEN,
                    payload: {
                        token: resForToken?.data?.token
                    }
                })
            }
            hasSumSubStatus = true;
        }

    }
    return hasSumSubStatus;
}

function* getSumSubDataForBeliz(){
    let hasSumSubStatus = false;
    const startedSumSubStatus: {
        data: any,
        success: boolean
    } = yield call(fetchData, {
        'url': 'client/anketas/started-sumsub-process/BZ',
        method: 'GET',
        params: {}
    }
    );
    if (startedSumSubStatus.success && startedSumSubStatus.data.data.registered){
        const resForSumSubStatus: {
            success: boolean,
            data: {
                message: string,
                pending: boolean,
                status: string
            }
        } = yield call (fetchData, {
            'url': 'client/anketas/bz/sumsub-status',
            method: 'GET',
            params: {}
        })
        if (resForSumSubStatus?.success && resForSumSubStatus?.data?.status){
            yield put({
                type: SET_SUMSUB_STATUS,
                payload: {
                    status: resForSumSubStatus?.data?.status,
                    provider: TProviderType.beliz
                }
            })
        }
        const resForToken: {
            success: boolean,
            data: {
                token : string
            }
        } = yield call (fetchData, {
            'url': 'client/anketas/bz/sumsub-iframe-token',
            method: 'GET',
            params: {}
        })
        if (resForToken?.success) {
            hasSumSubStatus = true;
            yield put({
                type: SET_SUMSUB_BZ_TOKEN,
                payload: {
                    token: resForToken?.data?.token
                }
            })
        }
    }
    return hasSumSubStatus;
}

const getShortProviderName = (provider: TProviderType) =>{
    let shortProviderName: string;
    switch (provider) {
        case TProviderType.armenia:
            shortProviderName = 'AM';
            break;
        case TProviderType.beliz:
            shortProviderName = 'BZ';
            break;
        case TProviderType.za:
            shortProviderName = 'ZA';
            break;
        case TProviderType.ru:
            shortProviderName = 'RU';
            break;                          
        default:
            shortProviderName = 'CMN';
            break;
    }
    return shortProviderName;
}

const getValidAnketaStep = (anketas : TStoredAnketa[], provider : TProviderType) => {
    const shortProviderName = getShortProviderName(provider);
    const anketa = anketas.find(anketa => anketa.type === shortProviderName);

    let step = anketa?.step ?? 1;

    switch (provider){
        case TProviderType.armenia:
        case TProviderType.za:
        case TProviderType.ru:

            if (!anketa?.data['country'] ){
                step = 2;
            }
            if (!anketa?.data['phone'] || !anketa?.data['email'] || !anketa?.data['lastName'] || !anketa?.data['name']){
                step = 1;
            }
            break;
        default:
            break;
    }
    
    return step;
}



function* getExistingAnketasSaga() {

    const action: TGetAnketasDataAction = yield take(
        GET_ANKETAS_DATA
    )
    let anketas: TStoredAnketa[] = [];
    const { provider, needAConfirmWindow } = action.payload;
    let step = 1;
    let continueFillingAnketaProcess = {
        continueAnketaFilling: true,
        cancelAnketaFilling: false
    }

    const shortProviderName = getShortProviderName(provider);

    const existingAnketasResult: {
        data: {
            anketasData: TStoredAnketaField,
            step: number,
            name: string
        },
        success: boolean
    } = yield call(fetchData, {
        'url': `client/anketas/${shortProviderName}`,
        method: 'GET',
        params: {}
    }
    );

    if (existingAnketasResult?.success) {
        const anketasData = existingAnketasResult?.data?.anketasData;
        step = existingAnketasResult?.data?.step ?? 1;

        anketas = [{
            data: anketasData,
            type: shortProviderName,
            step
        }];

        let  hasSumSubStatus: boolean = false;
        if (provider === TProviderType.armenia){
            hasSumSubStatus = yield call(getSumSubDataForArmenia);
        } else if (provider === TProviderType.za) {
            hasSumSubStatus = yield call(getSumSubDataForZa);
        }
        else if (provider === TProviderType.ru) {
            hasSumSubStatus = yield call(getSumSubDataForRu);
        }
         else if (provider === TProviderType.beliz) {
            hasSumSubStatus = yield call(getSumSubDataForBeliz);
        }

        if (hasSumSubStatus) {
          yield call(setAnketaPosition, provider, 5);
          return;
        }

        if (anketasData && Object.keys(anketasData).length !== 0) {

            if (needAConfirmWindow && !hasSumSubStatus) {

              yield put({
                type: SET_POPUP_OPEN,
                payload: {
                  provider,
                  name: existingAnketasResult?.data?.name ?? "",
                },
              });

              continueFillingAnketaProcess = yield race({
                continueAnketaFilling: take(CONTINUE_FILLING_ANKETA),
                cancelAnketaFilling: take(CANCEL_FILLING_ANKETA),
              });
            }

            const { continueAnketaFilling, cancelAnketaFilling } = continueFillingAnketaProcess;

            if (cancelAnketaFilling) {
                anketas = [];
            }

            if (continueAnketaFilling) {
                if (hasSumSubStatus){
                    yield call(setAnketaPosition, provider, 5);
                    return;
                }

                step = getValidAnketaStep(anketas, provider);
            } else {
                step = 1;
            }
        } else {
            anketas = [];
            step = 1;
            yield put({
                type: CANCEL_FILLING_ANKETA
            })
        }
    } else {
        anketas = [];
        step = 1;
        yield put({
            type: CANCEL_FILLING_ANKETA
        })
    }

    yield put({
        type: SET_STORED_ANKETAS,
        payload: {
            anketas
        }
    })
    yield call (setAnketaPosition, provider, step);
    
    if (needAConfirmWindow) {
        yield put({
            type: SET_POPUP_CLOSED
        })
    }
}

function* keepUserDataSaga() {
  yield all([
    takeLatest(STORE_FORM_DATA, sendUserDataToServerSaga),
    call(getExistingAnketasSaga),
    takeLatest(GET_SUMSUB_AM_AUTH, getSumSubDataForArmenia),
    takeLatest(GET_SUMSUB_ZA_AUTH, getSumSubDataForZa),
    takeLatest(GET_SUMSUB_RU_AUTH, getSumSubDataForRu),
    takeEvery(
      [
        INCREASE_CURRENT_PAGE,
        DECREASE_CURRENT_PAGE,
        SET_CURRENT_PAGE,
        INCREASE_PROVIDER_STEP,
        DECREASE_PROVIDER_STEP,
        SET_PROVIDER_STEP,
      ],
      keepFormSaga
    ),
    call(restoreStateSaga),
  ]);
}

export default keepUserDataSaga;
