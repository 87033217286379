import React from 'react';
import './AppFooterBeliz.css'
import {Steps} from "../StepsCounter/StepsCounter";
import {useSelector} from "react-redux";
import {getProviderStep} from "../../redux/currentPosition/currentPositionSelector";

export const AppFooterBeliz = () => {
  const activeIndex = useSelector(getProviderStep);
  return (
    <div className="container-footer">
      <div className="footer">
        <Steps activeIndex={activeIndex}/>
      </div>
    </div>
  );
};
