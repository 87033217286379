import { TApplicationState } from "../rootReducer";

export const getContinueAnketaPopupIsOpen = (state: TApplicationState) =>{
    return state.usersAnketa.continueAnketaPopup.popupIsOpen;
}

export const getContinueAnketaProvider = (state: TApplicationState) =>{
    return state.usersAnketa.continueAnketaPopup.provider;
}

export const isNewAnketaSelector = (state: TApplicationState) =>{
    return state.usersAnketa.continueAnketaPopup.newAnketa;
}

export const getContinueAnketaUserName = (state: TApplicationState) =>{
    return state.usersAnketa.continueAnketaPopup.userName;
}