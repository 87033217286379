import {Button, ButtonProps} from "@mui/material"

type TButtonProps = ButtonProps & {
    text?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disabled?: boolean,
    btnWidth?: number | string | {[index: string]: string},
    minWidth?: number | string,
    maxWidth?: number | string,
    btnHeight?: number | string,
    btnMargin?: number | string | {[index: string]: string},
    btnPadding?: number | string,
    color?: string,
    btnColor?: string,
    btnBackgroundColor?: string,
    fontSize?: number | string,
    fontWeight?: number | string,
    children?: React.ReactNode
}


export const ButtonComponent = ({
                                    onClick,
                                    text,
                                    size,
                                    fontSize,
                                    fontWeight,
                                    disabled = false,
                                    // btnWidth = .2,
                                    btnWidth,
                                    btnHeight = '52px',
                                    minWidth = '120px',
                                    maxWidth,
                                    btnMargin = 3,
                                    btnPadding,
                                    variant = 'contained',
                                    color = 'primary',
                                    btnColor,
                                    btnBackgroundColor,
                                    id,
                                    href,
                                    children
                                }: TButtonProps) => {
    return (<Button sx={
        {
            width: btnWidth,
            minWidth: minWidth,
            maxWidth: maxWidth,
            height: btnHeight,
            margin: btnMargin,
            fontSize: fontSize,
            fontWeight: fontWeight,
            padding: btnPadding,
            color: btnColor,
            backgroundColor: btnBackgroundColor
        }
    }
                    variant={variant}
                    size={size}
                    color={color}
                    onClick={onClick}
                    disabled={disabled}
                    id={id}
                    href={href}
    >
        {text}
        {children}
    </Button>)
}
