import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Link, useTheme, Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState} from "react"
import {ButtonComponent} from "../../../components/Buttons/ButtonComponent"
import {PopUpFieldContainer, PopUpStyledTextField} from "../../../components/FieldContainer/FieldContainer"
import {TimerComponent} from "../../../components/Timer/Timer"
import {fetchData} from "../../../utils/fetch"
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTranslation} from "react-i18next";


let myInterval: string | number | NodeJS.Timeout | undefined;


const requestCode = (email: string) => {
    fetchData({
        url: 'client/ownership/email/send',
        method: 'POST',
        params: {
            email
        }
    })
}

export const sxDialogTextField = {
    MuiInputBase: {
        styleOverrides: {
            root: {
                maxHeight: 64,
                '@media (min-width:981px)': {
                    maxHeight: 64,
                },
                "&.MuiOutlinedInput": {
                    maxHeight: 64,
                },
            }
        }
    }
}

export const ConfirmEmailDialog = ({open, handleClose, email, setEmailCode}: {
    open: boolean,
    handleClose: () => void,
    email: string,
    setEmailCode: (code: string) => void
}) => {
    const [code, setCode] = useState('');
    const [errorText, setErrorText] = useState('');
    const [timerExpired, setTimerExpired] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation(['belizForm', 'translation']);

    useEffect(() => {
        if (open) {
            requestCode(email);
        }
    }, [open]);

    const timerHasExpired = () => {
        setTimerExpired(true);
    }

    return <Dialog
        fullScreen={fullScreen}
        onClose={() => {
            setCode('');
            setErrorText('');
            setTimerExpired(false);
            handleClose()
        }}
        open={open}>
        <IconButton
            aria-label="close"
            onClick={() => {
                setCode('');
                setErrorText('');
                setTimerExpired(false);
                handleClose()
            }}
            sx={{
                width: '24px',
                height: '24px',
                position: 'absolute',
                right: {xs: 20, sm: 24},
                top: {xs: 20, sm: 24},
                color: (theme) => theme.palette.text.secondary,
            }}
        >
            <CloseIcon/>
        </IconButton>
        <DialogTitle>{t('confirmation.emailTitle')}</DialogTitle>
        <DialogContent>
            <DialogContentText color='#2A3E5C' id="alert-dialog-thanks">
                {t('confirmation.thanksForReg')}
            </DialogContentText>
            <br/>
            <DialogContentText color='#2A3E5C' id="alert-dialog-description">
                {t('confirmation.emailDescription', {email})}
            </DialogContentText>
            <PopUpFieldContainer>
                <PopUpStyledTextField
                    label=""
                    type="text"
                    value={code}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCode(event.target.value);
                    }}
                />
                {errorText &&
                  <Typography variant="caption" color='error.main'>
                      {errorText}
                  </Typography>
                }
                <Box color="#AEBECC">
                    {timerExpired
                        ? <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '4px 32px'}}>
                            <Typography variant="caption">{t('confirmation.timedOut')}</Typography>
                            <Link
                                component="button"
                                variant="caption"
                                fontWeight={{sm: 500}}
                                underline="none"
                                onClick={() => {
                                    requestCode(email);
                                    setTimerExpired(false);
                                    setCode('');
                                    setErrorText('');
                                }}
                            >
                                {t('confirmation.sendCodeAgain')}
                            </Link>
                        </Box>
                        :
                        <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                            <Typography variant="caption">
                                {t('confirmation.timerText')}
                            </Typography>
                            <TimerComponent
                                variant="caption"
                                setExpired={timerHasExpired}
                                initialMinute={3}
                                initialSeconds={0}
                                intervalId={myInterval}
                            />
                        </Box>
                    }
                </Box>
            </PopUpFieldContainer>
        </DialogContent>
        <ButtonComponent text={t('buttons.confirm', {ns: 'translation'})} minWidth={190} btnWidth={{sm: 'fit-content'}} btnMargin={0} onClick={() => {
            fetchData({
                url: 'client/ownership/email/verify',
                method: 'POST',
                params: {
                    email,
                    code
                }
            }).then((response) => {
                if (response?.success) {
                    setEmailCode(code);
                    setErrorText('');
                    handleClose();
                } else {
                    if (response?.data?.errorMessage) {
                        setErrorText(t('confirmation.wrongEmailCode', {email}))
                    }
                }
            })
        }} disabled={!code || timerExpired}/>
    </Dialog>
}
