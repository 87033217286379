import { call, put, takeLatest } from "redux-saga/effects";
import { fetchData } from "../utils/fetch";
import { GET_COUNTRIES_LIST, SET_COUNTRIES_LIST } from "../redux/countriesList/countriesListTypes";

function* fetchCountriesList(){
    const countries : ReturnType<typeof fetchData> = yield call(fetchData, {
        url: `countries/bz/get-countries-list`,
        method: 'GET',
        params: {}
    });
    yield put({
        type: SET_COUNTRIES_LIST,
        payload: {
            countries: countries??[]
        }
    })

}

function* getCountriesListSaga() {
    yield takeLatest(GET_COUNTRIES_LIST, fetchCountriesList);
}

export default getCountriesListSaga;