export const countryList = [{
    "key": "CY",
    "value": "Cyprus"
}, {
    "key": "RU",
    "value": "Russia"
}, {
    "key": "UA",
    "value": "Ukraine"
}, {
    "key": "BY",
    "value": "Belarus"
}, {
    "key": "KZ",
    "value": "Kazakhstan"
}, {
    "key": "UZ",
    "value": "Uzbekistan"
}, {
    "key": "AB",
    "value": "Abkhazia"
}, {
    "key": "AD",
    "value": "Andorra"
}, {
    "key": "AI",
    "value": "Anguilla"
}, {
    "key": "AG",
    "value": "Antigua and Barbuda"
}, {
    "key": "AR",
    "value": "Argentina"
}, {
    "key": "AM",
    "value": "Armenia"
}, {
    "key": "AW",
    "value": "Aruba"
}, {
    "key": "AU",
    "value": "Australia"
}, {
    "key": "AT",
    "value": "Austria"
}, {
    "key": "AZ",
    "value": "Azerbaijan"
}, {
    "key": "BS",
    "value": "Bahamas"
}, {
    "key": "BH",
    "value": "Bahrain"
}, {
    "key": "BE",
    "value": "Belgium"
}, {
    "key": "BZ",
    "value": "Belize"
}, {
    "key": "BM",
    "value": "Bermuda"
}, {
    "key": "BA",
    "value": "Bosnia and Herzegovina"
}, {
    "key": "BR",
    "value": "Brazil"
}, {
    "key": "VG",
    "value": "British Virgin Islands"
}, {
    "key": "BN",
    "value": "Brunei Darussalam"
}, {
    "key": "BG",
    "value": "Bulgaria"
}, {
    "key": "KH",
    "value": "Cambodia"
}, {
    "key": "CM",
    "value": "Cameroon"
}, {
    "key": "CA",
    "value": "Canada"
}, {
    "key": "KY",
    "value": "Cayman Islands"
}, {
    "key": "CL",
    "value": "Chile"
}, {
    "key": "CN",
    "value": "China"
}, {
    "key": "CO",
    "value": "Colombia"
}, {
    "key": "CR",
    "value": "Costa Rica"
}, {
    "key": "HR",
    "value": "Croatia"
}, {
    "key": "CZ",
    "value": "Czech Republic"
}, {
    "key": "DK",
    "value": "Denmark"
}, {
    "key": "EC",
    "value": "Ecuador"
}, {
    "key": "EG",
    "value": "Egypt"
}, {
    "key": "EE",
    "value": "Estonia"
}, {
    "key": "FI",
    "value": "Finland"
}, {
    "key": "FR",
    "value": "France"
}, {
    "key": "GE",
    "value": "Georgia"
}, {
    "key": "DE",
    "value": "Germany"
}, {
    "key": "GI",
    "value": "Gibraltar"
}, {
    "key": "GR",
    "value": "Greece"
}, {
    "key": "GG",
    "value": "Guernsey"
}, {
    "key": "HK",
    "value": "Hong Kong"
}, {
    "key": "HU",
    "value": "Hungary"
}, {
    "key": "IS",
    "value": "Iceland"
}, {
    "key": "IN",
    "value": "India"
}, {
    "key": "ID",
    "value": "Indonesia"
}, {
    "key": "IE",
    "value": "Ireland"
}, {
    "key": "IM",
    "value": "Isle Of Man"
}, {
    "key": "IL",
    "value": "Israel"
}, {
    "key": "IT",
    "value": "Italy"
}, {
    "key": "JP",
    "value": "Japan"
}, {
    "key": "JE",
    "value": "Jersey"
}, {
    "key": "JO",
    "value": "Jordan"
}, {
    "key": "KW",
    "value": "Kuwait"
}, {
    "key": "KG",
    "value": "Kyrgyzstan"
}, {
    "key": "LV",
    "value": "Latvia"
}, {
    "key": "LB",
    "value": "Lebanon"
}, {
    "key": "LI",
    "value": "Liechtenstein"
}, {
    "key": "LT",
    "value": "Lithuania"
}, {
    "key": "LU",
    "value": "Luxembourg"
}, {
    "key": "MK",
    "value": "Macedonia (FYROM)"
}, {
    "key": "MY",
    "value": "Malaysia"
}, {
    "key": "MV",
    "value": "Maldives"
}, {
    "key": "MT",
    "value": "Malta"
}, {
    "key": "MH",
    "value": "Marshall Islands"
}, {
    "key": "MU",
    "value": "Mauritius"
}, {
    "key": "MX",
    "value": "Mexico"
}, {
    "key": "MN",
    "value": "Mongolia"
}, {
    "key": "ME",
    "value": "Montenegro"
}, {
    "key": "MA",
    "value": "Morocco"
}, {
    "key": "NP",
    "value": "Nepal"
}, {
    "key": "NL",
    "value": "Netherlands"
}, {
    "key": "NZ",
    "value": "New Zealand"
}, {
    "key": "NG",
    "value": "Nigeria"
}, {
    "key": "NO",
    "value": "Norway"
}, {
    "key": "OM",
    "value": "Oman"
}, {
    "key": "PA",
    "value": "Panama"
}, {
    "key": "PY",
    "value": "Paraguay"
}, {
    "key": "PE",
    "value": "Peru"
}, {
    "key": "PH",
    "value": "Philippines"
}, {
    "key": "PL",
    "value": "Poland"
}, {
    "key": "PT",
    "value": "Portugal"
}, {
    "key": "QA",
    "value": "Qatar"
}, {
    "key": "MD",
    "value": "Republic of Moldova"
}, {
    "key": "RO",
    "value": "Romania"
}, {
    "key": "RW",
    "value": "Rwanda"
}, {
    "key": "KN",
    "value": "Saint Kitts and Nevis"
}, {
    "key": "VC",
    "value": "Saint Vincent and the Grenadines"
}, {
    "key": "SA",
    "value": "Saudi Arabia"
}, {
    "key": "RS",
    "value": "Serbia"
}, {
    "key": "SC",
    "value": "Seychelles"
}, {
    "key": "SG",
    "value": "Singapore"
}, {
    "key": "SK",
    "value": "Slovakia"
}, {
    "key": "SI",
    "value": "Slovenia"
}, {
    "key": "ZA",
    "value": "South Africa"
}, {
    "key": "KR",
    "value": "South Korea"
}, {
    "key": "ES",
    "value": "Spain"
}, {
    "key": "SE",
    "value": "Sweden"
}, {
    "key": "CH",
    "value": "Switzerland"
}, {
    "key": "SY",
    "value": "Syria"
}, {
    "key": "TW",
    "value": "Taiwan (China)"
}, {
    "key": "TJ",
    "value": "Tajikistan"
}, {
    "key": "TH",
    "value": "Thailand"
}, {
    "key": "TR",
    "value": "Turkey"
}, {
    "key": "TM",
    "value": "Turkmenistan"
}, {
    "key": "TC",
    "value": "Turks and Caicos Islands"
}, {
    "key": "AE",
    "value": "United Arab Emirates"
}, {
    "key": "GB",
    "value": "United Kingdom"
}, {
    "key": "US",
    "value": "United States"
}, {
    "key": "UY",
    "value": "Uruguay"
}, {
    "key": "VE",
    "value": "Venezuela"
}, {
    "key": "VN",
    "value": "Vietnam"
}, {
    "key": "YE",
    "value": "Yemen"
}];