import {Box, Button, styled, Typography} from "@mui/material"
import { useTranslation } from "react-i18next";
import {MOVE_TO_STEP, SET_CURRENT_PAGE} from "../../redux/currentPosition/currentPositionTypes";
import {useDispatch} from "react-redux";


const StepBox = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
`

const StepIndexThread = styled('div')`
    display: flex;
    flex-direction: row;
 `

 const StepLine = styled('div')`
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #E1E6ED;
 `

const StepButton = styled(Button)({
    borderRadius: '100px',
    display: 'flex',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    padding: '8px 14px 8px 14px',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width:640px)': {
        borderRadius: '100px',
        display: 'flex',
        width: '32px',
        minWidth: '32px',
        height: '32px',
        padding: '8px 14px 8px 14px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '@media (min-width:981px)': {
        borderRadius: '100px',
        display: 'flex',
        width: '40px',
        minWidth: '40px',
        height: '40px',
        padding: '8px 14px 8px 14px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "&.Mui-disabled": {
        background: "#F4F8FF",
        color: "#AEBECC"
    },
    "&.MuiButton-outlined": {
        border: '1px solid',
    },
    "&.MuiButton-outlined.Mui-disabled": {
        backgroundColor: 'transparent',
        color: '#0186FF',
    },
});

const Step = ({ index, activeIndex, text, lastIndex }: {
    index: number,
    activeIndex: number,
    text: string,
    lastIndex?: boolean
  }) => {
  const dispatch = useDispatch();
  const moveToStep = () => {
      if (index === activeIndex || activeIndex > 4) return;
      dispatch({
        type: MOVE_TO_STEP,
        payload: {
        step: index
      }
    })
  };
    return (
      <StepBox sx={lastIndex ? {width: 'auto'} : {}}>
        <StepIndexThread>
            <StepButton
                sx={{ pointerEvents: activeIndex > 4 ? 'none' : 'auto'}}
                variant={index >= activeIndex || (index === 4 && activeIndex > 4) ? 'contained' : 'outlined'}
                disabled={index > activeIndex || (index < 4 && activeIndex > 4)}
                onClick={moveToStep}>
                <Typography variant="h5">
                    {index}
                </Typography>
            </StepButton>
            {!lastIndex && <StepLine/>}
        </StepIndexThread>
        <Typography
            variant={index >= activeIndex ? 'body1' : 'h5'}
            sx={{display: {xs: 'none', md: 'block'}}}
        >
             {text}
        </Typography>
      </StepBox>)
}

const StepsWrapper = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

type TStepsLabels = {
    [index: number]: string;
};

export const Steps = ({activeIndex = 1}: {activeIndex: number}) => {
    const { t } = useTranslation('belizForm');
    const stepsLabels: TStepsLabels = {
        1: t("steps.personalData"),
        2: t("steps.residence"),
        3: t("steps.agreement"),
        4: t("steps.verification")
    };
    return (
          <StepsWrapper>
              <Box sx={{ width: '100%', display: 'flex'}}>
                  <Step index={1} activeIndex={activeIndex} text={stepsLabels[1]} />
                  <Step index={2} activeIndex={activeIndex} text={stepsLabels[2]} />
                  <Step index={3} activeIndex={activeIndex} text={stepsLabels[3]} />
                  <Step index={4} activeIndex={activeIndex} lastIndex text={stepsLabels[4]} />
              </Box>
              <Typography
                  variant={'h5'}
                  sx={{display: {xs: 'block', md: 'none'}}}
              >
                  {stepsLabels[activeIndex <= 4 ? activeIndex : 4]}
              </Typography>
          </StepsWrapper>
    )
}
