import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

function ThanksForMessageDialog({
    open,
    handleClose,
}: {
    open: boolean;
    handleClose: () => void;
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("armeniaForm");

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={() => {
                handleClose();
            }}
        >
            <IconButton
                aria-label="close"
                onClick={() => {
                    handleClose();
                }}
                sx={{
                    width: { xs: 24, sm: 36 },
                    height: { xs: 24, sm: 36 },
                    padding: "12px",
                    position: "absolute",
                    right: { xs: 16, sm: 18 },
                    top: { xs: 16, sm: 18 },
                    color: "#6B6B6B",
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>{t("thanksForMessage.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("thanksForMessage.text")}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default ThanksForMessageDialog;
