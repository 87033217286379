import {useSelector} from "react-redux";
import {MainContactDataForm} from "./MainContactDataForm"
import {LoginByPhoneForm} from "./LoginByPhoneForm"
import {getProvider, getProviderStep,getIntermediateStep} from "../../../redux/currentPosition/currentPositionSelector"
import { TIntermediateStep } from "../../../redux/currentPosition/currentPositionTypes";
import {ConfirmRegistrationForm} from "./ConfirmRegistrationForm"
import {userHasAuthorized} from "../../../redux/mainContactData/mainContactDataSelectors"
import {Box, styled, Typography, useTheme} from "@mui/material";
import {ChooseBroker} from "../ChooseBroker/ChooseBroker";
import {ProviderForm} from "../ChooseBroker/ProviderForm";
import React, { useEffect } from "react";
import {LinearSteps} from "../../../components/LinearSteps/LinearSteps";
import {MainArmeniaForm} from "../ArmeniaForm/MainArmeniaForm";
import {useTranslation} from "react-i18next";
import {getProviderFromURL} from "../../../utils/common";
import {getContinueAnketaPopupIsOpen} from "../../../redux/continueAnketaPopup/continueAnketaPopupSelector";
import {StartNewOrContinueOldAnketaPopup} from "../MainForm/StartNewOrContinueOldAnketaPopup";
import { getAccountData } from "../../../redux/accountData/accountSelector";


export const FormOuterWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  @media (min-width: 640px) {
    padding: 64px 80px;
    margin: 0 40px 64px;
  }
`
export const armeniaFormOuterWrapper = {
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
        borderRadius: '16px'
    }
}
export const zaFormOuterWrapper = {
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
        borderRadius: '16px'
    }
}

export const ruFormOuterWrapper = {
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
        borderRadius: '16px'
    }
}

export const belizFormOuterWrapper = {
    padding: 0,
    margin: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    '@media (min-width: 640px)': {
        padding: 0,
        margin: 0,
    },
    '@media (min-width: 981px)': {
        padding: '64px 80px',
        margin: '0 40px 64px',
        backgroundColor: '#F4F8FF',
    }
}

export const FormWrapper = styled(Box)`
  //max-width: 764px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  @media (min-width: 640px) {
    gap: 48px;
  }
`
export const armeniaFormWrapper = {
    color: '#242624',
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
    }
}

export const zaFormWrapper = {
    color: '#242624',
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
    }
}

export const ruFormWrapper = {
    color: '#242624',
    '@media (min-width: 640px)': {
        backgroundColor: '#F6F6F6',
    }
}

export const belizFormWrapper = {
    gap: '32px',
    '@media (min-width: 640px)': {
        gap: '32px',
    },
    '@media (min-width: 981px)': {
        gap: '48px',
    }
}

export const LoginForm = () => {
    const {t} = useTranslation('armeniaForm');
    const providerStep = useSelector(getProviderStep);
    const intermediateStep = useSelector(getIntermediateStep);
    const authorized = useSelector(userHasAuthorized);
    const provider = getProviderFromURL() || 'beliz';
    const isOpen = useSelector(getContinueAnketaPopupIsOpen);
    const step = authorized ? 3 : providerStep;
	const hasAccount = useSelector(getAccountData);

    useEffect(() => {
        if (isOpen) {
            sessionStorage.setItem('isExistsAnketa',"1");
        }
    }, [isOpen]);

    const providerStyles: {[index: string]: {}} = {
        'beliz': belizFormOuterWrapper,
        'armenia': armeniaFormOuterWrapper,
        'za': zaFormOuterWrapper,
        'ru': ruFormOuterWrapper
    }

    const providerFormStyles: {[index: string]: {}} = {
        armenia: armeniaFormWrapper,
        beliz: belizFormWrapper,   
        za: zaFormWrapper,    
        ru: ruFormWrapper    
    };
    

    return (
        <FormOuterWrapper sx={provider ? providerStyles[provider] : {}}>
            <FormWrapper sx={providerFormStyles[provider] || belizFormWrapper}>

                {!hasAccount && (provider
                    ?
                    <Typography variant="h1">
                        {t('fillForm')}
                    </Typography>
                    :
                    <h1>{t('fillForm')}</h1>
                )}
                {intermediateStep === TIntermediateStep.ENTER_PHONE && <LoginByPhoneForm />}
                {intermediateStep === TIntermediateStep.CONFIRM_SMS && <ConfirmRegistrationForm />}
                {/* {!isOpen && intermediateStep === TIntermediateStep.MAIN_FORM && <MainContactDataForm />} */}

                {/* {step === 1 && <LoginByPhoneForm/>} */}
                {/* {step === 2 && <ConfirmRegistrationForm/>} */}
                {/*{step === 3 && <MainContactDataForm/>}*/}
                {isOpen && <StartNewOrContinueOldAnketaPopup/>}
            </FormWrapper>
        </FormOuterWrapper>
    )
}
