import { Card, CardActions, CardContent, CardHeader, styled, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent"
import { INCREASE_CURRENT_PAGE, SET_PROVIDER, TProviderType } from "../../../redux/currentPosition/currentPositionTypes";
import LogoImg from '../../../imgs/armenia.svg';
import { getStoredArmeniaAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { GET_ANKETAS_DATA, SET_POPUP_OPEN } from "../../../redux/continueAnketaPopup/continueAnketaPopupTypes";
import {useTranslation} from "react-i18next";

const Logo = styled('div')`
background-image: url(${LogoImg});
width: 150px;
height: 30px;
margin-left: 20px;
background-size: cover;
`
const BrokerCardText = styled(Typography)`
    padding: 5px
`

export const ArmeniaBrokerCard = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const submitForm = () => {
        dispatch({
            type: GET_ANKETAS_DATA,
            payload: {
                provider: TProviderType.armenia,
                needAConfirmWindow: true
            }
        })
    }

    return (<Card sx={{ maxWidth: 300, margin: '5px', border: '1px solid #dddddd' }}>
        <CardHeader
            title={t('brokerCard.armeniaTitle')}
            subheader={t('brokerCard.subTitle')}
        />
        <CardContent>
            <BrokerCardText variant="body2">
                {t('brokerCard.description1')}
            </BrokerCardText>
            <BrokerCardText variant="body2">
                {t('brokerCard.description2')}
            </BrokerCardText>
            <BrokerCardText variant="body2">
                {t('brokerCard.description3')}
            </BrokerCardText>
        </CardContent>
        <Logo />
        <CardActions>
        <ButtonComponent btnMargin = {1.2} text={t('buttons.fillForm')} onClick={submitForm} />
        </CardActions>
    </Card>)
}
