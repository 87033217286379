import { TProviderType } from "../currentPosition/currentPositionTypes";

export const SET_POPUP_OPEN= 'SET_POPUP_OPEN';
export const SET_POPUP_CLOSED= 'SET_POPUP_CLOSED';
export const CONTINUE_FILLING_ANKETA = 'CONTINUE_FILLING_ANKETA';
export const CANCEL_FILLING_ANKETA = 'CANCEL_FILLING_ANKETA';
export const GET_ANKETAS_DATA = 'GET_ANKETAS_DATA';

export type TsetPopupOpenAction = {
    type: typeof SET_POPUP_OPEN,
    payload: {
        provider: TProviderType,
        name?: string
    }
}

export type TsetPopupClosedAction = {
    type: typeof SET_POPUP_CLOSED
}

export type TContinueFillingAnketaAction = {
    type: typeof CONTINUE_FILLING_ANKETA
}

export type TCancelFillingAnketaAction = {
    type: typeof CANCEL_FILLING_ANKETA
}

export type TGetAnketasDataAction = {
    type: typeof GET_ANKETAS_DATA,
    payload: {
        provider: TProviderType,
        needAConfirmWindow?: boolean
    }
}

export type TContinueAnketaPopupState = {
    popupIsOpen : boolean;
    provider: TProviderType,
    newAnketa: boolean
    userName: string
}

export type TContinueAnketaPopupAction = TsetPopupOpenAction | TsetPopupClosedAction | TContinueFillingAnketaAction | TCancelFillingAnketaAction | TGetAnketasDataAction;