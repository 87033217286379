import { Box, Link, styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TProviderType } from "../../redux/currentPosition/currentPositionTypes";
import { ButtonComponent } from "../Buttons/ButtonComponent";
import { FormContainer } from "../FieldContainer/FieldContainer";
import { armeniaFormContainer, zaFormContainer, formContainerStyles } from "../../forms/MainHelpersApp/RegistrationForm/LoginByPhoneForm";
import { logout } from "../../utils/logout";

export const belizFormContainer = {
	gap: '32px',
	'@media (min-width: 640px) and (max-width: 981px)': {
		padding: '48px 32px 0',
		gap: '32px',
	},
	'@media (min-width: 981px)': {
		padding: '48px 36px 32px',
		gap: '64px',
	}
}

const ResultWrapper = styled(Box)({
	margin: '0 auto',
	padding: '40px 0',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	textAlign: 'start',
	alignSelf: 'center',
	gap: '16px',
	'@media (min-width:640px)': {
		alignItems: 'center',
		textAlign: 'center',
		gap: '24px'
	},
	'@media (min-width:1140px)': {
		width: '80%'
	}
});


export type THasAccountMessageProps = {
	phone: string;
	provider: TProviderType;
}

function HasAccountMessage({ phone, provider }: THasAccountMessageProps) {
	const { t } = useTranslation();
	const theme = useTheme();
	const emails = {
		beliz: 'clients@ffin.bz',
		armenia: 'info@ffin.am',
		za: 'clients@tfos.com',
		ru: 'info@cifra-broker.ru',
		none: 'info@ffin.am'
	};
	const email = emails[provider];

	return (
		<FormContainer
			sx={formContainerStyles[provider] || belizFormContainer}
			bgcolor={{ sm: theme.palette.background.paper }}
		>

		<ResultWrapper>
			<Typography variant={'h1'}>{t('account.open')}</Typography>
			<Typography variant={'subtitle1'}>{t('account.description', { phone })}</Typography>
			<Typography variant={'subtitle1'}>
				{t('account.supportHelp')}
				<Link variant="subtitle1" underline="none" href="mailto:info@ffin.am">
					{t('account.supportEmail', { email })}
				</Link>
			</Typography>
			<ButtonComponent
                text={t('buttons.login')}
                btnMargin={0}
				onClick={()=> window.open("https://tradernet.am/authentication/login", "_blank")}
            />
			<ButtonComponent
                minWidth={120}
                btnMargin={"32px 0 0"}
                text={t("buttons.exit")}
                onClick={logout}
              />
		</ResultWrapper>
		</FormContainer>
	)
}

export default HasAccountMessage