import { TProviderType } from "../redux/currentPosition/currentPositionTypes";
import { fetchData } from "../utils/fetch";
import { deleteAllDocumentsFromDb } from "../utils/uploadFile";
import { STORE_FORM_DATA } from "../redux/userData/userDataTypes";

export const providerUrlPostfix: { [key in keyof typeof TProviderType]: string } = {
    beliz: 'BZ',
    armenia: 'AM',
    za: 'ZA',
    ru: 'RU',
    none: 'COMMON'
};


export const createAnketaSnapshot = async (provider: keyof typeof TProviderType) => {
    const urlPostfix = providerUrlPostfix[provider];
    if (!urlPostfix) {
        throw new Error(`Провайдер ${provider} не найден`);
    }

    await fetchData({
        url: `database/snapshot/anketa`,
        method: 'POST',
        params: {
            type: urlPostfix
        }
    });
}


export const newAnketa = async (provider: keyof typeof TProviderType, dispatch: any) => {
    dispatch({
        type: 'CANCEL_FILLING_ANKETA'
    });

    const urlPostfix = providerUrlPostfix[provider];
    if (!urlPostfix) {
        throw new Error(`Провайдер ${provider} не найден`);
    }

    await createAnketaSnapshot(provider);

    deleteAllDocumentsFromDb();
    await fetchData({
        'url': `client/anketas/${urlPostfix}`,
        'method': 'DELETE',
        'params': {}
    });
    const phone = sessionStorage.getItem('phone');
    dispatch({
        type: STORE_FORM_DATA,
        payload: {
            formName: TProviderType[provider],
            fieldName: "phone",
            fieldValue: phone,
        },
    });
}

