import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoutIcon from "../../imgs/exit-icon.svg";
import { logout } from "../../utils/logout";

export const Logout = ({style}: {style?: object}) => {
    const {t} = useTranslation();
    return (<Box sx={style} title={t('tabsMenu.exit')}>
        <IconButton sx={{padding: 0, color: "#A2A2A2"}} onClick={logout}>
            <img src={LogoutIcon} alt="Exit" />
        </IconButton>
    </Box>)
}
