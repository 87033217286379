import { call, put, take, takeLatest } from "redux-saga/effects";
import { fetchData } from "../utils/fetch";
import { SET_REGISTERED } from "../redux/mainContactData/mainContactDataTypes";

type TUTMLabel = {
    utm_source: string | null,
    utm_medium: string | null,
    utm_campaign: string | null
}

function* postUTMParams({
    utm ='',
    utm_source = '',
    utm_medium = '',
    utm_campaign = '',
    utm_term = '',
    utm_content = '',
    providerType
}: {
    utm: string | null,
    utm_source: string | null,
    utm_medium: string | null,
    utm_campaign: string | null,
    utm_term: string | null,
    utm_content: string | null,
    providerType: string | null
}) {
    yield call(fetchData, {
        url: `client/anketas/save-utm-labels`,
        method: 'POST',
        params: {
            utm,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            type: providerType
        }
    });
}

function* sendUTMParams() {
    let url = new URL(window.location.href);
    let utm_source = url.searchParams.get('utm_source');
    let utm_medium = url.searchParams.get('utm_medium');
    let utm_campaign = url.searchParams.get('utm_campaign');
    let utm_term = url.searchParams.get('utm_term');
    let utm_content = url.searchParams.get('utm_content');
    let provider = url.searchParams.get('provider');
    let shortProviderName;

    switch (provider) {
        case 'beliz':
            shortProviderName = 'BZ'
            break;
        case 'armenia':
            shortProviderName = 'AM'
            break;
        case 'za':
            shortProviderName = 'ZA'
            break;     
        case 'ru':
            shortProviderName = 'RU'
            break;                      
        default:
            shortProviderName = 'CMN'
            break;
    }

    yield call(postUTMParams, {
        utm: window.location.href,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        providerType: shortProviderName
    });
}
function* sendUTMParamsSaga() {
    yield take(SET_REGISTERED);
    yield call(sendUTMParams);
}

export default sendUTMParamsSaga;