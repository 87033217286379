import {TextField, InputLabel, Typography, Box, Link} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './MainBelizForm.css'
import { FieldContainer, FormContainer, FormRow, FormWrapper } from "../../../components/FieldContainer/FieldContainer";
import { BelizCountryField } from "./BelizTaxResidencyField";
import { RadioGroupWithOtherOption } from "../../../components/RadioGroups/RadioGroupWithOtherOption";
import { RadioGroupComponent } from "../../../components/RadioGroups/RadioGroup";
import { CheckBoxComponent } from "../../../components/CheckBoxes/CheckBoxComponent";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import {
    DECREASE_PROVIDER_STEP,
    INCREASE_PROVIDER_STEP
} from "../../../redux/currentPosition/currentPositionTypes";
import { TFieldStucture, taxResidencyOptions } from "./BelizConfigTypes";
import { getApplicantFiles, getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { STORE_FORM_DATA, TAnketa, TAnketaField } from "../../../redux/userData/userDataTypes";
import { CheckBoxComponentWithOtherOption } from "../../../components/CheckBoxes/CheckBoxComponentWithOtherOption";
import { BelizSelectFile } from "./BelizSelectFile";
import { useStoredData } from "../../../utils/common";
import { TStoredAnketaField } from "../../../redux/storedAnketas/storedAnketasTypes";
import { TApplicationState } from "../../../redux/rootReducer";
import { getFieldVisibility } from "../../../redux/fieldsVisibility/fieldsVisibilitySelectors";
import {getBelizErrorFields, getBelizFormErrors} from "../../../redux/belizErrors/belizErrorsSelectors";
import {REMOVE_ERROR_FIELD, SET_ERROR_FIELDS, SET_FORM_ERRORS} from "../../../redux/belizErrors/belizErrorsTypes";
import {useTranslation} from "react-i18next";
import i18n from "../../../i18n/i18n";


const BasicTextField = (field: TFieldStucture) => {
    const { t } = useTranslation('belizForm');
    const dispatch = useDispatch();
    const [defaultValue]: string[] = useStoredData<string>('beliz', [field.optionName]);
    const [fieldValue, setFieldValue] = useState(defaultValue);
    const [fieldError, setFieldError] = useState(field.errorText ?? '');

    useEffect(()=>{
        if (field.errorText){
            setFieldError(field.errorText);
        }
    }, [field.errorText]);

    return (<FieldContainer id={field.optionName} key={field.optionName}>
        {/*<InputLabel htmlFor={`beliz.${field.optionName}`}>{field.optionLabel}</InputLabel>*/}
        <InputLabel htmlFor={`beliz.${field.optionName}`}>{t(`step2.${field.optionName}`)}</InputLabel>
        <TextField
            size="small"
            sx={{ width: 1 }}
            margin={"normal"}
            placeholder={field.placeholder}
            type="text"
            name={`beliz.${field.optionName}`}
            value={fieldValue}
            required
            error={!!fieldError}
            helperText={fieldError}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(event.target.value);
                setFieldError('');
                dispatch({
                    type: REMOVE_ERROR_FIELD,
                    payload: {
                        errorField: field.optionName
                    }
                });
            }}
        />
    </FieldContainer>)
}

const renderField = (field: TFieldStucture,
    formErrors: {
        [key: string]: TError;
    }) => {
    switch (field.optionType) {
        case 'selectcountry':
            return (<FieldContainer id={field.optionName} key={field.optionName}>
                {/*<InputLabel required={field.required} htmlFor={field.optionName}>{field.optionLabel}</InputLabel>*/}
                {/*<InputLabel required={field.required} htmlFor={field.optionName}>{i18n.t(`${field.optionLabel}`, { ns: 'belizForm' })}</InputLabel>*/}
                <InputLabel required={field.required} htmlFor={field.optionName}>{i18n.t(`step2.${field.optionName}`, {ns: 'belizForm'})}</InputLabel>
                <BelizCountryField
                    fieldName={field.optionName}
                    fieldLabel={field.optionLabel}
                    required={field.required ?? false}
                    hasError={formErrors[field.optionName] as boolean}
                />
            </FieldContainer>)
        case 'textfield':
            return (<BasicTextField
                    key={field.optionName}
                    optionId={field.optionId}
                    optionType='textfield'
                    optionName={field.optionName}
                    optionLabel={field.optionLabel}
                    placeholder={field.placeholder}
                    errorText={formErrors[field.optionName] as string}
                />
            )
        case 'singlechoice':
            if (field?.options?.['other']) {
                return (<RadioGroupWithOtherOption
                    key={field.optionName}
                    providerName="beliz"
                    groupName={field.optionName}
                    groupLabel={field.optionLabel}
                    required={field.required ?? false}
                    options={field.options}
                    hasError={formErrors[field.optionName] as boolean}
                />)
            } else {
                return (<RadioGroupComponent
                    key={field.optionName}
                    providerName="beliz"
                    groupName={field.optionName}
                    groupLabel={field.optionLabel}
                    required={field.required ?? false}
                    options={field.options}
                    hasError={formErrors[field.optionName] as boolean}
                />)
            }
        case 'multiplechoice':
            if (field?.options?.['other']) {
                return (<CheckBoxComponentWithOtherOption
                    key={field.optionName}
                    providerName="beliz"
                    groupName={field.optionName}
                    groupLabel={field.optionLabel}
                    required={field.required ?? false}
                    options={field.options ?? {}}
                    hasError={formErrors[field.optionName] as boolean}
                />)
            } else {
                return (<CheckBoxComponent
                    key={field.optionName}
                    providerName="beliz"
                    groupName={field.optionName}
                    groupLabel={field.optionLabel}
                    required={field.required ?? false}
                    options={field.options ?? {}}
                    hasError={formErrors[field.optionName] as boolean}
                />)
            }
        default:
            break;
    }
}

const initFormErrors = () => {
    const optionsErrors: { [key: string]: TError } = {}
    taxResidencyOptions.forEach((option: TFieldStucture) => {
        optionsErrors[option.optionName] = false
    });

    return optionsErrors;
};

const initFieldsWithErrors: string[] = [];

type TError = boolean | string | undefined;

const validateForm = (anketa: TAnketa | null,
    serverAnketaData: TStoredAnketaField | undefined,
    filesNames: string[],
    formErrors: {
        [key: string]: TError;
    },
    setFormErrors: React.Dispatch<React.SetStateAction<{ [key: string]: TError }>>,
                      setFieldsWithErrors: React.Dispatch<React.SetStateAction<string[]>>,
    documentsWidgetVisible: boolean) => {
    // setFormErrors(initFormErrors);
    // const errors = { ...formErrors };
    // const errors = structuredClone(formErrors);
    const errors: { [key: string]: TError } = {};
    const errorFields: string[] =[];

    let isFormValid = true;
    const fields: {
        [fieldName: string]: TAnketaField;
    } | undefined = anketa?.fields;
    const getOptionValue = (optionName: string) => {
        return fields?.[optionName]?.fieldValue ?? serverAnketaData?.[optionName];
    }

    taxResidencyOptions.forEach(option => {
        if (option.required) {
            if (option.optionType !== 'multiplechoice') {
                if (option.optionType === 'singlechoice') {
                    const radioGroupOptionName = `${option.optionName}_radiogroup`;
                    const optionValue = getOptionValue(radioGroupOptionName);
                    const optionText = getOptionValue(`${option.optionName}_text`);
                    if (!optionValue) {
                        isFormValid = false;
                        errors[option.optionName] = true;
                        errorFields.push(option.optionName);
                    } else if (optionValue === 'other' && !optionText) {
                        isFormValid = false;
                        errors[option.optionName] = true;
                        errorFields.push(option.optionName);
                    } else {
                        errors[option.optionName] = false;
                    }
                } else if (option.optionType === 'selectcountry') {
                    const optionValue = getOptionValue(option.optionName);
                    if (!optionValue) {
                        isFormValid = false;
                        errors[option.optionName] = true;
                        errorFields.push(option.optionName);
                    } else {
                        errors[option.optionName] = false;
                    }
                }
            } else {
                const checkboxOptionsNames = Object.keys(option.options ?? {}).map(checkboxName => `${option.optionName}_${checkboxName}_checkbox`);
                let hasSelectedCheckbox = false;
                checkboxOptionsNames.forEach(checkboxName => {
                    const checkboxValue = getOptionValue(checkboxName);
                    if (checkboxValue) {
                        hasSelectedCheckbox = true;
                    }
                })
                const otherCheckboxValue = getOptionValue(`${option.optionName}_other_checkbox`);
                // const otherCheckboxText = getOptionValue(`${option.optionName}_other_text`);
                const otherCheckboxText = getOptionValue(`${option.optionName}_text`);
                if (!hasSelectedCheckbox || (otherCheckboxValue && !otherCheckboxText)) {
                    isFormValid = false;
                    errors[option.optionName] = true;
                    errorFields.push(option.optionName);
                } else {
                    errors[option.optionName] = false;
                }
            }
        } else {
            if (option.validationRule) {
                const optionValue = getOptionValue(option.optionName);
                // errors[option.optionName] = option.validationRule(fields?.[option.optionName]?.fieldValue ?? '');
                errors[option.optionName] = option.validationRule(optionValue as string ?? '');
                if (errors[option.optionName]){
                    isFormValid = false;
                    errorFields.push(option.optionName);
                }
            }
        }
    });
    errors['filesWasAttached'] = !filesNames?.length;
    if (!filesNames?.length && documentsWidgetVisible) {
        isFormValid = false;
        errorFields.push('filesWasAttached');
    }
    setFormErrors(errors);
    setFieldsWithErrors(errorFields);
    return isFormValid;
}

export const BelizTaxResidency = () => {
    const { t } = useTranslation(['translation', 'belizForm']);
    const dispatch = useDispatch();

    useEffect(() => {
        const body = document.querySelector('.MainForm-wrapper');
        setTimeout(() => {
            // body?.scrollTo({ top: 0, behavior: 'smooth' })
            body?.scrollIntoView({ block: "start", behavior: 'smooth' })
        }, 50)
    }, []);


    const bzAnketasData = useSelector(getLocalStoredBelizAnketasData);
    const serverBelizData = useSelector(getStoredBelizAnketasData)?.data;
    const filesNames = useSelector(getApplicantFiles);
    const documentsWidgetVisible = useSelector((state: TApplicationState) => getFieldVisibility(state, 'attachFilesWidget'));
    const taxResidency = bzAnketasData?.fields['taxresidency']?.fieldValue || serverBelizData?.['taxresidency']
    const belizFormErrors = useSelector(getBelizFormErrors);
    const belizErrorFields = useSelector(getBelizErrorFields);
    const [formErrors, setFormErrors] = useState(initFormErrors());
    const [fieldsWithErrors, setFieldsWithErrors] = useState(initFieldsWithErrors);

    useEffect(() => {
        dispatch({
            type: STORE_FORM_DATA,
            payload: {
                formName: 'beliz',
                fieldName: 'taxresidency',
                fieldValue: taxResidency//just for recalculating documents select visibility
            }
        });
    }, [])

    useEffect(() => {
        dispatch({
            type: SET_FORM_ERRORS,
            payload: {
                errors : formErrors
            }
        });
    }, [formErrors])

    useEffect(() => {
        dispatch({
            type: SET_ERROR_FIELDS,
            payload: {
                errorFields : fieldsWithErrors
            }
        });
    }, [fieldsWithErrors])



    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const setNextForm = () => {
        if (!validateForm(bzAnketasData, serverBelizData, filesNames, formErrors, setFormErrors, setFieldsWithErrors, documentsWidgetVisible)) {
            return;
        }
        dispatch({
            type: INCREASE_PROVIDER_STEP
        })
    }

    const handleLinkClick = (idName: string) => {
        const field = document.getElementById(`${idName}`);
        field?.scrollIntoView({ block: "start", behavior: 'smooth' })
    };

    return (<FormWrapper>
        <FormContainer>
            {
                taxResidencyOptions.map(field => renderField(field, formErrors))
            }
            {documentsWidgetVisible && (<BelizSelectFile
                required
                error={formErrors['filesWasAttached'] as boolean}
            />)}
            {!!belizErrorFields.length && (
                <Box sx={{display: 'flex', flexDirection: 'column', gap: {xs: '8px', md: '12px'}}}>
                    <Typography
                        variant="subtitle1"
                        fontSize={18}
                        color="error.main"
                    >
                        {t('errors.errorFieldsQuestions', { count: belizErrorFields.length })}
                    </Typography>
                    <ul className="errors_list" >
                        {
                            taxResidencyOptions
                                .filter((field) => belizErrorFields.includes(field.optionName))
                                .map((option) =>
                                    <li key={`error_${option.optionName}`} style={{cursor: 'pointer'}}>
                                    <Link
                                        underline="hover"
                                        color="error.main"
                                        onClick={() => {handleLinkClick(option.optionName)}}
                                    >
                                        {/*<Typography variant="body2" color="error.main">{option.optionLabel}</Typography>*/}
                                        <Typography variant="body2" color="error.main">{t(`step2.${option.optionName}`, {ns: 'belizForm'})}</Typography>
                                    </Link>
                                    </li>
                                    )
                        }
                        {
                            belizErrorFields.includes('filesWasAttached')
                            &&
                            <li style={{cursor: 'pointer'}}>
                                <Link
                                    underline="hover"
                                    color="error.main"
                                    onClick={() => {handleLinkClick('filesWasAttached')}}
                                >
                                    <Typography variant="body2" color="error.main">
                                        {t('step2.incomeConfirmation', {ns: 'belizForm'})}
                                    </Typography>
                                </Link>
                            </li>
                        }
                    </ul>
                </Box>
            )}

            <ButtonComponent text={t('buttons.next')} btnMargin={0} onClick={setNextForm} />

        </FormContainer>
    </FormWrapper>)
}
