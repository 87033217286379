import { TAdminAnketasState } from "./adminAnketas/adminAnketasTypes";
import { TAdminDataState } from "./adminData/adminDataTypes";
import { TAdminEditAnketaModeState } from "./adminEditAnketaMode/adminEditAnketaModeTypes";
import { adminRootReducer } from "./adminRootReducer";
import { TContinueAnketaPopupState } from "./continueAnketaPopup/continueAnketaPopupTypes";
import { TCountriesState } from "./countriesList/countriesListTypes";
import { TCurrentPositionState } from "./currentPosition/currentPositionTypes";
import { TFieldsVisibilityState } from "./fieldsVisibility/fieldsVisibilityTypes";
import { TBelizFormErrorsState } from "./belizErrors/belizErrorsTypes";
import { TMainContactDataState } from "./mainContactData/mainContactDataTypes";
import { TStoredAnketasState } from "./storedAnketas/storedAnketasTypes";
import { TSumSubDataState } from "./sumSubData/sumSubDataTypes";
import { TUserDataState } from "./userData/userDataTypes";
import { userRootReducer } from "./userRootReducer";
import { combineReducers } from "redux";
import { TDocumentsState } from "./fetchedDocuments/fetchedDocumentsTypes";
import { TAccountState } from "./accountData/accountTypes";
import { TPopupErrorsState } from "./errorPopup/errorPopupTypes";

export type TApplicationState = {
    usersAnketa: {
        data: TUserDataState;
        mainContactData: TMainContactDataState,
        currentPosition: TCurrentPositionState,
        storedAnketas: TStoredAnketasState,
        continueAnketaPopup: TContinueAnketaPopupState,
        sumSubData: TSumSubDataState,
        countriesData: TCountriesState,
        fieldsVisibility: TFieldsVisibilityState,
        belizFormErrors: TBelizFormErrorsState,
        documents: TDocumentsState,
		accountData: TAccountState,
        hasErrorPopup: TPopupErrorsState
    },
    adminAnketas: {
        adminData: TAdminDataState,
        anketasData: TAdminAnketasState,
        adminEditCommentMode: TAdminEditAnketaModeState
    }
}

export const rootReducer = combineReducers({
    usersAnketa: userRootReducer,
    adminAnketas: adminRootReducer
})