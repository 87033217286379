import React from 'react';
import './AppHeaderBeliz.css';
import {ButtonComponent} from "../Buttons/ButtonComponent";
import {Link, Typography} from "@mui/material";

export const AppHeaderBeliz = () => {
  return (
    <div className="container-header-beliz">
      <div className="header-beliz">
        <div className='logo-beliz'></div>
        <div className="menu-beliz">
          <div className="cta">
            <Typography variant="body2" color="text.secondary" align="right">
              При возникновении вопросов звоните на горячую линию
            </Typography>
          </div>

          <div className="language-select-beliz">
            <div className='language-icon'></div>
            <div className="language">RUS</div>
            <div className="arrow-icon"></div>
          </div>

            <ButtonComponent
                id="phone_button"
                text='8 800 333 5 888'
                variant='outlined'
                btnPadding='12px 24px'
                minWidth={0}
                btnMargin={0}
                fontSize={16}
                href="tel:88003335888"
            />
          <Link className='phone_icon_link' href="tel:88003335888" underline="none">
            <div className='phone_icon'>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
