import { TProviderType } from "../currentPosition/currentPositionTypes";

export const SET_SUMSUB_TOKEN = 'SET_SUMSUB_TOKEN';
export const SET_SUMSUB_BZ_TOKEN = 'SET_SUMSUB_BZ_TOKEN';
export const SET_SUMSUB_STATUS = 'SET_SUMSUB_STATUS';
export const SET_SID = 'SET_SID';

export type TSumSubDataState = {
    token: string | undefined;
    bzToken: string | undefined;
    status: string | undefined;
    provider: TProviderType,
    SID: string | undefined;
    phoneId: string | undefined;
}

export type TSetSumsubTokenAction ={
    type: typeof SET_SUMSUB_TOKEN,
    payload: {
        token: string
    }
}

export type TSetSumsubBzTokenAction ={
    type: typeof SET_SUMSUB_BZ_TOKEN,
    payload: {
        token: string
    }
}

export type TSetSumsubStatusAction ={
    type: typeof SET_SUMSUB_STATUS,
    payload: {
        status: string,
        provider: TProviderType
    }
}

export type TSetSIDAction = {
    type: typeof SET_SID,
    payload: {
        SID: string,
        phoneId: string
    }
}

export type TSumsubAction = TSetSumsubTokenAction | TSetSumsubBzTokenAction | TSetSumsubStatusAction | TSetSIDAction;
