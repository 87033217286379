import { getProviderFromURL } from "../../utils/common";
import { TProviderType } from "../currentPosition/currentPositionTypes";
import {
  TAccountState,
  TAccountAction,
} from "./accountTypes";

const defaultProvider = getProviderFromURL();

const initialState = {
  provider:
    defaultProvider && defaultProvider in TProviderType
      ? (defaultProvider as TProviderType)
      : TProviderType.none,
  hasAccount: false,
  hasAccountEmail: false,
	email: null
};

export const accountReducer = (
  state: TAccountState = initialState,
  action: TAccountAction
) => {
  switch (action.type) {
    case "SET_HAS_ACCOUNT_PHONE":
      return {
        ...state,
        hasAccount: action.payload.hasAccount,
      };
    case "SET_HAS_ACCOUNT_EMAIL":
      return {
        ...state,
        hasAccountEmail: action.payload.hasAccountEmail,
        email: action.payload.email
      };
    default:
      return state;
  }
};
