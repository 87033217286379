import React from 'react';
import 'react-international-phone/style.css';

import {
    BaseTextFieldProps,
    InputAdornment,
    MenuItem,
    Select, styled,
    TextField,
    Typography,
} from '@mui/material';

import {
    CountryIso2,
    defaultCountries,
    FlagEmoji,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone';

const CountrySelect = styled(Select)({
    MenuProps: {
        style: {
            height: '300px',
            width: '360px',
            top: '10px',
            left: '-34px',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    },

    width: 'max-content',
    // Remove default outline (display only on focus)
    fieldset: {
        display: 'none',
    },
    '&.Mui-focused:has(div[aria-expanded="false"])': {
        fieldset: {
            display: 'block',
            outline: 'none',
            border: 'none',
        },
    },
    // Update default spacing
    '.MuiSelect-select': {
        padding: '8px',
        paddingRight: '24px !important',
        outline: 'none',
        border: 'none',
    },
    svg: {
        right: 0,
    },
});

export type MUIPhoneProps = BaseTextFieldProps & {
    value: string;
    onChange: (phone: string, country: CountryIso2) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    helperText: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
    defaultCountryCode?: string; 
}

export const MuiPhone: React.FC<MUIPhoneProps> = ({
                                                      value,
                                                      label,
                                                      placeholder,
                                                      size,
                                                      onChange,
                                                      onKeyDown,
                                                      onBlur,
                                                      onFocus,
                                                      helperText,
                                                      disabled,
                                                      defaultCountryCode = 'ru',
                                                      ...restProps
                                                  }) => {
    const {phone, handlePhoneValueChange, inputRef, country, setCountry} =
        usePhoneInput({
            defaultCountry: defaultCountryCode as CountryIso2, 
            value,
            countries: defaultCountries,
            onChange: (data) => {
                onChange(data.phone, data.country);
            },
        });

    return (
        <TextField
            size={size}
            variant="outlined"
            label={label}
            color="primary"
            required
            placeholder={placeholder}
            helperText={helperText}
            error={!!helperText}
            value={phone}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={handlePhoneValueChange}
            onKeyDown={onKeyDown}
            type="tel"
            inputRef={inputRef}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{marginRight: '2px', marginLeft: '-8px'}}
                        disablePointerEvents={disabled}
                    >
                        <CountrySelect
                            value={country}
                            onChange={(e) => setCountry(e.target.value as CountryIso2)}
                            renderValue={(value) => (
                                <FlagEmoji iso2={value as CountryIso2 | undefined} style={{display: 'flex'}}/>
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagEmoji
                                            iso2={country.iso2}
                                            style={{marginRight: '8px'}}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </CountrySelect>
                    </InputAdornment>
                ),
            }}
            {...restProps}
        />
    );
};
