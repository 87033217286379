import { Box } from "@mui/material";
import SumsubWebSdk from '@sumsub/websdk-react';
import { getLocalStoredZaAnketasData, getStoredZaAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DECREASE_PROVIDER_STEP, TProviderType } from "../../../redux/currentPosition/currentPositionTypes";
import { getSumSubToken } from "../../../redux/sumSubData/sumSubSelectors";
import { GET_SUMSUB_ZA_AUTH } from "../../../redux/storedAnketas/storedAnketasTypes";



export const ZaSumSubForm = () => {
    const accessToken = useSelector(getSumSubToken);
    const localZaData = useSelector(getLocalStoredZaAnketasData);
    const storedFields = useSelector(getStoredZaAnketasData);

    const userPhone: string | undefined = storedFields?.['phone'] || localZaData?.fields['phone']?.fieldValue;
    const userEmail: string | undefined = storedFields?.data['email'] as string | undefined || localZaData?.fields['email']?.fieldValue;
    const taxResidency = storedFields?.data['country'] || localZaData?.fields['country']?.fieldValue || '';

    const dispatch = useDispatch();

    useEffect(() => {
        if (!accessToken) {
            dispatch({
                type: DECREASE_PROVIDER_STEP
            })
        }
    }, [accessToken])

    const config = {
        lang: taxResidency === 'Russia' ? 'ru' : 'en',
        email: userEmail,
        phone: userPhone,
        i18n: { "document": { "subTitles": { "IDENTITY": "Upload a document that proves your identity" } } },
        onMessage: (type: any, payload: any) => {
        }
    }

    const accessTokenExpirationHandler = () => {
        dispatch({
            type: GET_SUMSUB_ZA_AUTH,
            payload: {
                provider: TProviderType.za
            }
        })
        return new Promise<string>((resolve: (value: string | PromiseLike<string>) => void, reject: (reason?: any) => void) => { resolve('success') });
    }

    return (<Box>
        {<SumsubWebSdk
            accessToken={accessToken ?? ''}
            expirationHandler={accessTokenExpirationHandler}
            config={config}
            options={{}}
        />
        }</Box>)
}