import {
  SET_FORM_ERRORS,
  TFormErrorsAction,
  TBelizFormErrorsState,
  SET_ERROR_FIELDS,
  REMOVE_ERROR_FIELD
} from "./belizErrorsTypes";

const defaultFormErrorsState: TBelizFormErrorsState = {
  errors: {},
  errorFields: []
};

export const belizErrorsReducer = (state = defaultFormErrorsState, action: TFormErrorsAction): TBelizFormErrorsState => {
  switch (action.type) {
    case SET_FORM_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      };
      case SET_ERROR_FIELDS:
      return {
        ...state,
        errorFields: action.payload.errorFields,
      };
      case REMOVE_ERROR_FIELD:
      return {
        ...state,
        errorFields: state.errorFields.filter((field) => field !== action.payload.errorField ),
      };
    default:
      return state;
  }
};
