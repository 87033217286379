import { Box, styled } from "@mui/material"
import './MainRuForm.css'
import { RuConfirmationForm } from "./RuConfirmationForm";
import { RuConfirmDocuments } from "./RuConfirmDocuments";
import { RuMainContactData } from "./RuMainContactData";
import { RuSumSubForm } from "./RuSumSubForm";
import { RuTaxResidency } from "./RuTaxResidency";
import { Logout } from "../../../components/Logout/Logout";
import NeedHelpButton from "../../../components/NeedHelpButton/NeedHelpButton";
import RetryLaterDialog from "../../../components/Dialogs/RetryLater";

const RuFormMenuButtons = styled(Box)`
    position: static;
    @media (min-width:640px) {
        display: flex;
        align-items: center;
        gap: 12px;
        position: absolute;
        top: 20px;
        right: 24px;
    }  
`

export const MainRuForm = ({ activeStep }: { activeStep: number }) => {    
    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        {activeStep === 1 && <RuMainContactData />}
        {activeStep === 2 && <RuTaxResidency />}
        {activeStep === 3 && <RuConfirmDocuments />}
        {activeStep === 4 && <RuConfirmationForm />}
        {activeStep > 4 && <RuSumSubForm />}
        <RuFormMenuButtons>
            <NeedHelpButton />            
            <Logout style={{ display: {xs: 'none', sm: 'flex'} }} />            
        </RuFormMenuButtons>
        <RetryLaterDialog/>
    </Box>)
}
