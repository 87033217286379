export const FETCH_DOCS_FROM_SERVER = 'FETCH_DOCS_FROM_SERVER';
export const SAVE_DOCUMENTS_LINKS = 'SAVE_DOCUMENTS_LINKS';


export type TFetchDocsAction = {
    type : typeof FETCH_DOCS_FROM_SERVER,
    payload: {
        docIds: string[],
        url: string,
        data? : any
    }
}

export type TSaveDocsAction = {
    type : typeof SAVE_DOCUMENTS_LINKS,
    payload: {
        [docId: string] : string
    }
}

export type TDocumentsAction = TFetchDocsAction | TSaveDocsAction;

export type TDocumentsState = {
    [docId : string] : string
}