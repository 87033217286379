import './VpnNotification.css';
import { useTranslation } from "react-i18next";
import wifiIcon from '../../imgs/wifi-icon.svg';

function VpnNotification() {
    const { t } = useTranslation();
    return (
      <div className="vpn-notification">
        <img src={wifiIcon} alt="WiFi icon" />
        <div className="vpn-notification__text">
          <h5>{t("notifications.vpn")}</h5>
          <p>{t("notifications.vpn-recommendation")}</p>
        </div>
      </div>
    );
}

export default VpnNotification;