import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Link, styled, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { FieldContainer, FormContainer, FormRow, FormWrapper } from "../../../components/FieldContainer/FieldContainer";
import {
    getStoredBelizAnketasData
} from "../../../redux/storedAnketas/storedAnketasSelectors";
import { ConfirmEmailDialog } from "./BelizEmailConfirmation";
import { ConfirmPhoneDialog } from "./BelizPhoneConfirmation";
import { INCREASE_PROVIDER_STEP } from "../../../redux/currentPosition/currentPositionTypes";
import { EMPTY_FIELD_TEXT, INVALID_PHONE_VALUE, NON_CONFIRMED } from "../../../redux/commonTypes";
import { STORE_FORM_DATA } from "../../../redux/userData/userDataTypes";
import { MuiPhone } from "../../../components/Fields/MuiPhone";
import { CountryIso2, usePhoneValidation } from "react-international-phone";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { useStoredData } from "../../../utils/common";
import BackdropLoader from "../../../components/BackdropLoader/BackdropLoader";
import { fetchData } from "../../../utils/fetch";

const CheckBoxesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 981px) {
    gap: 24px;
  }
`
type fieldForValidation = {
    value: string | boolean,
    validationType: 'isEmpty' | 'isConfirmed' | 'isChecked'
    errorHandler: (value: React.SetStateAction<string>) => void
}

const formIsValid = (fields: fieldForValidation[]) => {
    let isValid = true;
    fields.forEach(field => {
        if (field.validationType === 'isEmpty') {
            if (!field.value) {
                isValid = false;
                field.errorHandler(i18n.t('errors.field.emptyBox'));
            } else {
                field.errorHandler('');
            }
        }
        if (field.validationType === 'isConfirmed') {
            if (!field.value) {
                isValid = false;
                field.errorHandler(i18n.t('errors.contactConfirm'));
            } else {
                field.errorHandler('');
            }
        }
        if (field.validationType === 'isChecked') {
            if (!field.value) {
                isValid = false;
                field.errorHandler('');
            } else {
                // field.errorHandler('');
            }
        }
    });
    return isValid;
}

export const BelizContactData = () => {
    const { t } = useTranslation(['translation', 'belizForm']);
    const [showLoader, setShowLoader] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);

    const [defaultEmail] = useStoredData<string>('beliz', ['email']);
    const [email, setEmail] = useState(defaultEmail ?? '');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [emailHelperText, setEmailHelperText] = useState('');

    const [currentCountry, setCurrentCountry] = useState<CountryIso2>('ru');
    const defaultPhone = sessionStorage.getItem('phone') || '';
    const [phone, setPhone] = useState(defaultPhone ?? '');
    const [phoneHelperText, setPhoneHelperText] = useState('');

    const [defaultName] = useStoredData<string>('beliz', ['name']);
    const [name, setName] = useState(defaultName ?? '');
    const [nameHelperText, setNameHelperText] = useState('');

    const [defaultLastName] = useStoredData<string>('beliz', ['lastName']);
    const [lastName, setLastName] = useState(defaultLastName ?? '');
    const [lastNameHelperText, setLastNameHelperText] = useState('');

    const [defaultSecondName] = useStoredData<string>('beliz', ['secondName']);
    const [secondName, setSecondName] = useState(defaultSecondName ?? '');
    const [secondNameHelperText, setSecondNameHelperText] = useState('');

    const [confidentialPolicy, setConfidentialPolicy] = useState(true);
    const [mailingStatus, setMailingStatus] = useState(false);

    const [confirmEmailDialogOpen, setConfirmEmailDialogOpen] = useState(false);
    const phoneValidation = usePhoneValidation(phone, { country: currentCountry });

    useEffect(() => {
        setShowLoader(true);
        fetchData({
            url: "client/ownership/phone/verify-uniq-beliz",
            method: "POST",
            params: {
                phone: phone.replace(/\D/g, ""),
            },
        }).then((response) => {
            setShowLoader(false);
            if (response?.success) {
                setHasAccount(false);
                setPhoneHelperText('');
            } else {
                setHasAccount(true);
                setPhoneHelperText(t("errors.phone.hasAccount-bz", { phone }));
            }
        });
    }, [phone]);

    const validatePhoneNumber = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!phone) {
            setPhoneHelperText(t('errors.field.emptyBox'));
            return
        }
        if (!phoneValidation.isValid) {
            setPhoneHelperText(t('errors.phone.unacceptableFormat'));
        }
    };

    const validateEmail = () => {
        if (!email) {
          setEmailHelperText(t('errors.field.empty'));
          setIsValidEmail(false);
          return;
        }
        if (!email.match(/^[a-zA-Z0-9@.+\-_]+$/g)) {
          setEmailHelperText(t('errors.email.wrongSymbols'));
          setIsValidEmail(false);
          return;
        }
        setIsValidEmail(true);
    };

    const openConfirmEmailDialog = () => {
        setConfirmEmailDialogOpen(true);
    };

    const closeConfirmEmailDialog = () => {
        setConfirmEmailDialogOpen(false);
    };

    const [defaultEmailCode] = useStoredData<string>('beliz', ['emailConfirmationCode']);
    const [emailCode, setEmailCode] = useState(defaultEmailCode || '');

    const [defaultPhoneCode] = useStoredData<string>('beliz', ['phoneConfirmationCode']);
    const [phoneCode, setPhoneCode] = useState(defaultPhoneCode || '');

    const [confirmPhoneDialogOpen, setConfirmPhoneDialogOpen] = useState(false);

    const [validatingConfidentiallyCheckboxError, setValidatingConfidentiallyCheckboxError] = useState(false);
    const showConfidentiallyCheckboxError = () => setValidatingConfidentiallyCheckboxError(true);

    const openConfirmPhoneDialog = () => {
        setConfirmPhoneDialogOpen(true);
    };

    const closeConfirmPhoneDialog = () => {
        setConfirmPhoneDialogOpen(false);
    };

    const dispatch = useDispatch();

    const setNextForm = () => {
        const fields: fieldForValidation[] = [{ value: name, errorHandler: setNameHelperText, validationType: 'isEmpty' },
        { value: lastName, validationType: 'isEmpty', errorHandler: setLastNameHelperText },
        { value: confidentialPolicy, validationType: 'isChecked', errorHandler: showConfidentiallyCheckboxError },
        { value: emailCode, validationType: 'isConfirmed', errorHandler: setEmailHelperText },
        { value: phoneCode, validationType: 'isConfirmed', errorHandler: setPhoneHelperText }];
        if (formIsValid(fields)) {
            dispatch({
                type: INCREASE_PROVIDER_STEP
            })
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            setNextForm();
        }
    };

    const rememberCheckboxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const optionName = event.target.name;
        dispatch({
            type: STORE_FORM_DATA,
            payload: {
                formName: 'beliz',
                fieldName: optionName,
                fieldValue: event.target.checked
            }
        })
    };

    return (
        <FormWrapper>
            <FormContainer>
                <FormRow>
                    <FieldContainer>
                        <InputLabel htmlFor={'beliz.email'}>{t('step1.email.label', { ns: 'belizForm' })}</InputLabel>
                        <TextField
                            size="small"
                            sx={{ width: 1 }}
                            margin={"normal"}
                            // label="E-mail"
                            placeholder={t('step1.email.placeholder', { ns: 'belizForm' })}
                            type="email"
                            required
                            helperText={emailHelperText}
                            error={!!emailHelperText}
                            value={email}
                            name={'beliz.email'}
                            InputLabelProps={{ shrink: !!email }}
                            disabled={!!emailCode || showLoader || hasAccount}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value);
                                setEmailHelperText('');
                            }}
                            onBlur={validateEmail}
                        />
                        <ButtonComponent
                            text={emailCode ? t('buttons.change') : t('buttons.confirm')}
                            onClick={() => {
                                if (emailCode) {
                                    setEmailCode('');
                                    dispatch({
                                        type: STORE_FORM_DATA,
                                        payload: {
                                            formName: 'beliz',
                                            fieldName: 'emailConfirmationCode',
                                            fieldValue: ''
                                        }
                                    })
                                } else {
                                    openConfirmEmailDialog();
                                }
                            }}
                            minWidth={190}
                            btnMargin={'8px 0 0'}
                            disabled={!email || showLoader || hasAccount ||!isValidEmail}
                        />
                        <ConfirmEmailDialog
                            open={confirmEmailDialogOpen}
                            handleClose={closeConfirmEmailDialog}
                            email={email}
                            setEmailCode={(code: string) => {
                                setEmailCode(code);
                                setEmailHelperText('');
                                dispatch({
                                    type: STORE_FORM_DATA,
                                    payload: {
                                        formName: 'beliz',
                                        fieldName: 'emailConfirmationCode',
                                        fieldValue: code
                                    }
                                });
                            }}
                        />
                    </FieldContainer>
                </FormRow>
                <FormRow>
                    <FieldContainer>
                        <InputLabel htmlFor={'beliz.phone'}>{t('step1.phone.label', { ns: 'belizForm' })}</InputLabel>
                        <MuiPhone
                            value={phone}
                            name={'beliz.phone'}
                            size="small"
                            placeholder={t('step1.phone.placeholder', { ns: 'belizForm' })}
                            helperText={phoneHelperText}
                            onBlur={validatePhoneNumber}
                            disabled={!!phoneCode || hasAccount}
                            onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
                            onChange={(phone, country) => {
                                setPhoneHelperText('');
                                setPhone(phone);
                                setCurrentCountry(country);
                            }}
                        />
                        <ButtonComponent
                            text={phoneCode ? t('buttons.change') : t('buttons.confirm')}
                            onClick={() => {
                                if (phoneCode) {
                                    setPhoneCode('');
                                    dispatch({
                                        type: STORE_FORM_DATA,
                                        payload: {
                                            formName: 'beliz',
                                            fieldName: 'phoneConfirmationCode',
                                            fieldValue: ''
                                        }
                                    });
                                } else {
                                    openConfirmPhoneDialog();
                                }
                            }}
                            minWidth={190}
                            btnMargin={'8px 0 0'}
                            disabled={!phone || !phoneValidation.isValid || showLoader || hasAccount}
                        />
                        <ConfirmPhoneDialog
                            open={confirmPhoneDialogOpen}
                            handleClose={closeConfirmPhoneDialog}
                            phone={phone}
                            setPhoneCode={(code: string) => {
                                setPhoneCode(code);
                                setPhoneHelperText('');
                                dispatch({
                                    type: STORE_FORM_DATA,
                                    payload: {
                                        formName: 'beliz',
                                        fieldName: 'phoneConfirmationCode',
                                        fieldValue: code
                                    }
                                });
                            }}
                        />
                    </FieldContainer>
                </FormRow>
                <FormRow sx={{ gap: '32px' }}>
                    <FieldContainer>
                        <InputLabel htmlFor={'beliz.lastName'}>{t('step1.lastName.label', { ns: 'belizForm' })}</InputLabel>
                        <TextField
                            size="small"
                            sx={{ width: 1 }}
                            margin={"normal"}
                            // label="Фамилия"
                            placeholder={t('step1.lastName.placeholder', { ns: 'belizForm' })}
                            type="text"
                            required
                            helperText={lastNameHelperText}
                            error={!!lastNameHelperText}
                            value={lastName}
                            name={'beliz.lastName'}
                            InputLabelProps={{ shrink: !!lastName }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setLastName(event.target.value);
                                setLastNameHelperText('');
                            }}
                            disabled={showLoader || hasAccount}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <InputLabel htmlFor={'beliz.name'}>{t('step1.name.label', { ns: 'belizForm' })}</InputLabel>
                        <TextField
                            size="small"
                            sx={{ width: 1 }}
                            margin={"normal"}
                            // label="Имя"
                            placeholder={t('step1.name.placeholder', { ns: 'belizForm' })}
                            type="text"
                            required
                            helperText={nameHelperText}
                            error={!!nameHelperText}
                            value={name}
                            InputLabelProps={{ shrink: !!name }}
                            name={'beliz.name'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setName(event.target.value);
                                setNameHelperText('');
                            }}
                            disabled={showLoader || hasAccount}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <InputLabel htmlFor={'beliz.secondName'}>{t('step1.patronymic.label', { ns: 'belizForm' })}</InputLabel>
                        <TextField
                            size="small"
                            sx={{ width: 1 }}
                            margin={"normal"}
                            // label="Отчество (необязательно)"
                            placeholder={t('step1.patronymic.placeholder', { ns: 'belizForm' })}
                            type="text"
                            helperText={secondNameHelperText}
                            error={!!secondNameHelperText}
                            name={'beliz.secondName'}
                            value={secondName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSecondName(event.target.value);
                                setSecondNameHelperText('');
                            }}
                            disabled={showLoader || hasAccount}
                        />
                    </FieldContainer>
                </FormRow>
                <CheckBoxesContainer>
                    <FormControl>
                        <FormControlLabel
                            label={
                                <Typography variant="body1">
                                    {t('step1.agreePersData', { ns: 'belizForm' })}
                                    <Link href="https://ffin.bz/policy/" target="_blank" underline="none">
                                        {t('step1.privacyPolicy', { ns: 'belizForm' })}
                                    </Link>
                                </Typography>
                            }
                            control={<Checkbox
                                name='beliz.confidentialpolicy_checkbox'
                                checked={confidentialPolicy}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    rememberCheckboxValue(event);
                                    setConfidentialPolicy(!confidentialPolicy);
                                    setValidatingConfidentiallyCheckboxError(false);
                                }}
                                disabled={showLoader || hasAccount}
                            />}
                        />
                        {validatingConfidentiallyCheckboxError &&
                            <FormHelperText sx={{ color: 'error.main' }}>{t('errors.consent')}</FormHelperText>
                        }
                    </FormControl>
                    <FormControlLabel
                        label={
                            <Typography variant="body1">
                                {t('step1.agreeInfo', { ns: 'belizForm' })}
                            </Typography>
                        }

                        control={<Checkbox
                            name='beliz.mailingstatus_checkbox'
                            checked={mailingStatus}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                rememberCheckboxValue(event);
                                setMailingStatus(!mailingStatus);
                            }}
                            disabled={showLoader || hasAccount}
                        />}
                    />
                </CheckBoxesContainer>
                <FormRow sx={{ gap: "25px" }}>
                    <ButtonComponent
                        minWidth={125}
                        btnMargin={0}
                        text={t('buttons.next')}
                        onClick={setNextForm}
                        disabled={showLoader || hasAccount}
                    />
                    {hasAccount && (
                        <ButtonComponent
                            minWidth={120}
                            btnMargin={0}
                            text={t("buttons.exit")}
                            onClick={() => {
                                sessionStorage.setItem("token", "");
                                sessionStorage.setItem("phone", "");
                                window.location.reload();
                            }}
                        />
                    )}
                </FormRow>
            </FormContainer>
            <Box sx={{ width: '440px', display: { xs: 'none', md: 'block' } }}>
            </Box>
            <BackdropLoader showLoader={showLoader} />
        </FormWrapper>
    )
}
