import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    Box,
    Checkbox,
    FormControlLabel,
    Link,
    TextField,
    Typography
} from "@mui/material"
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getLocalStoredZaAnketasData,
    getStoredZaAnketasData
} from "../../../redux/storedAnketas/storedAnketasSelectors";
import { countryList } from "../../../utils/countries_list";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { DECREASE_PROVIDER_STEP, INCREASE_PROVIDER_STEP } from "../../../redux/currentPosition/currentPositionTypes";
import { sendFieldToServer } from "../../../utils/common";
import { useTranslation } from "react-i18next";
import { isNewAnketaSelector } from "../../../redux/continueAnketaPopup/continueAnketaPopupSelector";


const countries = countryList.map(item => item.value);

export const ZaTaxResidency = () => {
    const {t} = useTranslation(['translation', 'zaForm']);

    const storedZaData = useSelector(getStoredZaAnketasData);
    const localZaData = useSelector(getLocalStoredZaAnketasData);
    const isNewAnketa = useSelector(isNewAnketaSelector);

    const storedZaFields: { [key: string]: string } = (!isNewAnketa && storedZaData?.data) ? (storedZaData?.data as { [key: string]: string }) : {};

    const defaultTaxResidency = localZaData?.fields['country']?.fieldValue || storedZaFields['country'] || null;
    const [taxResidency, setTaxResidency] = useState<string | null>(defaultTaxResidency);
    const [taxResidencyHelperText, setTaxResidencyHelperText] = useState('');

    const defaultInn = localZaData?.fields['inn']?.fieldValue || storedZaFields['inn'] || '';
    const [inn, setInn] = useState(defaultInn);
    const [innHelperText, setInnHelperText] = useState('');

    const defaultWealth = localZaData?.fields['wealth']?.fieldValue || storedZaFields['wealth'] || '';
    const [wealth, setWealth] = useState<string | null>(defaultWealth);
    const [wealthHelperText, setWealthHelperText] = useState('');

    const defaultIDontRememberInn = localZaData?.fields['IDontRememberInn']?.fieldValue || storedZaFields['IDontRememberInn'] || 'false';
    const isDefaultIDontRememberInn = (defaultIDontRememberInn === 'true');
    const [IDontRememberInn, setIDontRememberInn] = useState(isDefaultIDontRememberInn);

    const [IHaveUsaResidensyCheckbox, setIHaveUsaResidensyCheckBox] = useState(false);

    const defaultIRelatedToPublicPersons = localZaData?.fields['IRelatedToPublicPersons']?.fieldValue || storedZaFields['IRelatedToPublicPersons'] || 'false';
    const isDefaultIRelatedToPublicPersons = (defaultIRelatedToPublicPersons === 'true');
    const [IRelatedToPublicPersons, setIRelatedToPublicPersons] = useState(isDefaultIRelatedToPublicPersons);

    const usaResident = IHaveUsaResidensyCheckbox || taxResidency == 'United States';

    const dispatch = useDispatch();

    const wealthOptions = [`${t('step2.less', {ns: 'zaForm'})} € 50 000`, '€ 50 000 — 100 000', '€ 100 000 — 300 000', '€ 300 000 — 1 000 000', '€ 1 000 000 — 10 000 000', '€ 10 000 000 — 30 000 000', `${t('step2.more', {ns: 'armeniaForm'})} € 30 000 000`]

    useEffect(() => {
        if (storedZaFields && Object.keys(storedZaFields).length && !isNewAnketa){
            if (storedZaFields['country']){
                setTaxResidency(storedZaFields['country'])
            }
            if (storedZaFields['inn']){
                setInn(storedZaFields['inn'])
            }
            if (storedZaFields['wealth']){
                setWealth(storedZaFields['wealth'])
            }
            if (storedZaFields['IDontRememberInn']){
                setIDontRememberInn(storedZaFields['IDontRememberInn'] === 'true')
            }
            if (storedZaFields['IRelatedToPublicPersons']){
                setIRelatedToPublicPersons(storedZaFields['IRelatedToPublicPersons']=== 'true')
            }
        }
    }, [storedZaFields]);
    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const setNextForm = () => {
        if (
            !taxResidency
            || (!IDontRememberInn && !inn)
            || (IRelatedToPublicPersons && !wealth)
        ) {
            if (!taxResidency) {
                setTaxResidencyHelperText(t('errors.field.empty'));
            }
            if (!IDontRememberInn && !inn) {
                setInnHelperText(t('errors.field.empty'));
            }
            if (IRelatedToPublicPersons && !wealth) {
                setWealthHelperText(t('errors.field.empty'));
            }
            return;
        }
        if (!usaResident) {
            dispatch({
                type: INCREASE_PROVIDER_STEP
            })
        }
    }

    return (<Box sx={{display: "flex", flexDirection: "column", gap: '32px'}}>
        <Typography variant="h5">
            {t('step2.title', {ns: 'zaForm'})}
        </Typography>
        <Box sx={{display: "flex", flexDirection: "column", gap: '16px'}}>
            <Autocomplete
                disablePortal
                // size="small"
                options={countries}
                value={taxResidency}
                onChange={(event: SyntheticEvent<Element, Event>, value: string | null, reason: AutocompleteChangeReason) => {
                    setTaxResidency(value)
                    setTaxResidencyHelperText('');
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                    return (<TextField
                        {...params}
                        margin={"normal"}
                        label={t('fields.taxResidency.label')}
                        placeholder={t('fields.taxResidency.placeholder')}
                        name={'za.country'}
                        value={taxResidency}
                        // required
                        helperText={taxResidencyHelperText}
                        error={!!taxResidencyHelperText}
                        InputLabelProps={{}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTaxResidency(event.target.value);
                        }}
                    />)
                }}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                <TextField
                    // size="small"
                    sx={{
                        // width: .51,
                        'display': IDontRememberInn ? 'none' : 'flex'
                    }}
                    margin={"normal"}
                    label={t('fields.inn.label')}
                    placeholder={t('fields.inn.placeholder')}
                    type="text"
                    name={'za.inn'}
                    value={inn}
                    hidden={IDontRememberInn}
                    helperText={innHelperText}
                    error={!!innHelperText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInn(event.target.value)
                        setInnHelperText('');
                    }}
                />
                <FormControlLabel
                    label={t('step2.IDontRememberInn', {ns: 'zaForm'})}
                    control={<Checkbox checked={IDontRememberInn}
                                       name={'za.IDontRememberInn'}
                                       value={IDontRememberInn}
                                       onChange={() => {
                                           setIDontRememberInn(!IDontRememberInn);
                                           setInnHelperText('');
                                       }}/>}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <FormControlLabel
                    label={t('step2.usaResident', {ns: 'zaForm'})}
                    control={<Checkbox checked={IHaveUsaResidensyCheckbox}
                                       onChange={() => setIHaveUsaResidensyCheckBox(!IHaveUsaResidensyCheckbox)}/>}
                />
                <Typography variant="body2">
                    {t('step2.usaResidencyDescription', {ns: 'zaForm'})}
                </Typography>
                {usaResident &&
                  <Typography variant="subtitle2" color={'error.main'}>
                      {t('step2.usaResidentNotAllowed', {ns: 'zaForm'})}
                  </Typography>}
            </Box>
            <FormControlLabel
                label={t('step2.publicFigures', {ns: 'zaForm'})}
                control={<Checkbox checked={IRelatedToPublicPersons}
                                   name={'za.IRelatedToPublicPersons'}
                                   value={IRelatedToPublicPersons}
                                   onChange={() => {
                                       setIRelatedToPublicPersons(!IRelatedToPublicPersons);
                                       setWealthHelperText('');
                                   }}/>}
            />
            {IRelatedToPublicPersons &&
              <Autocomplete
                disablePortal
                options={wealthOptions}
                value={wealth}
                onChange={(event: SyntheticEvent<Element, Event>, value: string | null, reason: AutocompleteChangeReason) => {
                    setWealth(value);
                    setWealthHelperText('');
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={t('step2.amountOfWealth', {ns: 'zaForm'})}
                        name={'za.wealth'}
                        value={wealth}
                        required
                        helperText={wealthHelperText}
                        error={!!wealthHelperText}
                        InputLabelProps={{}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setWealth(event.target.value);
                        }}/>}
              />
            }
            <Box sx={{marginTop: '16px', display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, alignItems: 'center', gap: '48px'}}>
                <Link
                    sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center', gap: '4px', textDecoration: 'none'}}
                    component="button"
                    color="primary.main"
                    variant="subtitle1"
                    onClick={setPrevForm}
                >
                    <div className="armenia_arrow-left-green"></div>
                    <Typography variant="subtitle1" color={'primary.main'}>
                        {t('buttons.back')}
                    </Typography>
                </Link>
                <ButtonComponent minWidth={120} btnMargin={'8px 0 0'} text={t('buttons.next')}
                                 disabled={usaResident}
                                 onClick={setNextForm}/>
            </Box>
        </Box>
    </Box>)
}
