import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material"
import { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../../../redux/storedAnketas/storedAnketasSelectors"
import { TCountry } from "../../../redux/countriesList/countriesListTypes"
import { getCountriesListSelector } from "../../../redux/countriesList/countriesListSelectors"
import { EMPTY_FIELD_TEXT } from "../../../redux/commonTypes";
import {REMOVE_ERROR_FIELD} from "../../../redux/belizErrors/belizErrorsTypes";
import {useTranslation} from "react-i18next";

export const BelizCountryField = ({
    fieldName,
    placeholder="Введите или выберите из списка",
    size='medium',
    required,
    hasError
}: {
    fieldName: string,
    fieldLabel: string,
    placeholder?: string,
    size?: "small" | "medium" | undefined,
    validationRule?: (fieldValue: string) => string | undefined,
    required: boolean,
    errorText?: string,
    hasError?: boolean
}) => {
    const { t } = useTranslation(['translation', 'belizForm']);
    const [taxResidencyHelperText, setTaxResidencyHelperText] = useState('');

    const storedBelizData = useSelector(getStoredBelizAnketasData);

    const storedBelizFields: { [key: string]: string | boolean } = storedBelizData?.data ?? {};

    const bzAnketasData = useSelector(getLocalStoredBelizAnketasData);

    const defaultFieldValue = bzAnketasData?.fields[fieldName]?.fieldValue || storedBelizFields[fieldName] as string || null;
    const [countryValue, setCountryValue] = useState<string | null>(defaultFieldValue ?? null);
    const dispatch = useDispatch();
    const countriesList : TCountry[]  = useSelector(getCountriesListSelector);
    const countriesOptions = countriesList
        ?.filter((country: TCountry) => {
            if (fieldName === "taxresidency") {
                return  country?.alpha3Code !== "USA"
            }
            return country;
        })
        .filter((country: { valueEn: string, risk: { status: string } }) => country?.risk?.status !== "Prohibited")
        .map((country: { valueEn: string, alpha3Code: string }) => ( country.valueEn));

    useEffect(()=>{
        if (required && hasError){
            if (!countryValue){
                setTaxResidencyHelperText(t('errors.field.emptyBox'));
            }
        }
    }, [hasError, countryValue]);

    return (<Autocomplete
        id={fieldName}
        disablePortal
        size={size}
        options={countriesOptions}
        value={countryValue}
        sx={{ width: 1, scrollMarginTop: '4rem' }}
        onChange={(event: SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason) => {
            setCountryValue(value);
            setTaxResidencyHelperText('');
            dispatch({
                type: REMOVE_ERROR_FIELD,
                payload: {
                    errorField: fieldName
                }
            });
        }}
        renderInput={(params) =>
            <TextField
            {...params}
            margin={"normal"}
            label={''}
            placeholder={t('placeholders.enterOrSelect', {ns: 'belizForm'})}
            name={`beliz.${fieldName}`}
            value={countryValue}
            required
            helperText={taxResidencyHelperText}
            error={!!taxResidencyHelperText}
            InputLabelProps = {{}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCountryValue(event.target.value);
            }} />}
    />)
}
