import {MainForm} from "./MainForm/MainForm"
import {AppHeader} from "../../components/AppHeader/AppHeader"
import {AppFooter} from "../../components/AppFooter/AppFooter"
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {useSelector} from "react-redux";
import {getCurrentPage, getProvider} from "../../redux/currentPosition/currentPositionSelector";
import {TProviderType} from "../../redux/currentPosition/currentPositionTypes";
import {AppHeaderBeliz} from "../../components/AppHeaderBeliz/AppHeaderBeliz";
import {AppFooterBeliz} from "../../components/AppFooterBeliz/AppFooterBeliz";
import {getProviderFromURL} from "../../utils/common";

const mainTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: '#65DCB6',
            contrastText: 'black',
        },
    },
    typography: {
        "fontFamily": `"GT America LCG", Arial, sans-serif`,
        "fontSize": 11,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const belizTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 981,
            lg: 1200,
            xl: 1536,
        },
    },
    shape: {
        borderRadius: 8,
    },
    palette: {
        primary: {
            main: '#0186FF',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#2A3E5C',
        },
        error: {
            main: '#FD3564',
        },
        text: {
            primary: '#2A3E5C',
            secondary: '#AEBECC',
            disabled: '#647294',
        },
        background: {
            default: '#F4F8FF',
            paper: '#FFFFFF',
        },
    },
    typography: {
        "fontFamily": `"GT America LCG", Arial, sans-serif`,
        "fontSize": 16,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h1: {
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '30px',
            '@media (min-width:981px)': {
                fontSize: '38px',
                lineHeight: '46px',
            },
        },
        h3: {
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
            '@media (min-width:981px)': {
                fontSize: '26px',
                lineHeight: '32px',
            },
        },
        h4: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            '@media (min-width:981px)': {
                fontSize: '24px',
                lineHeight: '28px',
            },
        },
        h5: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '22px',
            '@media (min-width:981px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
        },
        body1: {
            // color: '#2A3E5C',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            '@media (min-width:981px)': {
                fontSize: '20px',
            },
        },
        body2: {
            color: '#647294',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        subtitle1: {
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        subtitle2: {
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
        },
        caption: {
            // color: '#AEBECC',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            '@media (min-width:981px)': {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        button: {
            textTransform: 'none'
        },
    }
}, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '52px',
                    padding: '14px 32px',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    '@media (min-width:700px)': {
                        width: 'fit-content',
                        height: '56px',
                        padding: '16px 32px',
                        borderRadius: '8px',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                    "&.MuiButton-outlined": {
                        border: '2px solid',
                    },
                    "&.Mui-disabled": {
                        background: "#E1E6ED",
                        color: "#647294"
                    },
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#2A3E5C',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    whiteSpace: 'wrap',
                    '@media (min-width:981px)': {
                        fontSize: '20px',
                    },
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#2A3E5C',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    whiteSpace: 'wrap',
                    '@media (min-width:981px)': {
                        fontSize: '20px',
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: '16px 0 24px',
                    borderRadius: '8px',
                }
            }

        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    maxHeight: 48,
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    borderRadius: '8px',
                    '@media (min-width:981px)': {
                        paddingTop: '16.5px',
                        paddingBottom: '16.5px',
                        maxHeight: 60,
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                    },
                    "&.MuiOutlinedInput": {
                        padding: '10.5px 14px',
                        borderRadius: '8px',
                    },
                    "&.MuiAutocomplete": {
                        maxWidth: 516,
                        maxHeight: 60,
                        borderRadius: '8px',
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    maxHeight: '24px',
                    '@media (min-width:981px)': {
                        maxHeight: '24px',
                        paddingTop: '18px',
                        paddingBottom: '18px',
                        fontSize: '20px',
                    },
                },
                root: {
                    alignContent: 'center',
                    "&.MuiAutocomplete-input": {
                        paddingTop: '12.5px',
                        paddingBottom: '12.5px',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        maxHeight: '24px',
                        '@media (min-width:981px)': {
                            maxHeight: '24px',
                            paddingTop: '18px',
                            paddingBottom: '18px',
                            fontSize: '20px',
                        },
                    }

                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    paddingTop: '12.5px',
                    paddingBottom: '12.5px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    maxHeight: '24px',
                    '@media (min-width:981px)': {
                        maxHeight: '24px',
                        paddingTop: '18px',
                        paddingBottom: '18px',
                        fontSize: '20px',
                    },
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    "&.MuiTextField-root": {
                        margin: 0,
                    },
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                    alignItems: 'flex-start',
                    gap: '16px',
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    width: '24px',
                    height: '24px',
                    // padding: '0 9px',
                    // alignSelf: 'flex-start',
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    width: '24px',
                    height: '24px',
                    // padding: '0 9px',
                    // alignSelf: 'flex-start',
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    // '@media (min-width:640px)': {
                    //     padding: '0 64px 0 0',
                    // },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiDialog-paper": {
                        maxWidth: '712px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '24px',
                        background: '#FFFFFF',
                        '@media (min-width:640px)': {
                            padding: '24px 64px 40px',
                            borderRadius: '24px',
                            gap: '40px',
                        },
                    },
                    "&.MuiOutlinedInput-input": {
                        maxHeight: '64px',
                    },

                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiDialogTitle-root": {
                        padding: 0,
                        marginTop: '40px',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '28px',
                        '@media (min-width:640px)': {
                            marginTop: '56px',
                            fontSize: '26px',
                            lineHeight: '32px',
                        },
                    },
                    "&.MuiDialogContentText-root": {
                        padding: 0,
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '24px',
                        '@media (min-width:640px)': {
                            fontSize: '20px',
                        },
                    },
                }
            }
        }
    }
});

const armeniaTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    shape: {
        borderRadius: 4,
    },
    palette: {
        primary: {
            main: '#72BF44',
            contrastText: '#FFFFFF',
        },

        secondary: {
            main: '#A2A2A2',
        },
        error: {
            main: '#E97070',
        },
        text: {
            primary: '#242624',
            secondary: '#A2A2A2',
            disabled: '#A2A2A2',
        },
        background: {
            default: '#FFF',
            paper: '#F6F6F6'
        }
    },
    typography: {
        "fontFamily": `"GT America LCG", Arial, sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h1: {
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '28px',
            '@media (min-width:640px)': {
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '48px',
            },
        },
        h4: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '28px',
            },
        },
        h5: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                lineHeight: '28px',
            },
        },
        h6: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
        },
        subtitle1: {
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: 18,
                lineHeight: '24px',
            },
        },
        subtitle2: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
        },
        body1: {
            color: '#242624',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        body2: {
            color: '#A2A2A2',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            '@media (min-width:640px)': {
                fontSize: 14,
            },
        },
        caption: {
            color: '#6B6B6B',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        button: {
            textTransform: 'none',
            fontSize: '18px',
        },

    },
}, {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiLink-root": {
                        // color: '#242624',
                        fontFamily: `"GT America LCG", Arial, sans-serif`,
                        // fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        // lineHeight: '16px',
                    },
                    "&.MuiFormControlLabel": {
                        styleOverrides: {
                            root: {
                                fontSize: '18px',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                lineHeight: '18px',
                            },
                        }
                    },
                    "&.MuiDialogTitle-root": {
                        padding: 0,
                        marginTop: '32px',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '28px',
                        '@media (min-width:640px)': {
                            margin: 0,
                            fontSize: '40px',
                            lineHeight: '48px',
                            textAlign: 'center',
                            alignSelf: 'center',
                        },
                    },
                    "&.MuiDialogContentText-root": {
                        padding: 0,
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#242624',
                        '@media (min-width:640px)': {
                            maxWidth: '424px',
                            color: '#24303B',
                            textAlign: 'center', 
                            fontSize: '18px',
                            lineHeight: '28px',
                            alignSelf: 'center',
                        },
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '44px',
                    padding: '12px 24px',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    '@media (min-width:700px)': {
                        width: 'fit-content',
                        // width: '100%',
                        height: '56px',
                        padding: '16px 32px',
                        borderRadius: '4px',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                    "&.MuiButton-outlined": {
                        border: '2px solid',
                    },
                    "&.Mui-disabled": {
                        background: "rgba(114, 191, 68, 0.35)",
                        color: "#FFF"
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 9px',
                    alignSelf: 'flex-start',
                    // color: '#72BF44',
                    // "&.Mui-checked": {
                    //     color: '#FFFFFF',
                    // },
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // maxHeight: 56,
                    // padding: '8px 20px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '20px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    "&.MuiOutlinedInput": {
                        borderRadius: '4px',
                    },
                    "&.MuiAutocomplete": {
                        maxHeight: 56,
                        borderRadius: '4px',
                    },
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: '#72BF44',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#A2A2A2',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0',
                    marginTop: '8px',
                    fontSize: '14px'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginTop: '8px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    alignItems: 'flex-start',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                },
                label: {
                    paddingTop: '2px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        padding: '8px 12px',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    },
                    "&.Mui-selected": {
                        // color: '#65DCB6',
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '32px',
                    overflow: 'visible',
                    '@media (min-width:640px)': {
                        gap: '48px',
                    },
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 0,                    
                    '@media (max-width:640px)': {
                        width: '100%',
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiDialog-paper": {                        
                        padding: '16px 16px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        background: '#FFFFFF',
                        '@media (min-width:640px)': {
                            maxWidth: '668px',
                            padding: '56px',
                            borderRadius: '16px',
                            gap: '24px',
                        },
                    },
                    "&.MuiOutlinedInput-input": {
                        maxHeight: '64px',
                    },

                }
            }
        },
    }
});


const zaTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    shape: {
        borderRadius: 4,
    },
    palette: {
        primary: {
            main: '#72BF44',
            contrastText: '#FFFFFF',
        },

        secondary: {
            main: '#A2A2A2',
        },
        error: {
            main: '#E97070',
        },
        text: {
            primary: '#242624',
            secondary: '#A2A2A2',
            disabled: '#A2A2A2',
        },
        background: {
            default: '#FFF',
            paper: '#F6F6F6'
        }
    },
    typography: {
        "fontFamily": `"GT America LCG", Arial, sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h1: {
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '28px',
            '@media (min-width:640px)': {
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '48px',
            },
        },
        h4: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '28px',
            },
        },
        h5: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                lineHeight: '28px',
            },
        },
        h6: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
        },
        subtitle1: {
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: 18,
                lineHeight: '24px',
            },
        },
        subtitle2: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
        },
        body1: {
            color: '#242624',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        body2: {
            color: '#A2A2A2',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            '@media (min-width:640px)': {
                fontSize: 14,
            },
        },
        caption: {
            color: '#6B6B6B',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        button: {
            textTransform: 'none',
            fontSize: '18px',
        },

    },
}, {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiLink-root": {
                        // color: '#242624',
                        fontFamily: `"GT America LCG", Arial, sans-serif`,
                        // fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        // lineHeight: '16px',
                    },
                    "&.MuiFormControlLabel": {
                        styleOverrides: {
                            root: {
                                fontSize: '18px',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                lineHeight: '18px',
                            },
                        }
                    },
                    "&.MuiDialogTitle-root": {
                        padding: 0,
                        marginTop: '32px',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '28px',
                        '@media (min-width:640px)': {
                            margin: 0,
                            fontSize: '40px',
                            lineHeight: '48px',
                            textAlign: 'center',
                            alignSelf: 'center',
                        },
                    },
                    "&.MuiDialogContentText-root": {
                        padding: 0,
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#242624',
                        '@media (min-width:640px)': {
                            maxWidth: '424px',
                            color: '#24303B',
                            textAlign: 'center', 
                            fontSize: '18px',
                            lineHeight: '28px',
                            alignSelf: 'center',
                        },
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '44px',
                    padding: '12px 24px',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    '@media (min-width:700px)': {
                        width: 'fit-content',
                        // width: '100%',
                        height: '56px',
                        padding: '16px 32px',
                        borderRadius: '4px',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                    "&.MuiButton-outlined": {
                        border: '2px solid',
                    },
                    "&.Mui-disabled": {
                        background: "rgba(114, 191, 68, 0.35)",
                        color: "#FFF"
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 9px',
                    alignSelf: 'flex-start',
                    // color: '#72BF44',
                    // "&.Mui-checked": {
                    //     color: '#FFFFFF',
                    // },
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // maxHeight: 56,
                    // padding: '8px 20px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '20px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    "&.MuiOutlinedInput": {
                        borderRadius: '4px',
                    },
                    "&.MuiAutocomplete": {
                        maxHeight: 56,
                        borderRadius: '4px',
                    },
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: '#72BF44',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#A2A2A2',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0',
                    marginTop: '8px',
                    fontSize: '14px'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginTop: '8px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    alignItems: 'flex-start',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                },
                label: {
                    paddingTop: '2px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        padding: '8px 12px',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    },
                    "&.Mui-selected": {
                        // color: '#65DCB6',
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '32px',
                    overflow: 'visible',
                    '@media (min-width:640px)': {
                        gap: '48px',
                    },
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 0,                    
                    '@media (max-width:640px)': {
                        width: '100%',
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiDialog-paper": {                        
                        padding: '16px 16px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        background: '#FFFFFF',
                        '@media (min-width:640px)': {
                            maxWidth: '668px',
                            padding: '56px',
                            borderRadius: '16px',
                            gap: '24px',
                        },
                    },
                    "&.MuiOutlinedInput-input": {
                        maxHeight: '64px',
                    },

                }
            }
        },
    }
});

const ruTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    shape: {
        borderRadius: 4,
    },
    palette: {
        primary: {
            main: '#72BF44',
            contrastText: '#FFFFFF',
        },

        secondary: {
            main: '#A2A2A2',
        },
        error: {
            main: '#E97070',
        },
        text: {
            primary: '#242624',
            secondary: '#A2A2A2',
            disabled: '#A2A2A2',
        },
        background: {
            default: '#FFF',
            paper: '#F6F6F6'
        }
    },
    typography: {
        "fontFamily": `"GT America LCG", Arial, sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        h1: {
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '28px',
            '@media (min-width:640px)': {
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '48px',
            },
        },
        h4: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '28px',
            },
        },
        h5: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            '@media (min-width:640px)': {
                fontSize: '24px',
                lineHeight: '28px',
            },
        },
        h6: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: '20px',
                lineHeight: '24px',
            },
        },
        subtitle1: {
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            '@media (min-width:640px)': {
                fontSize: 18,
                lineHeight: '24px',
            },
        },
        subtitle2: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '16px',
        },
        body1: {
            color: '#242624',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        body2: {
            color: '#A2A2A2',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
            '@media (min-width:640px)': {
                fontSize: 14,
            },
        },
        caption: {
            color: '#6B6B6B',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
        button: {
            textTransform: 'none',
            fontSize: '18px',
        },

    },
}, {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.MuiLink-root": {
                        // color: '#242624',
                        fontFamily: `"GT America LCG", Arial, sans-serif`,
                        // fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        // lineHeight: '16px',
                    },
                    "&.MuiFormControlLabel": {
                        styleOverrides: {
                            root: {
                                fontSize: '18px',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                lineHeight: '18px',
                            },
                        }
                    },
                    "&.MuiDialogTitle-root": {
                        padding: 0,
                        marginTop: '32px',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '28px',
                        '@media (min-width:640px)': {
                            margin: 0,
                            fontSize: '40px',
                            lineHeight: '48px',
                            textAlign: 'center',
                            alignSelf: 'center',
                        },
                    },
                    "&.MuiDialogContentText-root": {
                        padding: 0,
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#242624',
                        '@media (min-width:640px)': {
                            maxWidth: '424px',
                            color: '#24303B',
                            textAlign: 'center', 
                            fontSize: '18px',
                            lineHeight: '28px',
                            alignSelf: 'center',
                        },
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '44px',
                    padding: '12px 24px',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                    '@media (min-width:700px)': {
                        width: 'fit-content',
                        // width: '100%',
                        height: '56px',
                        padding: '16px 32px',
                        borderRadius: '4px',
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                    },
                    "&.MuiButton-outlined": {
                        border: '2px solid',
                    },
                    "&.Mui-disabled": {
                        background: "rgba(114, 191, 68, 0.35)",
                        color: "#FFF"
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 9px',
                    alignSelf: 'flex-start',
                    // color: '#72BF44',
                    // "&.Mui-checked": {
                    //     color: '#FFFFFF',
                    // },
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // maxHeight: 56,
                    // padding: '8px 20px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '20px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                    "&.MuiOutlinedInput": {
                        borderRadius: '4px',
                    },
                    "&.MuiAutocomplete": {
                        maxHeight: 56,
                        borderRadius: '4px',
                    },
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#FFFFFF",
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: '#72BF44',
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#A2A2A2',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        lineHeight: '24px'
                    },
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '0',
                    marginTop: '8px',
                    fontSize: '14px'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginTop: '8px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    alignItems: 'flex-start',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                },
                label: {
                    paddingTop: '2px',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    '@media (min-width:640px)': {
                        fontSize: '18px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '18px'
                    },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '@media (max-width:640px)': {
                        padding: '8px 12px',
                        height: 'fit-content',
                        minHeight: 'fit-content',
                        fontSize: '14px',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        lineHeight: '16px'
                    },
                    "&.Mui-selected": {
                        // color: '#65DCB6',
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '32px',
                    overflow: 'visible',
                    '@media (min-width:640px)': {
                        gap: '48px',
                    },
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 0,                    
                    '@media (max-width:640px)': {
                        width: '100%',
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiDialog-paper": {                        
                        padding: '16px 16px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        background: '#FFFFFF',
                        '@media (min-width:640px)': {
                            maxWidth: '668px',
                            padding: '56px',
                            borderRadius: '16px',
                            gap: '24px',
                        },
                    },
                    "&.MuiOutlinedInput-input": {
                        maxHeight: '64px',
                    },

                }
            }
        },
    }
});

const getTheme = (provider: TProviderType) => {
    switch (provider) {
        case TProviderType.beliz:
            return belizTheme;
        case TProviderType.armenia:
            return armeniaTheme;
        case TProviderType.za:
            return zaTheme;  
        case TProviderType.ru:
            return ruTheme;                    
        default:
            return mainTheme;
    }
}

export const MainHelpersAppForm = () => {
    const currentPage = useSelector(getCurrentPage);

    const headers = {
        'none': AppHeader,
        'beliz': AppHeaderBeliz,
        // 'armenia': AppHeaderArmenia,
        'armenia': null,
        'za' : null,
        'ru' : null
    };
    const footers = {
        'none': AppFooter,
        'beliz': currentPage < 2 ? null : AppFooterBeliz,
        // 'armenia': AppFooterArmenia,
        'armenia': null,
        'za' : null,
        'ru' : null
    };

    const directLinkProvider = getProviderFromURL() as TProviderType;
    const currentProvider = useSelector(getProvider);
    const provider = directLinkProvider || currentProvider;

    const currentTheme = getTheme(provider);

    const Header = headers[provider] ?? (() => (<></>));
    const Footer = footers[provider] ?? (() => (<></>));


    return (
        <ThemeProvider theme={currentTheme}>
            <Box sx={{height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Header/>
                <MainForm/>
                <Footer/>
            </Box>
        </ThemeProvider>
    )
}
