export const SET_FIELD_VISIBILITY = 'SET_FIELD_VISIBILITY';
export type TSetFieldVisibilityAction = {
    type : typeof SET_FIELD_VISIBILITY,
    payload: {
        fieldName: string,
        visible: boolean
    }
}

export type TVisibilityAction = TSetFieldVisibilityAction;

export type TFieldsVisibilityState = {
    [fieldName : string] : {
        visible : boolean
    }
}