import { useState, useEffect } from 'react';
import {Typography, TypographyTypeMap} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";

export const TimerComponent = ({
    initialMinute = 0,
    initialSeconds = 0,
    setExpired,
    variant,
    intervalId,
    needClearTimer,
    color
}: {
    initialMinute?: number,
    initialSeconds?: number,
    setExpired?: () => void,
    variant?: Variant,
    intervalId?: string | number | NodeJS.Timeout | undefined
    needClearTimer?: boolean
    color?: string,
}) => {

    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {
        if (!needClearTimer){
            intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(intervalId);
                        setExpired?.();
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
            }, 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    });

    return (
      <Typography
        sx={{ width: "32px", textAlign: "center", flexShrink: 0 }}
        variant={variant}
        color={color}
      >
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    );
}
