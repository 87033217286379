import { Box, Button, TextField, styled } from "@mui/material"
import { useState } from "react";
import { EMPTY_FIELD_TEXT } from "../../redux/commonTypes";
import { fetchData } from "../../utils/fetch";
import { AUTHORIZE_ADMIN } from "../../redux/adminData/adminDataTypes";
import { useDispatch } from "react-redux";

const AdminBackground = styled(Box)`
    width: 100%;
    height: 100%;
    background: lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
}
`
const LoginBox = styled(Box)`
    width: 30%;
    height: 30%;
    display: flex;
    flex-direction: column;
`


export const AdminLoginForm = ()=>{
    const [login, setLogin] = useState('');
    const [loginHelperText, setLoginHelperText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordHelperText, setPasswordHelperText] = useState('');

    const dispatch = useDispatch();

    const submitForm = async () => {
        if (!login || !password) {
            if (!login) {
                setLoginHelperText(EMPTY_FIELD_TEXT);
            }
            if (!password) {
                setPasswordHelperText(EMPTY_FIELD_TEXT);
            }
            return;
        }

        const response = await fetchData({
            url: 'auth/admin/login',
            method: 'POST',
            params: {
                login,
                password
            }
        })
        if (response.success){
            sessionStorage.setItem('admin_token', response?.data?.access_token);
            dispatch({
                type: AUTHORIZE_ADMIN
            })
        }

    }
    
    return (<AdminBackground>
        <LoginBox>
        <TextField
            size="small"
            margin={"normal"}
            label="Логин"
            placeholder="Логин"
            helperText={loginHelperText}
            error={!!loginHelperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setLogin(event.target.value);
                setLoginHelperText('');
            }}
            required
            type="input"
        />
        <TextField
            size="small"
            margin={"normal"}
            label="Пароль"
            placeholder="Пароль"
            required
            type="password"
            helperText={passwordHelperText}
            error={!!passwordHelperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
                setPasswordHelperText('');
            }}
        />
        <Button onClick = {submitForm}>Войти</Button>
        </LoginBox>
       </AdminBackground>)
}