import { Box, LinearProgress, Typography, styled } from "@mui/material";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

const StepsHeader = styled(Box)`
  color: #242624;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  padding: 0;
  gap: 8px;
//   height: 40px;
  margin: 10px 0;
`

const StepsSubHeaderText = styled(Typography)`
  //font-family: GT America LCG;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`

const StepsHeaderText = styled(Typography)`
  //font-family: GT America LCG;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`

export const LinearSteps = ({ activeIndex, curHeaderText, nextHeaderText }:
  { activeIndex: number, curHeaderText: string, nextHeaderText: string }) => {
  const { t } = useTranslation();

  return (activeIndex ? <Box>
    <StepsHeader>
      <StepsHeaderText>{t('steps.step')} {activeIndex}</StepsHeaderText>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
        <StepsSubHeaderText>{curHeaderText}</StepsSubHeaderText>
        <StepsSubHeaderText sx={{ color: "#A2A2A2", textAlign: "end" }}>{nextHeaderText}</StepsSubHeaderText>
      </Box>

    </StepsHeader>
    <LinearProgress variant="determinate" value={activeIndex * 25} />
  </Box> : <></>)

}
