import { getProviderFromURL } from "../../utils/common";
import { TCurrentPositionAction, TCurrentPositionState, SET_PROVIDER, TProviderType, MOVE_TO_NEXT_PAGE, MOVE_TO_PREV_PAGE, MOVE_TO_PAGE, MOVE_TO_PREV_STEP, MOVE_TO_NEXT_STEP, MOVE_TO_STEP,SET_INTERMEDIATE_STEP, TIntermediateStep } from "./currentPositionTypes";

const defaultPage = sessionStorage.getItem('token') ? (getProviderFromURL() ? 2 : 1) : 0;
const defaultProvider = getProviderFromURL();
const defaultStep = sessionStorage.getItem('token') ? 0 : 1;

const defaultState = {
    currentPage: defaultPage,
    lastPage: defaultPage,
    providerStep: defaultStep,
    provider: (defaultProvider && defaultProvider in TProviderType) ? defaultProvider as TProviderType : TProviderType.none,
    currentIntermediateStep: TIntermediateStep.ENTER_PHONE
}

export const currentPositionReducer = (state: TCurrentPositionState = defaultState, action: TCurrentPositionAction) => {
    switch (action.type) {
        case MOVE_TO_NEXT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1,
                lastPage: state.currentPage + 1 >= state.lastPage ? state.currentPage + 1 : state.lastPage,
            }
        case MOVE_TO_PREV_PAGE:
            return {
                ...state,
                currentPage: state.currentPage - 1,
                provider: (state.currentPage > 2 ? state.provider : getProviderFromURL() ?? TProviderType.none),
            }
        case MOVE_TO_PAGE:
            return {
                ...state,
                currentPage: action.payload.page,
                lastPage: action.payload.page > state.lastPage ? action.payload.page : state.lastPage,
                provider: (action.payload.page > 1 ? state.provider : getProviderFromURL() ?? TProviderType.none),
            }
        case MOVE_TO_PREV_STEP:
            return {
                ...state,
                providerStep: state.providerStep - 1
            }
        case MOVE_TO_NEXT_STEP:
            return {
                ...state,
                providerStep: state.providerStep + 1
            }
        case MOVE_TO_STEP:
            return {
                ...state,
                providerStep: action.payload.step
            }
        case SET_PROVIDER: 
            return {
                ...state,
                provider: action.payload.provider
            }
        case SET_INTERMEDIATE_STEP:
            return {
                ...state,
                currentIntermediateStep: action.payload
            }
        default: {
            return state;
        }
    }
}
