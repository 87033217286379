interface TealiumData {
  [key: string]: any;
}

export const trackEventTealium = (
  data: TealiumData,
  eventType: "link" | "view" = "link"
) => {
  if (process.env.NODE_ENV !== "development" && window.utag) {
    if (eventType === "link") {
      window.utag.link(data);
    } else {
      window.utag.view(data);
    }
  }
};
