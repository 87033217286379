import {Box, styled, Typography, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"
import {ButtonComponent} from "../../../components/Buttons/ButtonComponent"
import {ButtonsContainer, FormContainer} from "../../../components/FieldContainer/FieldContainer"
import {
    getContinueAnketaPopupIsOpen,
    getContinueAnketaUserName} from "../../../redux/continueAnketaPopup/continueAnketaPopupSelector"
import {
    CONTINUE_FILLING_ANKETA} from "../../../redux/continueAnketaPopup/continueAnketaPopupTypes"
import {useTranslation} from "react-i18next";
import { getProvider } from "../../../redux/currentPosition/currentPositionSelector"
import {belizFormContainer,formContainerStyles} from "../RegistrationForm/LoginByPhoneForm";
import { newAnketa } from "../../../utils/anketaActions";

const PopupText = styled('div')`
  max-width: 634px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  @media (min-width: 640px) {
    line-height: 28px;
  }
`

const PopupWrapper = styled('div')`
  width: 100%;
  padding: 0 1.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  @media (min-width: 640px) {
    width: 500px;
  }
`
export const StartNewOrContinueOldAnketaPopup = () => {
    const {t} = useTranslation();
    const isOpen = useSelector(getContinueAnketaPopupIsOpen);
    const theme = useTheme();
    const name = useSelector(getContinueAnketaUserName);
    const provider = useSelector(getProvider);
    const isExistsAnketa = sessionStorage.getItem('isExistsAnketa');
    const dispatch = useDispatch();

    const handleNewAnketa = () => {
    	sessionStorage.removeItem('isExistsAnketa');
        newAnketa(provider, dispatch);
    };

    const existingAnketa = () => {
    	sessionStorage.removeItem('isExistsAnketa');
        dispatch({
            type: CONTINUE_FILLING_ANKETA
        });
    }
    return (
        isOpen || isExistsAnketa ? (
                <FormContainer
                    className="FormContainer"
                    sx={formContainerStyles[provider] || belizFormContainer} 
                    bgcolor={{sm: theme.palette.background.paper}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                        <Typography variant="h4">
                            {t('popup.newFormOrContinue.title')}{name ? `, ${name }` : ''}!
                        </Typography>
                        <PopupText>{t('popup.newFormOrContinue.question')}</PopupText>
                    </Box>
                    <ButtonsContainer>
                        <ButtonComponent text={t('buttons.continue')} onClick={existingAnketa} btnMargin={0}/>
                        <ButtonComponent
                            text={t('buttons.startOver')}
                            onClick={handleNewAnketa}
                            btnMargin={0}
                            fontWeight='400 !important'
                            btnColor='#0E0E0E'
                            btnBackgroundColor='#FFFFFF'
                        />
                    </ButtonsContainer>
                </FormContainer>
            )
            : <Box sx={{minHeght: '404px'}} bgcolor={{sm: theme.palette.background.paper}}></Box>)
}
