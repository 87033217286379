import { put, select, takeLatest } from "redux-saga/effects";
import { STORE_FORM_DATA, TAnketa, TStoreFormDataAction } from "../redux/userData/userDataTypes";
import { TApplicationState } from "../redux/rootReducer";
import { TCountry } from "../redux/countriesList/countriesListTypes";
import { getLocalStoredBelizAnketasData, getStoredBelizAnketasData } from "../redux/storedAnketas/storedAnketasSelectors";
import { getCountryRiskByValue } from "../utils/common";
import { TEstimatedYearInvestment } from "../forms/MainHelpersApp/BelizForm/BelizConfigTypes";
import { SET_FIELD_VISIBILITY } from "../redux/fieldsVisibility/fieldsVisibilityTypes";
import { TStoredAnketa } from "../redux/storedAnketas/storedAnketasTypes";

const isNeedAConfirmationDocument = (taxResidencyRisk: string = "Normal Risk", estimatedyearinvestment: TEstimatedYearInvestment) => {
    if ((taxResidencyRisk === "Low Risk" && estimatedyearinvestment === TEstimatedYearInvestment.morethan100k) ||
    ((taxResidencyRisk === "Normal Risk" || taxResidencyRisk === "High Risk") && (estimatedyearinvestment === TEstimatedYearInvestment.morethan100k || estimatedyearinvestment === TEstimatedYearInvestment.from50kto100k))){
        return true;
    }
    return false;
}

function* checkFieldValues(action: TStoreFormDataAction){
    if(action?.payload?.fieldName === 'taxresidency' || action?.payload?.fieldName === 'estimatedyearinvestment'){
        const countries: TCountry[] = yield select((s:TApplicationState)=>s.usersAnketa.countriesData.countriesList);
        const anketaData: TAnketa|null = yield select(getLocalStoredBelizAnketasData);
        const serverAnketaData: TStoredAnketa | undefined = yield select(getStoredBelizAnketasData);
        const taxResidency = anketaData?.fields['taxresidency']?.fieldValue || serverAnketaData?.data['taxresidency'] as string;
        const estimatedyearinvestment = (anketaData?.fields['estimatedyearinvestment']?.fieldValue || serverAnketaData?.data['estimatedyearinvestment']) as TEstimatedYearInvestment;
        const risk = getCountryRiskByValue(taxResidency??'', countries);
        const needADoc = isNeedAConfirmationDocument(risk, estimatedyearinvestment);
        yield put({
            type : SET_FIELD_VISIBILITY,
            payload: {
                fieldName: 'attachFilesWidget',
                visible: needADoc
            }
        })
    }
}

function* validateFieldSaga() {
    yield takeLatest(STORE_FORM_DATA, checkFieldValues);
}

export default validateFieldSaga;
