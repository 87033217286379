import React, {useState} from "react";
import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

type TPasswordFieldProps = {
  value: string;
  name: string;
  label?: string;
  margin?: string;
  width?: string;
  placeholder?: string;
  error: boolean;
  helperText: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const PasswordField = (
	{
		value,
		name,
		label,
		placeholder,
		margin,
		width,
		error,
		helperText,
		disabled,
		onChange,
	}: TPasswordFieldProps
) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<FormControl
			// size="small"
			sx={{ width: width, margin: margin }}
			required
			variant="outlined">
			<InputLabel htmlFor={`password-${name}`}>{label}</InputLabel>
			<OutlinedInput
				id={`password-${name}`}
				name={name}
				type={showPassword ? 'text' : 'password'}
				value={value}
				error={!!helperText}
				disabled={disabled}
				onChange={onChange}
				autoComplete="off"
				inputProps={{ autoComplete: "off" }}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowPassword(!showPassword)}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label={label}
				placeholder={placeholder}
			/>
			{error && <FormHelperText error={!!helperText} id={`password-${name}-helper-text`}>{helperText}</FormHelperText>}
		</FormControl>
	);
};
