import TagManager from 'react-gtm-module';

interface GTMArgs {
  gtmId: string;
}

const GTMProviders: { [key: string]: GTMArgs } = {
  armenia: { gtmId: 'GTM-MWPHPBKM' },
};

export const initializeGTM = (provider: string) => {
  const tagManagerArgs = GTMProviders[provider];
  if (tagManagerArgs) {
    TagManager.initialize(tagManagerArgs);
  }
};
