import { all, fork } from "redux-saga/effects";
import keepUserDataSaga from "./keepUserDataSaga";
import getCountriesListSaga from "./getCountriesList";
import validateFieldSaga from "./validateField";
import sendUTMParamsSaga from "./sendUTMParams";
import getBlobDocumentsSaga from "./getBlobDocumentsSaga";


export function* rootSaga() {
  yield all([
    fork(keepUserDataSaga),
    fork(getCountriesListSaga),
    fork(validateFieldSaga),
    fork(sendUTMParamsSaga),
    fork(getBlobDocumentsSaga)
  ]);
}