import { TProviderType } from "../currentPosition/currentPositionTypes";
import { TApplicationState } from "../rootReducer";

export const getSumSubToken = (state : TApplicationState ) =>{
    return state.usersAnketa.sumSubData.token
}

export const getSumSubBzToken = (state : TApplicationState ) =>{
    return state.usersAnketa.sumSubData.bzToken
}

export const getSID = (state : TApplicationState ) =>{
    return state.usersAnketa.sumSubData.SID
}

export const getPhoneId = (state : TApplicationState ) =>{
    return state.usersAnketa.sumSubData.phoneId
}

export const getSumSubArmeniaStatus = (state : TApplicationState ) =>{
    if (state.usersAnketa.sumSubData.provider === TProviderType.armenia){
        return state.usersAnketa.sumSubData.status;
    }
    return;
}

export const getSumSubZaStatus = (state : TApplicationState ) =>{
    if (state.usersAnketa.sumSubData.provider === TProviderType.za){
        return state.usersAnketa.sumSubData.status;
    }
    return;
}

export const getSumSubRuStatus = (state : TApplicationState ) =>{
    if (state.usersAnketa.sumSubData.provider === TProviderType.ru){
        return state.usersAnketa.sumSubData.status;
    }
    return;
}

export const getSumSubBelizStatus = (state : TApplicationState ) =>{
    if (state.usersAnketa.sumSubData.provider === TProviderType.beliz){
        return state.usersAnketa.sumSubData.status;
    }
    return;
}