import { TApplicationState } from "../rootReducer";

export const getFieldHasError = (state: TApplicationState, fieldName: string) => state.usersAnketa.belizFormErrors ?? false;

export const getBelizFormErrors = (state : TApplicationState ) => {
  return state.usersAnketa.belizFormErrors ?? {};
}

export const getBelizErrorFields = (state : TApplicationState ) => {
  return state.usersAnketa.belizFormErrors.errorFields ?? [];
}
