import { SET_COUNTRIES_LIST, TCountriesAction, TCountriesState, TCountry } from "./countriesListTypes";

const defaultContriesState = {
    countriesList : [],
}

export const countriesReducer = (state = defaultContriesState, action: TCountriesAction): TCountriesState => {
    switch (action.type) {
    case SET_COUNTRIES_LIST:
        return {
            ...state,
            countriesList: action.payload.countries
        }
        default:
            return state;
    }
}