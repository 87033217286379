import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStoredRuAnketasData, getStoredRuAnketasData } from '../../../redux/storedAnketas/storedAnketasSelectors';
import { DECREASE_PROVIDER_STEP, TProviderType } from '../../../redux/currentPosition/currentPositionTypes';
import { getSumSubToken } from '../../../redux/sumSubData/sumSubSelectors';
import { GET_SUMSUB_RU_AUTH } from '../../../redux/storedAnketas/storedAnketasTypes';


export const RuSumSubForm = () => {

    const accessToken = useSelector(getSumSubToken);
    const localRuData = useSelector(getLocalStoredRuAnketasData);
    const storedFields = useSelector(getStoredRuAnketasData);

    const userPhone = storedFields?.['phone'] || localRuData?.fields['phone']?.fieldValue;
    const userEmail = storedFields?.data['email'] || localRuData?.fields['email']?.fieldValue || '';
    const taxResidency = storedFields?.data['country'] || localRuData?.fields['country']?.fieldValue || '';

    const dispatch = useDispatch();

    useEffect(() => {
        if (!accessToken) {
            dispatch({
                type: DECREASE_PROVIDER_STEP,
            });
        } else {
            const script = document.createElement('script');
            script.src = 'https://static.cyberity.ru/idensic/static/sns-websdk-builder.js';
            script.async = true;
            script.onload = () => {
                const snsWebSdkInstance = (window as any).snsWebSdk.init(accessToken, getNewAccessToken)
                    .withConf({
                        lang: taxResidency === 'Russia' ? 'ru' : 'en',
                        email: userEmail,
                        phone: userPhone,
                    })
                    .build();
                snsWebSdkInstance.launch('#cyberity-websdk-container');
            };
            document.body.appendChild(script);
    
        }
    }, [accessToken, userEmail, userPhone, dispatch]);

   
    const getNewAccessToken = () => {
        dispatch({
            type: GET_SUMSUB_RU_AUTH,
            payload: {
                provider: TProviderType.ru,
            },
        });
        return Promise.resolve('new_access_token');
    };

    return <Box id="cyberity-websdk-container"></Box>;
};
