import { TApplicationState } from "../rootReducer";

export const getCurrentPage = (state:TApplicationState)=>{
    return state.usersAnketa.currentPosition.currentPage?? 0
}

export const getLastPage = (state:TApplicationState)=>{
    return state.usersAnketa.currentPosition.lastPage?? 0
}

export const getProviderStep = (state:TApplicationState)=>{
    return state.usersAnketa.currentPosition.providerStep?? 1
}

export const getIntermediateStep = (state: TApplicationState) => state.usersAnketa.currentPosition.currentIntermediateStep;

export const getProvider = (state:TApplicationState)=>{
    return state.usersAnketa.currentPosition.provider??''
}