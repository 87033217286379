import React, { useEffect } from "react";
import { Box, Checkbox, FormControlLabel, FormHelperText, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import { INCREASE_PROVIDER_STEP } from "../../../redux/currentPosition/currentPositionTypes";
import {
  getLocalStoredRuAnketasData,
  getStoredRuAnketasData,
} from "../../../redux/storedAnketas/storedAnketasSelectors";
import { STORE_FORM_DATA } from "../../../redux/userData/userDataTypes";
import { FormRow } from "../../../components/FieldContainer/FieldContainer";
import { PasswordField } from "../../../components/Fields/PasswordField";
import { MuiPhone } from "../../../components/Fields/MuiPhone";
import { CountryIso2, usePhoneValidation } from "react-international-phone";
import { fetchData } from "../../../utils/fetch";
import { useTranslation } from "react-i18next";
import { isNewAnketaSelector } from "../../../redux/continueAnketaPopup/continueAnketaPopupSelector";
import BackdropLoader from "../../../components/BackdropLoader/BackdropLoader";
import { getCountryCode } from "../../../redux/mainContactData/mainContactDataSelectors";
import { logout } from "../../../utils/logout";

const ALPHA__REGEX = /^[a-zA-Zа-яА-Я]+$/;

export const RuMainContactData = () => {
  const { t } = useTranslation(['translation', 'ruForm']);
  const [showLoader, setShowLoader] = useState(false);

  const storedContactData = useSelector(getStoredRuAnketasData);
  const localRuData = useSelector(getLocalStoredRuAnketasData);
  const isNewAnketa = useSelector(isNewAnketaSelector);
  const defaultCountryCode = useSelector(getCountryCode) as CountryIso2;

  const storedFields: { [key: string]: string } = (!isNewAnketa && storedContactData?.data) ? (storedContactData?.data as { [key: string]: string }) : {};

  const [currentCountry, setCurrentCountry] = useState<CountryIso2>(defaultCountryCode);
  const defaultPhone = localRuData?.fields['phone']?.fieldValue || storedFields['phone'] || sessionStorage.getItem('phone');
  const [phone, setPhone] = useState(defaultPhone ?? '');
  const [phoneHelperText, setPhoneHelperText] = useState('');

  const defaultPassword = localRuData?.fields['password']?.fieldValue || storedFields['password'] || sessionStorage.getItem('password');
  const [password, setPassword] = useState(defaultPassword ?? '');
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const defaultEmail = localRuData?.fields['email']?.fieldValue || storedFields['email'] || '';
  const [email, setEmail] = useState(defaultEmail ?? '');
  const [emailHelperText, setEmailHelperText] = useState('');

  const defaultLastName = localRuData?.fields['lastName']?.fieldValue || storedFields['lastName'] || '';
  const [lastName, setLastName] = useState(defaultLastName ?? '');
  const [lastNameHelperText, setLastNameHelperText] = useState('');

  const defaultName = localRuData?.fields['name']?.fieldValue || storedFields['name'] || '';
  const [name, setName] = useState(defaultName ?? '');
  const [nameHelperText, setNameHelperText] = useState('');

  const defaultSecondName = localRuData?.fields['secondName']?.fieldValue || storedFields['secondName'] || '';
  const [secondName, setSecondName] = useState(defaultSecondName ?? '');
  const [secondNameHelperText, setSecondNameHelperText] = useState('');

  const [confidentiallyPolicy, setConfidentiallyPolicy] = useState(true);
  const [confidentiallyPolicyHelperText, setConfidentiallyPolicyHelperText] = useState('');

  const [agreementToMarketing, setAgreementToMarketing] = useState(true);
  

  const [hasAccount, setHasAccount] = useState(false);

  const rememberCheckboxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optionName = event.target.name;

    dispatch({
        type: STORE_FORM_DATA,
        payload: {
            formName: 'ru',
            fieldName: optionName,
            fieldValue: event.target.checked
        }
    })
  };

  useEffect(() => {
    if (storedFields && Object.keys(storedFields).length && !isNewAnketa) {
      if (storedFields['phone']) {
        setPhone(storedFields['phone'])
      }
      if (storedFields['password']) {
        setPassword(storedFields['password'])
      }
      if (storedFields['email']) {
        setEmail(storedFields['email'])
      }
      if (storedFields['lastName']) {
        setLastName(storedFields['lastName'])
      }
      if (storedFields['name']) {
        setName(storedFields['name'])
      }
      if (storedFields['secondName']) {
        setSecondName(storedFields['secondName'])
      }
    }
  }, [storedFields])

  useEffect(() => {
    setShowLoader(true);
    fetchData({
      url: "client/ownership/phone/verify-uniq-ru",
      method: "POST",
      params: {
        phone: phone.replace(/\D/g, ""),
      },
    }).then((response) => {
      setShowLoader(false);
      if (response?.success) {
        setHasAccount(false);
        setPhoneHelperText('');
      } else {
        setHasAccount(true);
        setPhoneHelperText(t("errors.phone.hasAccount-am"));
      }
    });
  }, [phone]);

  const dispatch = useDispatch();
  const phoneValidation = usePhoneValidation(phone, { country: currentCountry });

  const validatePhoneNumber = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!phone) {
      setPhoneHelperText(t('errors.field.empty'));
    }
    if (!phoneValidation.isValid) {
      setPhoneHelperText(t('errors.phone.invalidFormat'));
    }
  };

  const validateEmail = () => {
    if (!email) {
      setEmailHelperText(t('errors.field.empty'));
      return false;
    }
    if (!email.match(/^[a-zA-Z0-9@.+\-_]+$/g)) {
      setEmailHelperText(t('errors.email.wrongSymbols'));
      return false;
    }
    return true;
  };

  const submitForm = () => {
    const isValidEmail = validateEmail();
    const isValidPassword = password.match(/^(?=(.*[A-Za-z]){1,})(?=(.*[0-9]){1,})(?=(.*[=!@#$%^&*()\-__+.]){1,}).{8,}$/g)
    if (
      !phone
      || !phoneValidation.isValid
      || !email
      || !password
      || !confidentiallyPolicy
      || !isValidEmail
      || !isValidPassword
      || !lastName
      || !name
    ) {
      if (!phone) {
        setPhoneHelperText(t('errors.field.empty'));
      }
      if (!phoneValidation.isValid) {
        setPhoneHelperText(t('errors.phone.invalidFormat'));
      }
      if (!isValidEmail) {
        setEmailHelperText(t('errors.email.wrongSymbols'));
      }
      if (!password) {
        setPasswordHelperText(t('errors.field.empty'));
      }
      if (!isValidPassword) {
        setPasswordHelperText(t('errors.password.bad'));
      }
      if (!email) {
        setEmailHelperText(t('errors.field.empty'));
      }
      if (!confidentiallyPolicy) {
        setConfidentiallyPolicyHelperText(t('errors.privacyPolicy.unaccepted'));
      }
      if (!lastName) {
        setLastNameHelperText(t('errors.field.empty'));
      }
      if (!name) {
        setNameHelperText(t('errors.field.empty'));
      }
      return;
    }
    setShowLoader(true);
    fetchData({
      url: 'client/ownership/verify-uniq',
      method: 'POST',
      params: {
        email,
        phone: phone.replace(/\D/g, "")
      }
    }).then((response) => {
      if (response?.success) {
        setShowLoader(false);
        dispatch({
          type: INCREASE_PROVIDER_STEP
        })
      } else {
        setShowLoader(false);
        if (response?.data?.error?.error['email']) {
          setEmailHelperText(t('errors.email.notUnique'));
        }
        if (response?.data?.error?.error['phone,reception']) {
          setPhoneHelperText(t('errors.phone.notUnique'));
        }
        if (!response?.data?.error?.error['email'] && !response?.data?.error?.error['phone,reception']) {
          setEmailHelperText(t('errors.email.invalidFormat'))
        }
      }
    })
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
      <Typography variant="h5">
        {t("step1.title", { ns: "ruForm" })}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <TextField
          fullWidth
          margin={"normal"}
          label={t("fields.email.label")}
          placeholder={t("fields.email.placeholder")}
          type="email"
          required
          helperText={emailHelperText}
          error={!!emailHelperText}
          value={email}
          onBlur={validateEmail}
          // InputLabelProps={{shrink: !!email}}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setEmailHelperText("");
          }}
          disabled={showLoader || hasAccount}
          name={"ru.email"}
        />
        <Box sx={{ width: "100%" }}>
          <PasswordField
            value={password}
            width={"100%"}
            label={t("fields.password.label")}
            placeholder={t("fields.password.placeholder")}
            name={"ru.password"}
            margin={"16px 0 8px"}
            helperText={passwordHelperText}
            error={!!passwordHelperText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
              setPasswordHelperText("");
            }}
            disabled={showLoader || hasAccount}
          />
            <Typography variant="body2">
              {t("fields.password.description")}
            </Typography>
          </Box>

          <MuiPhone
            sx={{ margin: "16px 0" }}
            value={phone}
            name={"ru.phone"}
            label={t("fields.phone.label")}
            placeholder={t("fields.phone.placeholder")}
            helperText={phoneHelperText}
            onBlur={validatePhoneNumber}
            onChange={(phone, country) => {
              setPhoneHelperText("");
              setPhone(phone);
              setCurrentCountry(country);
            }}
            disabled={true}
          />
          <TextField
            sx={{ width: 1 }}
            margin={"normal"}
            label={t("fields.lastName.label")}
            placeholder={t("fields.lastName.placeholder")}
            type="text"
            required
            helperText={lastNameHelperText}
            error={!!lastNameHelperText}
            value={lastName}
            name={"ru.lastName"}
            // InputLabelProps={{shrink: !!lastName}}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !ALPHA__REGEX.test(value)) {
                return;
              }
              setLastName(value);
              setLastNameHelperText("");
            }}
            disabled={showLoader || hasAccount}
          />
          <TextField
            sx={{ width: 1 }}
            margin={"normal"}
            label={t("fields.name.label")}
            placeholder={t("fields.name.placeholder")}
            type="text"
            required
            helperText={nameHelperText}
            error={!!nameHelperText}
            value={name}
            // InputLabelProps={{shrink: !!name}}
            name={"ru.name"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !ALPHA__REGEX.test(value)) {
                return;
              }
              setName(value);
              setNameHelperText("");
            }}
            disabled={showLoader || hasAccount}
          />
          <TextField
            sx={{ width: 1 }}
            margin={"normal"}
            label={t("fields.patronymic.label")}
            placeholder={t("fields.patronymic.placeholder")}
            type="text"
            helperText={secondNameHelperText}
            error={!!secondNameHelperText}
            name={"ru.secondName"}
            value={secondName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !ALPHA__REGEX.test(value)) {
                return;
              }
              setSecondName(value);
              setSecondNameHelperText("");
            }}
            disabled={showLoader || hasAccount}
          />
          <FormControlLabel
            label={
              <div>
                <span>{t("step1.agree", { ns: "ruForm" })}</span>
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("step1.privacyPolicy", { ns: "ruForm" })}
                  </a>
                </span>
                {confidentiallyPolicyHelperText && (
                  <FormHelperText error={!!confidentiallyPolicyHelperText}>
                    {confidentiallyPolicyHelperText}
                  </FormHelperText>
                )}
              </div>
            }
            control={
              <Checkbox
                name={"ru.confidentiallyPolicy_checkbox"}
                checked={confidentiallyPolicy}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  rememberCheckboxValue(event);
                  setConfidentiallyPolicy(!confidentiallyPolicy);
                  setConfidentiallyPolicyHelperText("");
              }}

                disabled={showLoader || hasAccount}
              />
            }
          />

        <FormControlLabel
            label={
              <div>
               <span>{t("step1.agreeSms", { ns: "ruForm" })}</span>
             </div>
            }
            control={
              <Checkbox
                name="ru.agreement__to_marketing_checkbox"
                checked={agreementToMarketing}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    rememberCheckboxValue(event);
                    setAgreementToMarketing(!agreementToMarketing);
                }}

                disabled={showLoader || hasAccount}
              />
            }
          />        

          
          <FormRow sx={{ paddingTop: '24px', gap: {xs: "16px", md: "24px"} }}>
            
          <ButtonComponent
              minWidth={120}
              btnMargin={0}
              text={t("buttons.next")}
              onClick={submitForm}
              disabled={showLoader || hasAccount}
            />
            {hasAccount && (
            <ButtonComponent
              minWidth={120}
              btnMargin={0}
              text={t("buttons.exit")}
              onClick={logout}
            />
            )}
        </FormRow>
      </Box>
      <BackdropLoader showLoader={showLoader} />
    </Box>
  );
}
