import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    styled,
    TableFooter
} from "@mui/material"
import React, {useEffect, useState} from "react"
import {fetchData} from "../../utils/fetch";
import {
    LOAD_USER_ANKETAS_ACTION,
    SET_ANKETAS_APPLIED_FILTERS_ACTION,
    SET_ANKETAS_EXISTING_FILTERS_ACTION,
    TAnketasFilters,
    THeaderField,
    TUserAnketa,
    TUserAnketaField
} from "../../redux/adminAnketas/adminAnketasTypes";
import {useDispatch, useSelector} from "react-redux";
import {UNAUTHORIZE_ADMIN} from "../../redux/adminData/adminDataTypes";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {SET_ADMIN_EDIT_ANKETA_MODE} from "../../redux/adminEditAnketaMode/adminEditAnketaModeTypes";
import {getAllAnketas, getFilterValues, getFilters} from "../../redux/adminAnketas/adminAnketasSelectors";
import {inEditAnketaModeSelector} from "../../redux/adminEditAnketaMode/adminEditAnketaModeSelectors";
import TablePagination from "@mui/base/TablePagination";
import {
    tablePaginationClasses as classes,
} from '@mui/base/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import {blue, grey} from "@mui/material/colors";
import {visuallyHidden} from '@mui/utils'
import {DateSelectionDialog} from "./AdminDateSelection";

const AdminWorkSpaceContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: auto auto 1fr;
`

const AdminHeader = styled(Box)`
  width: 100%;
  height: 40px;
  padding: 0 40px;
  background-color: lightgray;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  position: absolute;
`

const FiltersRow = styled(Box)`
  margin: 47px 20px 20px;
  font-weight: 300;
`

const TableCellContentWrapper = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const CustomTablePagination = styled(TablePagination)(
    ({theme}) => `
    &.MuiTablePagination-root {
        padding: 20px 60px;
        border-top: 1px solid lightgray;
    }

    & .${classes.spacer} {
      display: none;
    }
  
    & .${classes.toolbar}  {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  
    & .${classes.selectLabel} {
      margin: 0;
    }
  
    & .${classes.select}{
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
    }
  
    & .${classes.displayedRows} {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
  
    & .${classes.actions} {
      padding: 2px;
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
      border-radius: 50px;
      text-align: center;
    }
  
    & .${classes.actions} > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 4px;
      background-color: transparent;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  
      &:hover {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      }
  
      &:focus {
        outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      }
  
      &:disabled {
        opacity: 0.3;
      }
    }
    `,
);

const formatTableData = ({header, rows, count}: {
    header: Array<THeaderField>,
    rows: Array<TUserAnketa>,
    count: number
}) => {
    const headerTitles = header.map(item => ({
        id: item.field,
        title: item.title
    }));
    const rowsData: TUserAnketaField[][] = [];
    let rowData: TUserAnketaField[] = [];
    rows.forEach((row) => {
        header.forEach(headerItem => {
            const rowValue = (headerItem.field === 'date_created' ? row[headerItem.field]?.toString()?.split('T')?.[0] : row[headerItem.field]) ?? '';
            rowData.push(rowValue);
        });
        rowsData.push(rowData);
        rowData = [];
    })
    return {
        'header': headerTitles,
        'rows': rowsData,
        'count': count
    }
}

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_ROWS_NUMBER = 25;

export const createFiltersObject = (appliedFilters: TAnketasFilters[]) => {
    const filterValues: { [key: string]: string } = {};
    appliedFilters.forEach(filter => {
        filterValues[filter.field_name] = filter.search_text
    })
    return filterValues;
}

const isColumnSortable = (columnName: string) => {
    switch (columnName) {
        case 'id':
        case 'office':
        case 'date_created':
        case 'comment':
            return true;
        default:
            return false;
    }
}

export const AdminAnketasForm = () => {
    const anketasData = formatTableData(useSelector(getAllAnketas));
    const inEditMode = useSelector(inEditAnketaModeSelector);
    const filters = useSelector(getFilters);
    const filtersArray = useSelector(getFilterValues);
    const filterValues: { [key: string]: string } = createFiltersObject(filtersArray);
    const [searchParams, setSearchParams] = useState(filtersArray);
    const dispatch = useDispatch();

    const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_NUMBER);

    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState('id')

    const [dateSelectionDialogOpen, setDateSelectionDialogOpen] = useState(false);

    const logout = () => {
        sessionStorage.setItem('admin_token', '');
        dispatch({
            type: UNAUTHORIZE_ADMIN
        })
    }

    const fetchTableData = () => {
        if (!inEditMode) {
            fetchData({
                url: 'admin/anketas',
                method: 'POST',
                params: {
                    page_number: page,
                    page_size: rowsPerPage,
                    search: searchParams,
                    sort: {
                        sort_col: orderBy,
                        sort_dir: order
                    }
                }
            }).then(response => {
                dispatch({
                    type: LOAD_USER_ANKETAS_ACTION,
                    payload: {
                        anketas: response?.data?.table
                    }
                })
            })
        }
    }

    const fetchFilters = () => {
        if (!inEditMode) {
            fetchData({
                url: 'admin/anketas/filters',
                method: 'GET',
                params: {}
            }).then(response => {
                dispatch({
                    type: SET_ANKETAS_EXISTING_FILTERS_ACTION,
                    payload: {
                        filters: response?.data ?? []
                    }
                })
            })
        }
    }

    const openEditDialog = (anketaId: string) => {
        dispatch({
            type: SET_ADMIN_EDIT_ANKETA_MODE,
            payload: {
                anketaId,
                editMode: true
            }
        })
    }

    useEffect(fetchTableData, [inEditMode, page, rowsPerPage, searchParams, order, orderBy]);
    useEffect(fetchFilters, []);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(DEFAULT_PAGE_NUMBER);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const openDateSelectionDialog = () => {
        setDateSelectionDialogOpen(true);
    };

    const closeDateSelectionDialog = () => {
        setDateSelectionDialogOpen(false);
    };

    return (
        <AdminWorkSpaceContainer>
            <AdminHeader>
                <Button onClick={openDateSelectionDialog}>Выгрузить отчёт</Button>
                <DateSelectionDialog
                    open={dateSelectionDialogOpen}
                    handleClose={closeDateSelectionDialog}
                />
                <Button onClick={logout}>Выйти</Button>
            </AdminHeader>
            <FiltersRow>
                <Box>Поиск по фильтрам</Box>
                {filters.map(filter => (<TextField
                    key={filter}
                    fullWidth
                    label={filter}
                    value={filterValues[filter]}
                    size="small"
                    style={{width: 180, margin: 20}}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch({
                            type: SET_ANKETAS_APPLIED_FILTERS_ACTION,
                            payload: {
                                filter: {
                                    field_name: filter,
                                    search_text: event.target.value
                                }
                            }
                        })

                    }}
                    name={'armenia.email'}
                />))
                }
                <IconButton
                    style={{margin: '20px 10px'}}
                    onClick={() => {
                        setSearchParams(filtersArray.filter(appliedFilter => appliedFilter.search_text !== ''));
                    }}
                >
                    <SearchIcon/>
                </IconButton>
            </FiltersRow>
            <Paper sx={{width: '100%', height: '100%', overflow: 'hidden'}}>
                <TableContainer sx={{maxHeight: 'calc(100% - 70px)'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {
                                    anketasData?.header?.map((headerCell: { id: string, title: string }) => {
                                        return (<TableCell key={headerCell.id} align="right" sx={{fontWeight: 700}}>
                                            <TableSortLabel
                                                direction={orderBy === headerCell.id ? order : 'asc'}
                                                onClick={isColumnSortable(headerCell.id) ? createSortHandler(headerCell.id === 'office' ? 'type' : headerCell.id) : undefined}
                                                hideSortIcon={!isColumnSortable(headerCell.id)}
                                            >
                                                {headerCell.title}
                                                {orderBy === headerCell.id ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>)
                                    })
                                }
                                <TableCell key="lastTableCell" align="right" sx={{fontWeight: 700}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {anketasData?.rows?.map((row: TUserAnketaField[]) => {
                                return (
                                    <TableRow
                                        key={row[0].toString()}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        {row?.map((cellValue: TUserAnketaField, index: number) => {
                                            return (
                                                <TableCell style={{maxWidth: '200px'}} key={row[0].toString() + index}
                                                           align="right">
                                                    <TableCellContentWrapper title={cellValue.toString()}>
                                                        {cellValue}
                                                    </TableCellContentWrapper>
                                                </TableCell>)
                                        })
                                        }
                                        <TableCell key={row[0].toString() + row.length} align="right">
                                            <IconButton onClick={() => {
                                                openEditDialog(row[0].toString())
                                            }}>
                                                <VisibilityIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Table>
                    <TableFooter>
                        <TableRow>
                            <CustomTablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                colSpan={10}
                                count={anketasData?.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                slotProps={{
                                    select: {
                                        'aria-label': 'rows per page',
                                    },
                                    actions: {
                                        showFirstButton: true,
                                        showLastButton: true,
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </AdminWorkSpaceContainer>
    )
}
