import {Box, styled, TextField} from "@mui/material";

export const FormRow = styled('div')`
    width: 100%;
    max-width: 516px;   
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const FieldContainer = styled('div')`
    width: 100%;
    max-width: 645px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    scroll-margin-top: 4rem;
    gap: 8px;
    @media (min-width: 981px) {
      gap: 16px;
    }
`

export const PopUpFieldContainer = styled('div')`
    width: 100%;
    max-width: 645px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const PopUpStyledTextField = styled(TextField)({
    width: '100%',
    margin: 0,
    '@media (min-width:640px)': {
        maxWidth: 500,
    },
    ".MuiInputBase-root": {
        margin: '16px 0 0',
        height: 64,
        maxHeight: 64,
        '@media (min-width:640px)': {
            margin: '32px 0 0',
        }
    },
    ".MuiInputBase-input": {
        paddingTop: '18px',
        paddingBottom: '18px',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
        maxHeight: '28px',
        '@media (min-width:640px)': {
            paddingTop: '16px',
            paddingBottom: '16px',
            fontSize: '26px',
            lineHeight: '32px',
            maxHeight: '32px',
        }
    },
});

export const FormContainer = styled(Box)({
    width: '100%',
    maxWidth: '800px',
    padding: 0,
    marginBottom: '120px',
    backgroundColor: '#FFF',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    '@media (min-width:981px)': {
        padding: '64px 120px 64px 48px',
        marginBottom: '180px',
        gap: '64px',
},
});
// export const FormContainer2 = styled('div')`
//     //width: 800px;
//     max-width: 800px;
//     padding: 64px 0;
//     margin-bottom: 80px;
//     background-color: #FFF;
//     border-radius: 24px;
//     display: flex;
//     flex-direction: column;
//     gap: 64px;
//     @media (min-width:640px) {
//       max-width: 800px;
//       padding: 64px 120px 64px 48px;
//       margin-bottom: 80px;
//       background-color: #FFF;
//       border-radius: 24px;
//       display: flex;
//       flex-direction: column;
//       gap: 64px;
//     },
// `

export const FormWrapper = styled('div')`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media (min-width:981px) {
      gap: 40px;
    }
`

export const PopupBeliz = styled('div')`
  max-width: 712px;
  margin-bottom: 120px;
  display: inline-flex;  
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;  
  background: #FFF;
  @media (min-width:981px) {
    padding: 40px 24px 40px 64px;
    border-radius: 24px;
    margin-bottom: 180px;
  }
`

export const PopupBelizWrapper = styled('div')`
  max-width: 624px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;  
  @media (min-width:981px) {
    padding-right: 40px;    
    gap: 64px;
  }
`

export const PopupBelizContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  @media (min-width:981px) {
    gap: 40px;
  }
`

export const PopupBelizContent = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`

export const ButtonsContainer = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    gap: '16px',
    '@media (min-width:981px)': {
        width: "fit-content",
        flexDirection: 'row',
        justifyContent: "space-between",
        gap: '24px',
    },
});
