import {Box, Link, styled, Tab, Tabs, Typography} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {getCurrentPage, getProviderStep} from '../../../redux/currentPosition/currentPositionSelector';
import {DECREASE_PROVIDER_STEP, SET_CURRENT_PAGE} from '../../../redux/currentPosition/currentPositionTypes';
import { ChooseBroker } from '../ChooseBroker/ChooseBroker';
import { ProviderForm } from '../ChooseBroker/ProviderForm';
import { LoginForm } from '../RegistrationForm/LoginForm';
import { getProviderFromURL } from '../../../utils/common';
import { userHasRegistered } from '../../../redux/mainContactData/mainContactDataSelectors';
import { Logout } from "../../../components/Logout/Logout";
import { useTranslation } from "react-i18next";
import React from "react";
import { getAccountData } from '../../../redux/accountData/accountSelector';

const TabsWrapper = styled(Box)({
    width: '100%',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '@media (min-width:640px)': {
        paddingTop: '40px',
        gap: '64px'
    },
});

const belizTabsWrapper = {
    paddingTop: '16px',
    backgroundColor: '#FFFFFF',
    gap: '24px',
    '@media (min-width:640px)': {
        paddingTop: '16px',
        gap: '24px',
    },
    '@media (min-width: 981px)': {
        paddingTop: '40px',
        gap: '64px',
        backgroundColor: '#F4F8FF',
    }
}

export const MainTabsForm = () => {
    const { t } = useTranslation();
    const currentPage = useSelector(getCurrentPage);
    const providerStep = useSelector(getProviderStep);
	const hasAccount = useSelector(getAccountData);

    const dispatch = useDispatch();

    const setPrevForm = () => {
        dispatch({
            type: DECREASE_PROVIDER_STEP
        })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: {
                page: newValue
            }
        })
    };

    const registered = useSelector(userHasRegistered);
    const provider = getProviderFromURL() || 'beliz';
    const providerJustifyContent = provider ? 'flex-end': 'space-between';

    const isBeliz = provider === 'beliz';
    const specialCountries = ['armenia', 'za', 'rf'];
    const isSpecialCountry = (provider: string) => specialCountries.includes(provider);

    return (
        <TabsWrapper
            className="tabsWrapper"
            bgcolor='background.default'
            sx={isBeliz ? belizTabsWrapper : {}}
        >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: providerJustifyContent}}>
            {
                !provider &&
                (<Tabs value={currentPage}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    onChange={handleChange}
                >
                    {!registered && <Tab label={t('tabsMenu.contactDetails')} value={0} />}
                    {!provider && <Tab label={t('tabsMenu.choosingBroker')} value={1} disabled={currentPage < 1} />}
                    <Tab label={t('tabsMenu.accountOpening')} value={2} disabled={currentPage < 2} />
                </Tabs>)
            }
            {(registered || hasAccount) && <Logout style={ isSpecialCountry(provider) ? { display: {xs: 'flex', sm: 'none'}} : { display: 'flex'}}/>}
            </Box>
            {providerStep > 1 && providerStep < 5 && currentPage === 2 && isSpecialCountry(provider) &&
                <Link
                    sx={{
                        display: {xs: 'flex', md: 'none'},
                        marginBottom: '8px',
                        alignItems: 'center',
                        gap: '4px',
                        textDecoration: 'none'}}
                    component="button"
                    variant="caption"
                    onClick={setPrevForm}
                >
                    <div className="armenia_arrow-left-green"></div>
                    <Typography variant="caption">
                        {t('buttons.back')}
                    </Typography>
                </Link>
            }
            <Box mx={{ md: 5}}>
                {currentPage === 0 && !registered && <LoginForm />}
                {currentPage === 1 && <ChooseBroker />}
                {currentPage === 2 && <ProviderForm />}
            </Box>
        </TabsWrapper>
    );
}
