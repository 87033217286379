import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    useTheme,
    DialogActions,
    CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../../utils/fetch";
import { ButtonComponent } from "../../../components/Buttons/ButtonComponent";
import ThanksForMessageDialog from "../../../components/Dialogs/ThanksForMessageDialog";

function ArmeniaNeedHelpDialog({
    open,
    handleClose,
}: {
    open: boolean;
    handleClose: () => void;
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("armeniaForm");
    const [message, setMessage] = useState('');
    const [errorText, setErrorText] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [thanksDialogOpen, setThanksDialogOpen] = useState(false);

    const openThanksDialog = () => {
        setThanksDialogOpen(true);
    };

    const closeThanksDialog = () => {
        setThanksDialogOpen(false);
    };

    const handleSubmit = () => {
        setErrorText('');
        setLoading(true);
        fetchData({
            url: 'help',
            method: 'POST',
            params: {
                description: message
            }
        }).then((response) => {
            setLoading(false);
            if (response?.success) {
                setErrorText('');
                setMessage('');
                handleClose();
                openThanksDialog();
            } else {
                setErrorText(t('needHelp.messageSendError'));
            }
        })
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => {
                    setErrorText('');
                    handleClose();
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setErrorText('');
                        handleClose();
                    }}
                    sx={{
                        width: { xs: 24, sm: 36 },
                        height: { xs: 24, sm: 36 },
                        padding: "12px",
                        position: "absolute",
                        right: { xs: 16, sm: 18 },
                        top: { xs: 16, sm: 18 },
                        color: "#6B6B6B",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{t("needHelp.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("needHelp.description")}</DialogContentText>
                    <TextField
                        label={t("needHelp.message")}
                        multiline
                        rows={7}
                        sx={{ width: { xs: "100%", md: "556px" } }}
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value)
                            setErrorText('')
                        }
                        }
                        error={!!errorText}
                        helperText={errorText}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent
                        btnMargin="24px 0 0 0"
                        btnWidth="100%"
                        minWidth="158px"
                        disabled={message.trim() === "" || isLoading}
                        onClick={handleSubmit}
                    >
                        {!isLoading && t("buttons.send", { ns: "translation" })}
                        {isLoading && <CircularProgress size="2rem" color="inherit" sx={{ marginLeft: "10px" }} />}
                    </ButtonComponent>

                </DialogActions>
            </Dialog>
            <ThanksForMessageDialog open={thanksDialogOpen} handleClose={closeThanksDialog} />
        </>
    );
}

export default ArmeniaNeedHelpDialog;
