import { TProviderType } from "../currentPosition/currentPositionTypes";
import { CANCEL_FILLING_ANKETA, SET_POPUP_CLOSED, SET_POPUP_OPEN, TContinueAnketaPopupAction, TContinueAnketaPopupState } from "./continueAnketaPopupTypes";

const defaultContinueAnketaPopupState = {
    popupIsOpen: false,
    newAnketa: false,
    provider: TProviderType.none,
    userName: ''
}

export const continueAnketaPopupReducer = (state = defaultContinueAnketaPopupState, action: TContinueAnketaPopupAction): TContinueAnketaPopupState => {
    switch (action.type) {
    case SET_POPUP_OPEN:
        return {
            ...state,
            popupIsOpen: true,
            provider: action.payload.provider,
            userName: action.payload.name??''
        }
        case SET_POPUP_CLOSED:
            return {
                ...state,
                popupIsOpen: false,
                userName: ''
            }
        case CANCEL_FILLING_ANKETA:
            return {
                ...state,
                newAnketa: true
            }
        default:
            return state;
    }
}