import { MAIN_API_URL } from "./endpoints";

const hostName = `${MAIN_API_URL}`;
let fetchingUrl = '';
let fetchingMethod = '';
let fetcher: Promise<any>;


export const fetchData = ({ url, method, params, isBlob = false, contentType }: {
    url: string,
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    params: any,
    isBlob?: boolean,
    contentType?: string
}) => {
    const clientToken = sessionStorage.getItem('token');
    const adminToken = sessionStorage.getItem('admin_token');

    const token = url.indexOf('admin') !== -1 ? adminToken : clientToken;

    let formData;

    if (contentType?.startsWith('multipart')) {
        formData = new FormData();
        formData.append("fields", JSON.stringify(params.fields));
        for (let fileKey in params.files){
            formData.append(fileKey, params.files[fileKey]);
        }
    }

    const headers: HeadersInit = {
        "Authorization": token ? `Bearer ${token}` : ''
    }
    if (!contentType) {
        headers["Content-Type"] = "application/json";
    }

    if (!(fetchingUrl === url && fetchingMethod == method) || method === 'PATCH') {
        fetchingUrl = url;
        fetchingMethod = method;
        fetcher = fetch(`${hostName}/${url}/` + (method === 'GET' ? ('?' + new URLSearchParams(params)) : ''), {
            method,
            headers,
            body: method === 'GET' ? undefined : (contentType?.startsWith('multipart') ? formData : JSON.stringify(params))
        }).then(response => {
            fetchingUrl = '';
            fetchingMethod = '';
            return isBlob ? response.blob() : response.json()
        }).catch(err => {
            //console.error(err);
        });
    }
    return fetcher;
}
