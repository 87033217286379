import { Box } from "@mui/material"
import { ArmeniaBrokerCard } from "./ArmeniaBrokerCard"
import { BelizBrokerCard } from "./BelizBrokerCard";
import { ZaBrokerCard } from "./ZaBrokerCard";
import { RuBrokerCard } from "./RuBrokerCard";
import {useTranslation} from "react-i18next";

export const ChooseBroker = () => {
    const {t} = useTranslation();
    return (
        <Box>
            <h2>{t('brokerCard.title')}</h2>
            <Box sx={{ display: 'flex', padding: '5px' }}>
                <ArmeniaBrokerCard />
                <BelizBrokerCard />
                <ZaBrokerCard />
                <RuBrokerCard />
            </Box>
        </Box>)
}
